import React from 'react';
import CandidatesHeader from './CandidatesHeader';

class HouseCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: []
    };
  }

  componentDidMount() {
    this.queryAndUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.queryAndUpdate();
    }
  }

  queryAndUpdate() {
    const setState = this.setState;

    const url = "/v1/candidates/house/" + this.props.state + "/" + this.props.district;
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => {this.setState({ candidates: response })} );
      // .catch(() => this.props.history.push("/"));
  }

  render() {
    const {state, district} = this.props;
    const {candidates} = this.state;
    const information = !state ? 'All elections.' : '';

    return (
      <div className="candidates">
        <CandidatesHeader
          state={state}
          office="house"
          district={district}
          information={information}
        />

        {candidates.map(
          function(candidate, index) {
            const partyClass = candidate.party.toLowerCase().replace(/\W/g, '-');

            return(
              <div className={'candidate ' + partyClass} key={index}>
                <img className="thumbnail" src={candidate.photo_path} />
                <div className="caption">
                  <p className="first-name">{candidate.first_name}</p>
                  <p className="last-name">{candidate.last_name}</p>
                  <p className="party">{candidate.party}</p>
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  }
}

export default HouseCandidates;
