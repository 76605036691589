import React from 'react';
import CandidatesHeader from './CandidatesHeader';

class SenateCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: []
    };
  }

  componentDidMount() {
    this.queryAndUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.queryAndUpdate();
    }
  }

  queryAndUpdate() {
    const setState = this.setState;

    const url = "/v1/candidates/senate/" + this.props.state;
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => {this.setState({ candidates: response })});
      // .catch(() => this.props.history.push("/"));
  }

  render() {
    const {state} = this.props;
    const {candidates} = this.state;
    const information = !state ? 'All elections.' :
      (candidates.length === 0 ? 'No elections. Click map for Congressional candidates.' : '');

    return (
      <div className="candidates">
        <CandidatesHeader
          state={state}
          office="senate"
          information={information}
        />

        {candidates.map(
          function(candidate, index) {
            const partyClass = candidate.party.toLowerCase().replace(/\W/g, '-');

            return(
              <div className={'candidate ' + partyClass} key={index}>
                <img className="thumbnail" src={candidate.photo_path} />
                <div className="caption">
                  <p className="first-name">{candidate.first_name}</p>
                  <p className="last-name">{candidate.last_name}</p>
                  <p className="party">{candidate.party}</p>
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  }
}

export default SenateCandidates;
