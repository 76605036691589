import React from "react"
import CandidatesHeader from './CandidatesHeader';

function PresidentialCandidates() {
  const candidates = [{
    first_name: 'Joe',
    last_name: 'Biden',
    party: 'Democratic',
    photo_url: '/images/candidates/executive/biden.jpg',
  }, {
    first_name: 'Howie',
    last_name: 'Hawkins',
    party: 'Green',
    photo_url: '/images/candidates/executive/hawkins.jpg',
  }, {
    first_name: 'Jo',
    last_name: 'Jorgensen',
    party: 'Libertarian',
    photo_url: '/images/candidates/executive/jorgensen.jpg',
  }, {
    first_name: 'Donald',
    last_name: 'Trump',
    party: 'Republican',
    photo_url: '/images/candidates/executive/trump.jpg',
  }];

  return (
    <div className="candidates">
      <CandidatesHeader office="president" />

      {candidates.map(
        function(candidate, index) {
          const partyClass = candidate.party.toLowerCase();

          return(
            <div className={'candidate ' + partyClass} key={index}>
              <img className="thumbnail" src={candidate.photo_url} />
              <div className="caption">
                <p className="first-name">{candidate.first_name}</p>
                <p className="last-name">{candidate.last_name}</p>
                <p className="party">{candidate.party}</p>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

export default PresidentialCandidates;
