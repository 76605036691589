import React, { Component } from 'react';

function Input(props) {
  return(
    <div className="input-wrapper">
      <input />
      {props.example ? <p className="input-example">ex. {props.example}</p> : ''}
    </div>
  );
}

export default Input;

