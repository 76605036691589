import React from "react"
import PropTypes from "prop-types"

class District extends React.Component {
  render () {
    const showClass = this.props.show ? ' show' : '';
    const highlightClass = this.props.highlight ? ' highlight' : '';
    const borderClass = this.props.softBorder ? ' soft-border' : '';
    const focusClass = this.props.defocus ? ' defocus' : '';
    const partyClass = this.props.party ? ' ' + this.props.party : '';
    const classes = 'district' + showClass + highlightClass + borderClass + focusClass + partyClass;
    const titleText = this.props.state + ' State District ' + this.props.number;

    return (
      <path
        className={classes}
        d={this.props.vectors}
        onClick={this.props.onClick}
      >
        {titleText ? <title>{titleText}</title> : ''}
      </path>
    );
  }
}

District.propTypes = {
  state: PropTypes.string,
  number: PropTypes.number,
  party: PropTypes.string,
  vectors: PropTypes.string,
  show: PropTypes.bool,
  highlight: PropTypes.bool,
  softBorder: PropTypes.bool,
  defocus: PropTypes.bool,
  onClick: PropTypes.func,
};

export default District
