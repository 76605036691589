import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import District from "./District";
import StateBorders from "./StateBorders";
import MapStateAbbreviations from "./MapStateAbbreviations";
import districtPaths from "./districtPaths";

import fakePresidentData from "./fakePresidentData";
import fakeHouseData from "./fakeHouseData";

function partyClass(initial) {
  switch (initial) {
    case 'D': return 'democratic';
    case 'G': return 'green';
    case 'L': return 'libertarian';
    case 'R': return 'republican';
  }

  return null;
}

class USMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusState: props.focusState,
      animationStage: null,
    };
    this.handleDistrictClick = this.handleDistrictClick.bind(this);
  }

  handleDistrictClick(updateState, updateDistrict) {
    this.props.handleDistrictClick(updateState, updateDistrict);
  }

  componentWillUnmount(){
    if (this.timer) { clearTimeout(this.timer); }
  }

  componentDidUpdate(prevProps) {
    if (this.props.focusState != prevProps.focusState) {
      this.animate();
    }
  }

  animate() {
    this.fadeOut(
      () => this.move(
        () => this.transparent(
          () => this.fadeIn(
            () => this.endAnimation()
          )
        )
      )
    );
  }

  fadeOut(callback) {
    if (this.timer) { clearTimeout(this.timer) }
    this.setState({
      animationStage: 'fade-out',
      focusState: this.state.focusState,
    });
    this.timer = setTimeout(callback, 400);
  }

  move(callback) {
    if (this.timer) { clearTimeout(this.timer) }
    this.setState({
      animationStage: 'move',
      focusState: this.props.focusState,
    });
    this.timer = setTimeout(callback, 600);
  }

  transparent(callback) {
    if (this.timer) { clearTimeout(this.timer) }
    this.setState({
      animationStage: 'transparent',
      focusState: this.props.focusState,
    });
    this.timer = setTimeout(callback, 1);
  }

  fadeIn(callback) {
    if (this.timer) { clearTimeout(this.timer) }
    this.setState({
      animationStage: 'fade-in',
      focusState: this.props.focusState,
    });
    this.timer = setTimeout(callback, 400);
  }

  endAnimation() {
    if (this.timer) { clearTimeout(this.timer) }
    this.setState({
      animationStage: null,
      focusState: this.props.focusState,
    });
  }

  presidentialWinner(usState) {
    const initial = fakePresidentData.find(row => row.state === usState)?.winner;
    return partyClass(initial);
  }

  houseWinner(usState, number) {
    const initial = fakeHouseData.find(row => row.state === usState && row.number === number)?.winner;
    return partyClass(initial);
  }

  drawDistrict(district) {
    const focusState = this.state.focusState;
    const focusDistrict = this.props.focusDistrict;

    const party = this.props.office === 'house' ? this.houseWinner(district.state, district.number) : this.presidentialWinner(district.state);
    const animationStage = this.state.animationStage;
    const show = ['fade-in', null].includes(animationStage);
    const onClick = () => this.handleDistrictClick(district.state, district.number);

    return(
      <District
        key={district.state + '-' + district.number}
        state={district.state}
        number={district.number}
        party={party}
        vectors={district.vectors}
        show={show}
        highlight={district.state === focusState && district.number == focusDistrict}
        softBorder={!focusDistrict}
        defocus={focusState && district.state !== focusState}
        onClick={onClick}
      />
    );
  }

  render () {
    const focusState = this.state.focusState;
    const focusDistrict = this.props.focusDistrict;

    // only display paths when the map is not moving
    const districts = this.state.animationStage === 'move' ? [] : districtPaths;
    const selectedDistrict = districts.find((district) => district.state === focusState && district.number === focusDistrict)

    return (
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="us-map" viewBox="0 0 1520.05 840.75" preserveAspectRatio="xMinYMin">

        <g className={'transformer ' + (focusState ? focusState : '')}>

          <image className="district-borders-background" xlinkHref="/images/maps/us.jpg" width="100%" height="100%" x="0" y="0" />

          { districts.map((district) => (district.state === focusState) && (district.number == focusDistrict) ? '' : this.drawDistrict(district)) }

          <image className={'state-borders' + (focusState ? ' defocus' : '')} xlinkHref="/images/maps/state_borders.png" width="100%" height="100%" x="0" y="0" />

          { selectedDistrict ? this.drawDistrict(selectedDistrict) : '' }

          <MapStateAbbreviations visible={!focusState} />
        </g>

        {/* <circle cx="760" cy="420" r="4" fill="#f00" /> */}
      </svg>
    );
  }
}

USMap.propTypes = {
  office: PropTypes.string,
  focusState: PropTypes.string,
  focusDistrict: PropTypes.number,
  updateFocus: PropTypes.func
};
export default USMap
