import React, { Component } from 'react';
import { Link } from "react-router-dom";

const imageWidth = 80;
const spacing = 5;

class CandidateCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prefix: '',
      paths: [],
      displayed: [],
    };

    this.tick = this.tick.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    fetch('/v1/candidates/photo_urls')
      .then(response => response.json())
      .then(function({prefix, paths}) {
        this.setState({
          prefix,
          paths: this.shuffle(paths),
          imageIndex: 0,
          displayed: this.adjustDisplayed(),
        });
        this.tryFadeIn();
        this.tryFadeIn();
        this.timeout = setTimeout(this.tryFadeIn.bind(this), 300);
        this.interval = setInterval(this.tick, 750);
        window.addEventListener('resize', this.resize);
      }.bind(this));

  }

  componentWillUnmount(){
    if (this.timeout) { clearTimeout(this.timeout); }
    if (this.interval) { clearInterval(this.interval); }
    window.removeEventListener('resize', this.resize);
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  calculateSlotCount() {
    const count = Math.ceil(window.innerWidth / imageWidth);
    return (count % 2) ? count : count + 1;
  }

  adjustDisplayed() {
    const newSlotCount = this.calculateSlotCount();
    const newDisplayed = (new Array(newSlotCount)).fill({
      active: false,
      path: '',
      changedAt: 0
    });

    this.state.displayed.forEach(function(path, index) {
      if (index < newSlotCount) {
        newDisplayed[index] = path;
      }
    });

    return newDisplayed;
  }

  randomSlotIndex() {
    return this.randomInt(0, this.state.displayed.length - 1)
  }

  coinFlip() {
    return Math.random() >= 0.5;
  }

  tick() {
    if (this.coinFlip()) {
      this.tryFadeIn();
    } else {
      this.tryFadeOut();
    }
  }

  randomInt(low, high) {
    return Math.floor(Math.random() * (high - low + 1)) + low;
  }

  tryFadeIn() {
    let displayed = this.state.displayed.slice();

    const imageIndex = this.state.imageIndex;
    const slotIndex = this.randomSlotIndex();
    const existing = displayed[slotIndex];
    const now = (new Date).getTime();

    if (!existing || (!existing.active && (now > existing.changedAt + 2000))) {
      displayed[slotIndex] = {
        active: true,
        path: this.state.paths[this.state.imageIndex],
        changedAt: now,
        xShift: this.randomInt(-3, 3),
        yShift: this.randomInt(-12, 10),
        scale: this.randomInt(95, 103)/100,
      }

      this.setState({
        ...this.state,
        displayed,
        imageIndex: (imageIndex + 1) % this.state.paths.length,
      });
    }
  }

  tryFadeOut() {
    const slotIndex = this.randomSlotIndex();
    const existing = this.state.displayed[slotIndex];
    const now = (new Date).getTime();

    if (existing && existing.active && (now > existing.changedAt + 2000)) {
      let displayed = this.state.displayed.slice();

      displayed[slotIndex] = {
        active: false,
        path: existing.path,
        changedAt: now,
      }

      this.setState({
        ...this.state,
        displayed,
      });
    }
  }

  resize() {
    this.setState({
      ...this.state,
      displayed: this.adjustDisplayed()
    });
  }

  render() {
    const prefix = this.state.prefix;
    const displayed = this.state.displayed;
    const slotCount = displayed.length;
    const offset = Math.floor(slotCount/2);

    return(
      <div className="candidate-carousel">
        {
          displayed.map(function(obj, index) {
            const {active, path, xShift, yShift, scale} = (obj || {});
            const width = imageWidth * scale;
            const height = width * 1.5;
            const left = (imageWidth + spacing) * (index - offset) - (imageWidth / 2) + xShift;

            return(
              path
              ? <img
                  key={index - offset}
                  className={active ? 'active' : null}
                  src={prefix + '/' + path}
                  style={{
                    left: left + 'px',
                    top: yShift + 'px',
                    width: width + 'px',
                    height: height + 'px'
                  }}
                />
              : <img key={index - offset} src="images/candidates/blank.png" />
            );
          })
        }
      </div>
    );
  }
}

export default CandidateCarousel;
