import {districtCandidates} from './districtCandidates';

function GetHouseCandidates(usState, districtNumber) {
  const district = districtCandidates.find((d) => d.state === usState && d.number === districtNumber);
  return district?.candidates || [];
}

function HousePhotoUrl(state, district, candidate) {
  const firstInitial = candidate.first_name?.substring(0, 1);
  const lastName = candidate.last_name?.replace(/\W/, '_');

  return '/images/candidates/house/' + state + '-' + district + '_' + firstInitial + '_' + lastName + '.jpg';
}

export { GetHouseCandidates, HousePhotoUrl };
