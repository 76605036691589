export const stateCandidates =
[{
  name: "AL",
  candidates: [{
    first_name: "Doug",
    last_name: "Jones",
    ballotpedia: "Doug_Jones_(Alabama)",
    wiki: "/wiki/Doug_Jones_(politician)",
    party: "Democratic",
  }, {
    first_name: "Tommy",
    last_name: "Tuberville",
    ballotpedia: "Tommy_Tuberville",
    wiki: "/wiki/Tommy_Tuberville",
    party: "Republican",
  }]
}, {
  name: "AK",
  candidates: [{
    first_name: "Al",
    last_name: "Gross",
    ballotpedia: "Al_Gross",
    party: "Independent",
  }, {
    first_name: "Sidney \"Sid\"",
    last_name: "Hill",
    ballotpedia: "Sidney_Hill",
    party: "Independent",
    write_in: true,
  }, {
    first_name: "John Wayne",
    last_name: "Howe",
    ballotpedia: "John_Howe_(Alaska)",
    party: "Alaskan Independence",
  }, {
    first_name: "Dan",
    last_name: "Sullivan",
    ballotpedia: "Daniel_S._Sullivan",
    wiki: "/wiki/Dan_Sullivan_(U.S._senator)",
    party: "Republican",
  }, {
    first_name: "Jed",
    last_name: "Whittaker",
    ballotpedia: "Jed_Whittaker",
    party: "Green",
    write_in: true,
  }]
}, {
  name: "AR",
  candidates: [{
    first_name: "Tom",
    last_name: "Cotton",
    ballotpedia: "Tom_Cotton",
    wiki: "/wiki/Tom_Cotton",
    party: "Republican",
  }, {
    first_name: "Ricky Dale",
    last_name: "Harrington",
    suffix: "Jr.",
    ballotpedia: "Ricky_Dale_Harrington_Jr.",
    party: "Libertarian",
  }]
}, {
  name: "CO",
  candidates: [{
    first_name: "Raymon",
    last_name: "Doane",
    ballotpedia: "Raymon_Doane",
    party: "Libertarian",
  }, {
    first_name: "Daniel",
    last_name: "Doyle",
    ballotpedia: "Daniel_Doyle_(Colorado)",
    party: "Approval Voting",
  }, {
    first_name: "Stephan \"Seku\"",
    last_name: "Evans",
    ballotpedia: "Stephan_Evans",
    party: "Unity",
  }, {
    first_name: "Cory",
    last_name: "Gardner",
    ballotpedia: "Cory_Gardner",
    wiki: "/wiki/Cory_Gardner",
    party: "Republican",
  }, {
    first_name: "Bruce",
    last_name: "Lohmiller",
    ballotpedia: "Bruce_Lohmiller",
    party: "Green",
    write_in: true,
  }, {
    first_name: "John",
    last_name: "Hickenlooper",
    ballotpedia: "John_Hickenlooper",
    wiki: "/wiki/John_Hickenlooper",
    party: "Democratic",
  }, {
    first_name: "Michael",
    last_name: "Sanchez",
    ballotpedia: "Michael_Sanchez_(Colorado)",
    party: "Independent",
    write_in: true,
  }, {
    first_name: "Danny",
    last_name: "Skelly",
    ballotpedia: "Danny_Skelly",
    party: "Independent",
    write_in: true,
  }]
}, {
  name: "DE",
  candidates: [{
    first_name: "Chris",
    last_name: "Coons",
    ballotpedia: "Chris_Coons",
    wiki: "/wiki/Chris_Coons",
    party: "Democratic",
  }, {
    first_name: "Nadine",
    last_name: "Frost",
    ballotpedia: "Nadine_Frost",
    party: "Libertarian",
  }, {
    first_name: "Mark",
    last_name: "Turley",
    ballotpedia: "Mark_Turley",
    party: "Delaware Independent",
  }, {
    first_name: "Lauren",
    last_name: "Witzke",
    ballotpedia: "Lauren_Witzke",
    party: "Republican",
  }]
}, {
  name: "GA",
  candidates: [{
    first_name: "Shane",
    last_name: "Hazel",
    ballotpedia: "Shane_Hazel",
    party: "Libertarian",
  }, {
    first_name: "Jon",
    last_name: "Ossoff",
    ballotpedia: "Jon_Ossoff",
    wiki: "/wiki/Jon_Ossoff",
    party: "Democratic",
  }, {
    first_name: "David",
    last_name: "Perdue",
    ballotpedia: "David_Perdue",
    wiki: "/wiki/David_Perdue",
    party: "Republican",
  }]
}, {
  name: "ID",
  candidates: [{
    first_name: "Natalie",
    last_name: "Fleming",
    ballotpedia: "Natalie_Fleming_(Idaho)",
    party: "Independent",
  }, {
    first_name: "Paulette",
    last_name: "Jordan",
    ballotpedia: "Paulette_Jordan",
    wiki: "/wiki/Paulette_Jordan",
    party: "Democratic",
  }, {
    first_name: "Jim",
    last_name: "Risch",
    ballotpedia: "Jim_Risch",
    wiki: "/wiki/Jim_Risch",
    party: "Republican",
  }, {
    first_name: "Ray",
    last_name: "Writz",
    ballotpedia: "Ray_Writz",
    party: "Constitution",
  }]
}, {
  name: "IL",
  candidates: [{
    first_name: "David F.",
    last_name: "Black",
    ballotpedia: "David_Black_(Illinois)",
    party: "Green",
  }, {
    first_name: "Mark",
    last_name: "Curran",
    ballotpedia: "Mark_Curran",
    wiki: "/wiki/Mark_Curran",
    party: "Republican",
  }, {
    first_name: "Dick",
    last_name: "Durbin",
    ballotpedia: "Dick_Durbin",
    wiki: "/wiki/Dick_Durbin",
    party: "Democratic",
  }, {
    first_name: "Danny",
    last_name: "Malouf",
    ballotpedia: "Danny_Malouf",
    party: "Libertarian",
  }, {
    first_name: "Willie",
    last_name: "Wilson",
    ballotpedia: "Willie_Wilson",
    wiki: "/wiki/Willie_Wilson_(businessman)",
    party: "Willie Wilson Party",
  }]
}, {
  name: "IA",
  candidates: [{
    first_name: "Joni",
    last_name: "Ernst",
    ballotpedia: "Joni_Ernst",
    wiki: "/wiki/Joni_Ernst",
    party: "Republican",
  }, {
    first_name: "Theresa",
    last_name: "Greenfield",
    ballotpedia: "Theresa_Greenfield",
    wiki: "/wiki/Theresa_Greenfield",
    party: "Democratic",
  }, {
    first_name: "Suzanne",
    last_name: "Herzog",
    ballotpedia: "Suzanne_Herzog",
    party: "Independent",
  }, {
    first_name: "Rick",
    last_name: "Stewart",
    ballotpedia: "Rick_Stewart",
    party: "Libertarian",
  }]
}, {
  name: "KS",
  candidates: [{
    first_name: "Barbara",
    last_name: "Bollier",
    ballotpedia: "Barbara_Bollier",
    wiki: "/wiki/Barbara_Bollier",
    party: "Democratic",
  }, {
    first_name: "Jason",
    last_name: "Buckley",
    ballotpedia: "Jason_Buckley_(Kansas)",
    party: "Libertarian",
  }, {
    first_name: "Roger",
    last_name: "Marshall",
    ballotpedia: "Roger_Marshall",
    wiki: "/wiki/Roger_Marshall_(politician)",
    party: "Republican",
  }]
}, {
  name: "KY",
  candidates: [{
    first_name: "Brad",
    last_name: "Barron",
    ballotpedia: "Brad_Barron",
    party: "Libertarian",
  }, {
    first_name: "Paul John",
    last_name: "Frangedakis",
    ballotpedia: "Paul_John_Frangedakis",
    party: "Independent",
    write_in: true,
  }, {
    first_name: "Mitch",
    last_name: "McConnell",
    ballotpedia: "Mitch_McConnell",
    wiki: "/wiki/Mitch_McConnell",
    party: "Republican",
  }, {
    first_name: "Amy",
    last_name: "McGrath",
    ballotpedia: "Amy_McGrath",
    wiki: "/wiki/Amy_McGrath",
    party: "Democratic",
  }, {
    first_name: "Randall Lee",
    last_name: "Teegarden",
    ballotpedia: "Randall_Lee_Teegarden",
    party: "Independent",
    write_in: true,
  }]
}, {
  name: "LA",
  candidates: [{
    first_name: "Beryl",
    last_name: "Billiot",
    ballotpedia: "Beryl_Billiot",
    party: "Independent",
  }, {
    first_name: "John Paul",
    last_name: "Bourgeois",
    ballotpedia: "John_Paul_Bourgeois",
    party: "Independent",
  }, {
    first_name: "Bill",
    last_name: "Cassidy",
    ballotpedia: "Bill_Cassidy",
    wiki: "/wiki/Bill_Cassidy",
    party: "Republican",
  }, {
    first_name: "Reno",
    last_name: "Daret III",
    ballotpedia: "Reno_Jean_Daret_III",
    party: "Independent",
  }, {
    first_name: "Derrick",
    last_name: "Edwards",
    ballotpedia: "Derrick_Edwards",
    party: "Democratic",
  }, {
    first_name: "Xan",
    last_name: "John",
    ballotpedia: "Xan_John",
    party: "Independent",
  }, {
    first_name: "David Drew",
    last_name: "Knight",
    ballotpedia: "David_Drew_Knight",
    party: "Democratic",
  }, {
    first_name: "Vinny",
    last_name: "Mendoza",
    ballotpedia: "M.V._Mendoza",
    party: "Independent",
  }, {
    first_name: "Jamar \"Doc\"",
    last_name: "Montgomery",
    ballotpedia: "Jamar_Myers-Montgomery",
    party: "Independent",
  }, {
    first_name: "Dustin",
    last_name: "Murphy",
    ballotpedia: "Dustin_Murphy",
    party: "Republican",
  }, {
    first_name: "Adrian",
    last_name: "Perkins",
    ballotpedia: "Adrian_Perkins",
    wiki: "/wiki/Adrian_Perkins",
    party: "Democratic",
  }, {
    first_name: "Antoine",
    last_name: "Pierce",
    ballotpedia: "Antoine_Pierce",
    party: "Democratic",
  }, {
    first_name: "Melinda Mary",
    last_name: "Price",
    ballotpedia: "Melinda_Mary_Price",
    party: "Independent",
  }, {
    first_name: "Aaron",
    last_name: "Sigler",
    ballotpedia: "Aaron_Sigler",
    party: "Libertarian",
  }, {
    first_name: "Peter",
    last_name: "Wenstrup",
    ballotpedia: "Peter_Wenstrup",
    party: "Democratic",
  }]
}, {
  name: "ME",
  candidates: [{
    first_name: "Tiffany",
    last_name: "Bond",
    ballotpedia: "Tiffany_Bond",
    party: "Independent",
    write_in: true,
  }, {
    first_name: "Susan",
    last_name: "Collins",
    ballotpedia: "Susan_Collins_(Maine)",
    wiki: "/wiki/Susan_Collins",
    party: "Republican",
  }, {
    first_name: "Douglas",
    last_name: "Fogg",
    party: "Independent",
    write_in: true,
  }, {
    first_name: "Sara",
    last_name: "Gideon",
    ballotpedia: "Sara_Gideon",
    wiki: "/wiki/Sara_Gideon",
    party: "Democratic",
  }, {
    first_name: "Max",
    last_name: "Linn",
    ballotpedia: "Max_Linn",
    party: "Independent",
  }, {
    first_name: "Lisa",
    last_name: "Savage",
    ballotpedia: "Lisa_Savage",
    party: "Independent",
  }]
}, {
  name: "MA",
  candidates: [{
    first_name: "Shiva",
    last_name: "Ayyadurai",
    ballotpedia: "Shiva_Ayyadurai",
    wiki: "/wiki/Shiva_Ayyadurai",
    party: "Independent",
    write_in: true,
  }, {
    first_name: "Ed",
    last_name: "Markey",
    ballotpedia: "Ed_Markey",
    wiki: "/wiki/Ed_Markey",
    party: "Democratic",
  }, {
    first_name: "Kevin",
    last_name: "O'Connor",
    ballotpedia: "Kevin_O%27Connor_(Massachusetts)",
    party: "Republican",
  }]
}, {
  name: "MI",
  candidates: [{
    first_name: "Frank Seldon",
    last_name: "Cupps",
    party: "Communist",
    write_in: true,
  }, {
    first_name: "Doug",
    last_name: "Dern",
    ballotpedia: "Doug_Dern",
    party: "Natural Law",
  }, {
    first_name: "John",
    last_name: "James",
    ballotpedia: "John_James_(Michigan)",
    wiki: "/wiki/John_E._James",
    party: "Republican",
  }, {
    first_name: "Gary",
    last_name: "Peters",
    ballotpedia: "Gary_Peters",
    wiki: "/wiki/Gary_Peters",
    party: "Democratic",
  }, {
    first_name: "Marcia",
    last_name: "Squier",
    ballotpedia: "Marcia_Squier",
    party: "Green",
  }, {
    first_name: "Valerie",
    last_name: "Willis",
    ballotpedia: "Valerie_Willis",
    party: "U.S. Taxpayers",
  }]
}, {
  name: "MN",
  candidates: [{
    first_name: "Jason",
    last_name: "Lewis",
    ballotpedia: "Jason_Lewis_(Minnesota)",
    wiki: "/wiki/Jason_Lewis_(Minnesota_politician)",
    party: "Republican",
  }, {
    first_name: "Kevin",
    last_name: "O'Connor",
    ballotpedia: "Kevin_O%27Connor_(Minnesota)",
    party: "Legal Marijuana Now",
  }, {
    first_name: "Tina",
    last_name: "Smith",
    ballotpedia: "Tina_Smith",
    wiki: "/wiki/Tina_Smith",
    party: "Democratic",
  }, {
    first_name: "Oliver",
    last_name: "Steinberg",
    ballotpedia: "Oliver_Steinberg",
    party: "Legalize Cannabis",
  }]
}, {
  name: "MS",
  candidates: [{
    first_name: "Jimmy",
    last_name: "Edwards",
    ballotpedia: "Jimmy_Edwards",
    party: "Libertarian",
  }, {
    first_name: "Mike",
    last_name: "Espy",
    ballotpedia: "Mike_Espy",
    wiki: "/wiki/Mike_Espy",
    party: "Democratic",
  }, {
    first_name: "Cindy",
    last_name: "Hyde-Smith",
    ballotpedia: "Cindy_Hyde-Smith",
    wiki: "/wiki/Cindy_Hyde-Smith",
    party: "Republican",
  }]
}, {
  name: "MT",
  candidates: [{
    first_name: "Steve",
    last_name: "Bullock",
    ballotpedia: "Steve_Bullock",
    wiki: "/wiki/Steve_Bullock_(Montana_politician)",
    party: "Democratic",
  }, {
    first_name: "Steve",
    last_name: "Daines",
    ballotpedia: "Steve_Daines",
    wiki: "/wiki/Steve_Daines",
    party: "Republican",
  }]
}, {
  name: "NE",
  candidates: [{
    first_name: "Chris",
    last_name: "Janicek",
    ballotpedia: "Chris_Janicek",
    party: "Democratic",
  }, {
    first_name: "Preston",
    last_name: "Love",
    suffix: "Jr.",
    ballotpedia: "Preston_Love_Jr.",
    party: "Democratic",
    write_in: true,
  }, {
    first_name: "Ben",
    last_name: "Sasse",
    ballotpedia: "Ben_Sasse",
    wiki: "/wiki/Ben_Sasse",
    party: "Republican",
  }, {
    first_name: "Gene",
    last_name: "Siadek",
    ballotpedia: "Gene_Siadek",
    party: "Libertarian",
  }]
}, {
  name: "NH",
  candidates: [{
    first_name: "Corky",
    last_name: "Messner",
    ballotpedia: "Corky_Messner",
    party: "Republican",
  }, {
    first_name: "Justin",
    last_name: "O'Donnell",
    ballotpedia: "Justin_O%27Donnell",
    party: "Libertarian",
  }, {
    first_name: "Jeanne",
    last_name: "Shaheen",
    ballotpedia: "Jeanne_Shaheen",
    wiki: "/wiki/Jeanne_Shaheen",
    party: "Democratic",
  }]
}, {
  name: "NJ",
  candidates: [{
    first_name: "Cory",
    last_name: "Booker",
    ballotpedia: "Cory_Booker",
    wiki: "/wiki/Cory_Booker",
    party: "Democratic",
  }, {
    first_name: "Daniel",
    last_name: "Burke",
    ballotpedia: "Daniel_Burke_(New_Jersey)",
    party: "LaRouche was Right",
  }, {
    first_name: "Veronica",
    last_name: "Fernandez",
    ballotpedia: "Veronica_Fernandez",
    party: "Of, By, For!",
  }, {
    first_name: "Madelyn",
    last_name: "Hoffman",
    ballotpedia: "Madelyn_Hoffman",
    party: "Green",
  }, {
    first_name: "Rik",
    last_name: "Mehta",
    ballotpedia: "Rik_Mehta",
    party: "Republican",
  }, {
    first_name: "Luis",
    last_name: "Vergara",
    ballotpedia: "Luis_Vergara",
    party: "Independent",
  }]
}, {
  name: "NM",
  candidates: [{
    first_name: "Ben Ray",
    last_name: "Luján",
    ballotpedia: "Ben_Ray_Lujan",
    wiki: "/wiki/Ben_Ray_Luj%C3%A1n",
    party: "Democratic",
  }, {
    first_name: "Mark",
    last_name: "Ronchetti",
    ballotpedia: "Mark_Ronchetti",
    party: "Republican",
  }, {
    first_name: "Bob",
    last_name: "Walsh",
    ballotpedia: "Bob_Walsh",
    party: "Libertarian",
  }]
}, {
  name: "NC",
  candidates: [{
    first_name: "Shannon",
    last_name: "Bray",
    ballotpedia: "Shannon_Bray",
    party: "Libertarian",
  }, {
    first_name: "Cal",
    last_name: "Cunningham",
    ballotpedia: "Cal_Cunningham",
    wiki: "/wiki/Cal_Cunningham",
    party: "Democratic",
  }, {
    first_name: "Kevin",
    last_name: "Hayes",
    ballotpedia: "Kevin_E._Hayes",
    party: "Constitution",
  }, {
    first_name: "Thom",
    last_name: "Tillis",
    ballotpedia: "Thom_Tillis",
    wiki: "/wiki/Thom_Tillis",
    party: "Republican",
  }]
}, {
  name: "OK",
  candidates: [{
    first_name: "Abby",
    last_name: "Broyles",
    ballotpedia: "Abby_Broyles",
    wiki: "/wiki/Abby_Broyles",
    party: "Democratic",
  }, {
    first_name: "Joan",
    last_name: "Farr",
    ballotpedia: "_Farr",
    party: "Independent",
  }, {
    first_name: "Jim",
    last_name: "Inhofe",
    ballotpedia: "Jim_Inhofe",
    wiki: "/wiki/Jim_Inhofe",
    party: "Republican",
  }, {
    first_name: "Robert",
    last_name: "Murphy",
    ballotpedia: "Robert_Murphy_(Oklahoma)",
    party: "Libertarian",
  }, {
    first_name: "A. D.",
    last_name: "Nesbit",
    ballotpedia: "April_Nesbit",
    party: "Independent",
  }]
}, {
  name: "OR",
  candidates: [{
    first_name: "Gary",
    last_name: "Dye",
    ballotpedia: "Gary_Dye",
    party: "Libertarian",
  }, {
    first_name: "Jeff",
    last_name: "Merkley",
    ballotpedia: "Jeff_Merkley",
    wiki: "/wiki/Jeff_Merkley",
    party: "Democratic",
  }, {
    first_name: "Jo Rae",
    last_name: "Perkins",
    ballotpedia: "Jo_Rae_Perkins",
    wiki: "/wiki/Jo_Rae_Perkins",
    party: "Republican",
  }, {
    first_name: "Ibrahim",
    last_name: "Taher",
    ballotpedia: "Ibrahim_Taher",
    party: "Pacific Green",
  }]
}, {
  name: "RI",
  candidates: [{
    first_name: "Lenine",
    last_name: "Camacho",
    ballotpedia: "Lenine_Camacho",
    party: "Independent",
  }, {
    first_name: "Jack",
    last_name: "Reed",
    ballotpedia: "Jack_Reed",
    wiki: "/wiki/Jack_Reed_(politician)",
    party: "Democratic",
  }, {
    first_name: "Allen",
    last_name: "Waters",
    ballotpedia: "Allen_Waters",
    party: "Republican",
  }]
}, {
  name: "SC",
  candidates: [{
    first_name: "Bill",
    last_name: "Bledsoe",
    ballotpedia: "Bill_Bledsoe",
    party: "Constitution",
  }, {
    first_name: "Keenan",
    last_name: "Dunham",
    ballotpedia: "Keenan_Wallace_Dunham",
    party: "Libertarian",
    write_in: true,
  }, {
    first_name: "Lindsey",
    last_name: "Graham",
    ballotpedia: "Lindsey_Graham",
    wiki: "/wiki/Lindsey_Graham",
    party: "Republican",
  }, {
    first_name: "Jaime",
    last_name: "Harrison",
    ballotpedia: "Jaime_Harrison",
    wiki: "/wiki/Jaime_Harrison",
    party: "Democratic",
  }]
}, {
  name: "SD",
  candidates: [{
    first_name: "Dan",
    last_name: "Ahlers",
    ballotpedia: "Dan_Ahlers",
    wiki: "/wiki/Daniel_Ahlers",
    party: "Democratic",
  }, {
    first_name: "Mike",
    last_name: "Rounds",
    ballotpedia: "Mike_Rounds",
    wiki: "/wiki/Mike_Rounds",
    party: "Republican",
  }]
}, {
  name: "TN",
  candidates: [{
    first_name: "Marquita",
    last_name: "Bradshaw",
    ballotpedia: "Marquita_Bradshaw",
    wiki: "/wiki/Marquita_Bradshaw",
    party: "Democratic",
  }, {
    first_name: "Romi",
    last_name: "Faparusi",
    ballotpedia: "_Faparusi",
    party: "Independent",
  }, {
    first_name: "Jeffrey",
    last_name: "Grunau",
    ballotpedia: "Jeffrey_Grunau",
    party: "Independent",
  }, {
    first_name: "Bill",
    last_name: "Hagerty",
    ballotpedia: "Bill_Hagerty",
    wiki: "/wiki/Bill_Hagerty_(politician)",
    party: "Republican",
  }, {
    first_name: "Ronnie",
    last_name: "Henley",
    ballotpedia: "Ronnie_Henley",
    party: "Independent",
  }, {
    first_name: "Dean",
    last_name: "Hill",
    ballotpedia: "Dean_Hill_(Tennessee)",
    party: "Independent",
  }, {
    first_name: "Steven",
    last_name: "Hooper",
    ballotpedia: "Steven_Hooper_(Tennessee)",
    party: "Independent",
  }, {
    first_name: "Aaron",
    last_name: "James",
    ballotpedia: "Aaron_James_(Tennessee)",
    party: "Independent",
  }, {
    first_name: "Elizabeth",
    last_name: "McLeod",
    ballotpedia: "Elizabeth_McLeod",
    party: "Independent",
  }, {
    first_name: "Kacey",
    last_name: "Morgan",
    ballotpedia: "Kacey_Morgan",
    party: "Independent",
  }, {
    first_name: "Eric",
    last_name: "Stansberry",
    ballotpedia: "Eric_William_Stansberry",
    party: "Independent",
  }]
}, {
  name: "TX",
  candidates: [{
    first_name: "John",
    last_name: "Cornyn",
    ballotpedia: "John_Cornyn",
    wiki: "/wiki/John_Cornyn",
    party: "Republican",
  }, {
    first_name: "MJ",
    last_name: "Hegar",
    ballotpedia: "M.J._Hegar",
    wiki: "/wiki/MJ_Hegar",
    party: "Democratic",
  }, {
    first_name: "Kerry",
    last_name: "McKennon",
    ballotpedia: "_McKennon",
    party: "Libertarian",
  }, {
    first_name: "Ricardo",
    last_name: "Turullols-Bonilla",
    ballotpedia: "Ricardo_Turullols-Bonilla",
    party: "Independent",
    write_in: true,
  }]
}, {
  name: "VA",
  candidates: [{
    first_name: "Daniel",
    last_name: "Gade",
    ballotpedia: "Daniel_Gade",
    wiki: "/wiki/Daniel_Gade",
    party: "Republican",
  }, {
    first_name: "Mark",
    last_name: "Warner",
    ballotpedia: "Mark_Warner",
    wiki: "/wiki/Mark_Warner",
    party: "Democratic",
  }]
}, {
  name: "WV",
  candidates: [{
    first_name: "Shelley Moore",
    last_name: "Capito",
    ballotpedia: "Shelley_Moore_Capito",
    wiki: "/wiki/Shelley_Moore_Capito",
    party: "Republican",
  }, {
    first_name: "David",
    last_name: "Moran",
    ballotpedia: "David_Moran",
    party: "Libertarian",
  }, {
    first_name: "Paula Jean",
    last_name: "Swearengin",
    ballotpedia: "Paula_Jean_Swearengin",
    wiki: "/wiki/Paula_Jean_Swearengin",
    party: "Democratic",
  }]
}, {
  name: "WI",
  candidates: [{
    first_name: "Merav",
    last_name: "Ben-David",
    ballotpedia: "Merav_Ben-David",
    wiki: "/wiki/Merav_Ben-David",
    party: "Democratic",
  }, {
    first_name: "Cynthia",
    last_name: "Lummis",
    ballotpedia: "Cynthia_Lummis",
    wiki: "/wiki/Cynthia_Lummis",
    party: "Republican",
  }]
}];
