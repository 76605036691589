import React, { Component } from 'react';
import Input from './Input';

function Poll(props) {
  return(
    <div className="poll">
      <p style={{paddingBottom: '20px'}}>
        Welcome! Let's get started:
      </p>
      <h3 style={{textAlign: 'center', paddingBottom: '10px'}}>
        To find your elections, enter the zip code where you are registered.
      </h3>
      <Input example="10001" />
    </div>
  );
}

export default Poll;
