import React from "react";

function TitleBar() {
  return(
    <div className="title-bar">
      <div className="overlay" />
      <div className="horizontal-wrap">
        <img className="logo" src="/images/logos/topvote_us.png" alt="TopVote.us" />
        <p className="subtitle">Ranked. Weighted. Verified.</p>
      </div>
    </div>
  )
}

export default TitleBar
