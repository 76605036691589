import React, {useCallback, useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {statePathToCode, stateCodeToPath} from './StateInflectors';
import CanvasMap from './CanvasMap';
import USMap from './USMap';
import atLargeStates from './atLargeStates';

import HouseCandidates from './HouseCandidates';
import SenateCandidates from './SenateCandidates';
import PresidentialCandidates from './PresidentialCandidates';

function districtToInt(stateCode, district) {
  const districtParsed = parseInt(district);

  if (isNaN(districtParsed)) {
    if (atLargeStates.includes(stateCode)) {
      return 0;
    } else {
      return null;
    }
  } else {
    return districtParsed;
  }
}

function MapViewer(props) {
  const {office, asCanvas} = props;
  const {state, district} = useParams();
  const stateCode = statePathToCode(state);
  const districtInt = districtToInt(stateCode, district);
  const history = useHistory();

  const updateCanvasFocus = (updateStateCode, updateDistrict = null) => {
    const path =
      updateStateCode === null ? '' :
        (
          updateDistrict === null ?
          stateCodeToPath(updateStateCode) :
          stateCodeToPath(updateStateCode) + '/' + updateDistrict
        );

    history.push('/canvas/' + path);
  }

  const updateFocus = (updateOffice, updateStateCode, updateDistrict = null) => {
    let url = '/us/' + updateOffice;

    if (updateStateCode && updateOffice !== 'president') {
      url = url + '/' + stateCodeToPath(updateStateCode);
      if (updateDistrict && updateOffice !== 'senate') {
        url = url + '/' + updateDistrict;
      }
    }

    history.push(url);
  }

  const handleDistrictClick = (clickedState, clickedDistrict) => {
    if (office === 'house' || stateCode === clickedState) {
      if (asCanvas) {
        updateCanvasFocus(clickedState, clickedDistrict);
      } else {
        updateFocus('house', clickedState, clickedDistrict);
      }
    } else {
      if (asCanvas) {
        updateCanvasFocus(clickedState);
      } else {
        updateFocus('senate', clickedState);
      }
    }
  }

  function onEscape(event) {
    if(stateCode && event.keyCode === 27) {
      asCanvas ? updateCanvasFocus(district ? stateCode : null) : updateFocus(office, district ? stateCode : null);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", onEscape, false);

    return () => document.removeEventListener("keydown", onEscape, false);
  }, [stateCode, district]);

  return (
    <div className="map-viewer">
      {asCanvas ?
        <CanvasMap activeStateCode={stateCode} activeDistrictNumber={districtInt} handleDistrictClick={handleDistrictClick
        } /> :
        <USMap office={office} focusState={stateCode} focusDistrict={districtInt} handleDistrictClick={handleDistrictClick} />
      }
      {office==='house' ?
        <HouseCandidates state={stateCode} district={districtInt} /> :
        (office==='senate' ?
          <SenateCandidates state={stateCode} /> :
          <PresidentialCandidates />
        )
      }
    </div>
  );
}

export default MapViewer;
