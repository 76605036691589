const fakePresidentData = [
  {state: "AL", winner: "L"},
  {state: "AK", winner: "D"},
  {state: "AZ", winner: "R"},
  {state: "AR", winner: "G"},
  {state: "CA", winner: "L"},
  {state: "CO", winner: "D"},
  {state: "CT", winner: "R"},
  {state: "DE", winner: "G"},
  {state: "FL", winner: "L"},
  {state: "GA", winner: "D"},
  {state: "HI", winner: "R"},
  {state: "ID", winner: "G"},
  {state: "IL", winner: "L"},
  {state: "IN", winner: "D"},
  {state: "IA", winner: "R"},
  {state: "KS", winner: "G"},
  {state: "KY", winner: "L"},
  {state: "LA", winner: "D"},
  {state: "ME", winner: "R"},
  {state: "MD", winner: "G"},
  {state: "MA", winner: "L"},
  {state: "MI", winner: "D"},
  {state: "MN", winner: "R"},
  {state: "MS", winner: "G"},
  {state: "MO", winner: "L"},
  {state: "MT", winner: "D"},
  {state: "NE", winner: "R"},
  {state: "NV", winner: "G"},
  {state: "NH", winner: "L"},
  {state: "NJ", winner: "D"},
  {state: "NM", winner: "R"},
  {state: "NY", winner: "G"},
  {state: "NC", winner: "L"},
  {state: "ND", winner: "D"},
  {state: "OH", winner: "R"},
  {state: "OK", winner: "G"},
  {state: "OR", winner: "L"},
  {state: "PA", winner: "D"},
  {state: "RI", winner: "R"},
  {state: "SC", winner: "G"},
  {state: "SD", winner: "L"},
  {state: "TN", winner: "D"},
  {state: "TX", winner: "R"},
  {state: "UT", winner: "G"},
  {state: "VT", winner: "L"},
  {state: "VA", winner: "D"},
  {state: "WA", winner: "R"},
  {state: "WV", winner: "G"},
  {state: "WI", winner: "D"},
  {state: "WY", winner: "R"}
];

export default fakePresidentData
