import React from "react"
import {districtCandidates} from './districtCandidates';

function HouseLinks(props) {
  return (
    <>
      {districtCandidates.map((district, index1) =>
        district.candidates.map((candidate, index2) =>
          <p key={index1 * 441 + index2}>
            {candidate.first_name} {candidate.last_name} {district.state}-{district.district == 0 ? 'at-large' : district.district}
            <a href={'https://ballotpedia.org/' + candidate.ballotpedia}>LINK</a>
          </p>
        )
      )}
    </>
  );
}

export default HouseLinks;
