import React, { Component } from 'react';
import PropTypes from "prop-types";
import { render } from 'react-dom';
import Konva from 'konva';
import houseDistricts from './houseDistricts';
import stateLinesData from './stateLinesData';

class CanvasMap extends React.Component {
  constructor(props) {
    super(props);
    this.handleDistrictClick = this.handleDistrictClick.bind(this);
    this.buildDistrictPath = this.buildDistrictPath.bind(this);
    this.state = {
      activeStateCode: this.props.activeStateCode,
      activeDistrictNumber: this.props.activeDistrictNumber,
    }
  }

  componentDidMount() {
    this.stage = new Konva.Stage({
      container: 'us-map-container',
      width: window.innerWidth,
      height: window.innerHeight,
    });

    this.districts = this.buildDistrictsLayer();
    this.stateLines = this.buildStateLinesLayer();
    this.active = this.buildActiveLayer();

    this.stage.add(this.districts);
    this.stage.add(this.stateLines);
    this.stage.add(this.active);

    window.addEventListener('resize', this.size.bind(this));

    this.animate();
  }

  size() {
    this.stage.width(window.innerWidth);
    this.stage.height(window.innerHeight);
    this.stage.draw();
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeStateCode != this.state.activeStateCode || this.props.activeDistrictNumber != this.state.activeDistrictNumber) {
      const layer = this.active;
      layer.destroyChildren();

      if (this.props.activeDistrictNumber !== null) {
        const district = this.getDistrict(this.props.activeStateCode, this.props.activeDistrictNumber);
        const activeDistrictPath = this.buildDistrictPath(district, true);
        layer.add(activeDistrictPath);
      }

      this.stage.draw();

      this.setState({
        activeStateCode: this.props.activeStateCode,
        activeDistrictNumber: this.props.activeDistrictNumber,
      });
    }
  }

  handleDistrictClick(updateState, updateDistrict) {
    this.props.handleDistrictClick(updateState, updateDistrict);
  }

  buildDistrictsLayer() {
    const layer = new Konva.Layer();

    houseDistricts.forEach(district => layer.add(this.buildDistrictPath(district)));

    return layer;
  }

  getDistrict(stateCode, districtNumber) {
    return houseDistricts.find(district => (district.state === stateCode) && (district.number === districtNumber));
  }

  buildDistrictPath(district, active = false) {
    var d = district.d;
    var path = new Konva.Path({
      id: district.id,
      x: 0,
      y: 0,
      data: d,
      stroke: active ? '#0b0' : '#000',
      fill: '#fff',
      strokeWidth: active ? 1 : 0.5,
    });

    path.on('mousedown', e => {
      this.handleDistrictClick(district.state, district.number);
    });

    return path;
  }

  buildStateLinesLayer() {
    const layer = new Konva.Layer();

    var stateLinesPath = new Konva.Path({
      id: 'state-lines',
      x: 0,
      y: 0,
      data: stateLinesData,
      stroke: '#900',
      strokeWidth: 1
    });

    layer.add(stateLinesPath);

    return layer;
  }

  buildActiveLayer() {
    const layer = new Konva.Layer();

    if (this.state.activeDistrictNumber) {
      const district = this.getDistrict(this.state.activeStateCode, this.state.activeDistrictNumber);
      const activeDistrictPath = this.buildDistrictPath(district, true);
      layer.add(activeDistrictPath);
    }

    return layer;
  }

  animate() {
    const stage = this.stage;
    const districts = this.districts;

    var anim = new Konva.Animation(function(frame) {
      const time = frame.time;
      console.log(frame.frameRate);
      const paths = districts.find('Path');
      paths.each(function(path) {
        path.scale({x: time/1000, y: time/1000});
      });

      // stage.scale({x: time/1000, y: time/1000})
      }, [this.districts]);

    anim.start();

    setTimeout(function() { anim.stop(); }, 2000);
  }


  render() {
    return(
      <div id="us-map-container" />
    )
  }
}

CanvasMap.propTypes = {
  activeState: PropTypes.string,
  activeDistrict: PropTypes.number,
  handleDistrictClick: PropTypes.func
};

export default CanvasMap;
