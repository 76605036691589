export const districtCandidates =
[{
  state: "AL",
  number: 1,
  candidates: [{
    first_name: "James",
    last_name: "Averhart",
    ballotpedia: "James_Averhart",
    party: "Democratic",
  }, {
    first_name: "Jerry",
    last_name: "Carl",
    ballotpedia: "Jerry_Carl",
    party: "Republican",
  }]
}, {
  state: "AL",
  number: 2,
  candidates: [{
    first_name: "Phyllis",
    last_name: "Harvey-Hall",
    ballotpedia: "Phyllis_Harvey-Hall",
    party: "Democratic",
  }, {
    first_name: "Barry",
    last_name: "Moore",
    ballotpedia: "Barry_Moore_(Alabama)",
    party: "Republican",
    wikipedia: "/wiki/Barry_Moore_(Alabama_politician)",
  }]
}, {
  state: "AL",
  number: 3,
  candidates: [{
    first_name: "Mike",
    last_name: "Rogers",
    ballotpedia: "Mike_Rogers_(Alabama)",
    party: "Republican",
    wikipedia: "/wiki/Mike_Rogers_(Alabama_politician)",
  }, {
    first_name: "Adia",
    last_name: "Winfrey",
    ballotpedia: "Adia_Winfrey",
    party: "Democratic",
  }]
}, {
  state: "AL",
  number: 4,
  candidates: [{
    first_name: "Robert",
    last_name: "Aderholt",
    ballotpedia: "Robert_Aderholt",
    party: "Republican",
    wikipedia: "/wiki/Robert_Aderholt",
  }, {
    first_name: "Rick",
    last_name: "Neighbors",
    ballotpedia: "Rick_Neighbors",
    party: "Democratic",
  }]
}, {
  state: "AL",
  number: 5,
  candidates: [{
    first_name: "Mo",
    last_name: "Brooks",
    ballotpedia: "Mo_Brooks",
    party: "Republican",
    wikipedia: "/wiki/Mo_Brooks",
  }]
}, {
  state: "AL",
  number: 6,
  candidates: [{
    first_name: "Gary",
    last_name: "Palmer",
    ballotpedia: "Gary_Palmer",
    party: "Republican",
    wikipedia: "/wiki/Gary_Palmer_(politician)",
  }, {
    first_name: "Kaynen",
    last_name: "Pellegrino",
    ballotpedia: "Kaynen_Pellegrino",
    party: "Independent",
  }]
}, {
  state: "AL",
  number: 7,
  candidates: [{
    first_name: "Terri",
    last_name: "Sewell",
    ballotpedia: "Terri_Sewell",
    party: "Democratic",
    wikipedia: "/wiki/Terri_Sewell",
  }]
}, {
  state: "AK",
  number: 0,
  candidates: [{
    first_name: "Alyse",
    last_name: "Galvin",
    ballotpedia: "Alyse_Galvin",
    party: "Independent",
  }, {
    first_name: "Don",
    last_name: "Young",
    ballotpedia: "Don_Young_(Alaska)",
    party: "Republican",
    wikipedia: "/wiki/Don_Young",
  }]
}, {
  state: "AZ",
  number: 1,
  candidates: [{
    first_name: "Tom",
    last_name: "O'Halleran",
    ballotpedia: "Tom_O%27Halleran",
    party: "Democratic",
    wikipedia: "/wiki/Tom_O%27Halleran",
  }, {
    first_name: "Tiffany",
    last_name: "Shedd",
    ballotpedia: "Tiffany_Shedd",
    party: "Republican",
  }]
}, {
  state: "AZ",
  number: 2,
  candidates: [{
    first_name: "Ann",
    last_name: "Kirkpatrick",
    ballotpedia: "Ann_Kirkpatrick",
    party: "Democratic",
    wikipedia: "/wiki/Ann_Kirkpatrick",
  }, {
    first_name: "Brandon",
    last_name: "Martin",
    ballotpedia: "Brandon_Martin_(Arizona)",
    party: "Republican",
  }]
}, {
  state: "AZ",
  number: 3,
  candidates: [{
    first_name: "Raúl",
    last_name: "Grijalva",
    ballotpedia: "Raul_Grijalva",
    party: "Democratic",
    wikipedia: "/wiki/Ra%C3%BAl_Grijalva",
  }, {
    first_name: "Daniel",
    last_name: "Wood",
    ballotpedia: "Daniel_Wood_(Arizona)",
    party: "Republican",
  }]
}, {
  state: "AZ",
  number: 4,
  candidates: [{
    first_name: "Delina",
    last_name: "DiSanto",
    ballotpedia: "Delina_DiSanto",
    party: "Democratic",
  }, {
    first_name: "Paul",
    last_name: "Gosar",
    ballotpedia: "Paul_Gosar",
    party: "Republican",
    wikipedia: "/wiki/Paul_Gosar",
  }]
}, {
  state: "AZ",
  number: 5,
  candidates: [{
    first_name: "Andy",
    last_name: "Biggs",
    ballotpedia: "Andy_Biggs",
    party: "Republican",
    wikipedia: "/wiki/Andy_Biggs",
  }, {
    first_name: "Joan",
    last_name: "Greene",
    ballotpedia: "Joan_Greene",
    party: "Democratic",
  }]
}, {
  state: "AZ",
  number: 6,
  candidates: [{
    first_name: "David",
    last_name: "Schweikert",
    ballotpedia: "David_Schweikert",
    party: "Republican",
    wikipedia: "/wiki/David_Schweikert",
  }, {
    first_name: "Hiral",
    last_name: "Tipirneni",
    ballotpedia: "Hiral_Tipirneni",
    party: "Democratic",
  }]
}, {
  state: "AZ",
  number: 7,
  candidates: [{
    first_name: "Josh",
    last_name: "Barnett",
    ballotpedia: "Josh_Barnett",
    party: "Republican",
  }, {
    first_name: "Ruben",
    last_name: "Gallego",
    ballotpedia: "Ruben_Gallego",
    party: "Democratic",
    wikipedia: "/wiki/Ruben_Gallego",
  }]
}, {
  state: "AZ",
  number: 8,
  candidates: [{
    first_name: "Debbie",
    last_name: "Lesko",
    ballotpedia: "Debbie_Lesko",
    party: "Republican",
    wikipedia: "/wiki/Debbie_Lesko",
  }, {
    first_name: "Michael",
    last_name: "Muscato",
    ballotpedia: "Michael_Muscato",
    party: "Democratic",
  }]
}, {
  state: "AZ",
  number: 9,
  candidates: [{
    first_name: "Dave",
    last_name: "Giles",
    ballotpedia: "Dave_Giles",
    party: "Republican",
  }, {
    first_name: "Greg",
    last_name: "Stanton",
    ballotpedia: "Greg_Stanton",
    party: "Democratic",
    wikipedia: "/wiki/Greg_Stanton",
  }]
}, {
  state: "AR",
  number: 1,
  candidates: [{
    first_name: "Rick",
    last_name: "Crawford",
    ballotpedia: "Rick_Crawford_(Arkansas)",
    party: "Republican",
    wikipedia: "/wiki/Rick_Crawford_(politician)",
  }]
}, {
  state: "AR",
  number: 2,
  candidates: [{
    first_name: "Joyce",
    last_name: "Elliott",
    ballotpedia: "Joyce_Elliott",
    party: "Democratic",
    wikipedia: "/wiki/Joyce_Elliott",
  }, {
    first_name: "French",
    last_name: "Hill",
    ballotpedia: "French_Hill",
    party: "Republican",
    wikipedia: "/wiki/French_Hill_(politician)",
  }, {
    first_name: "J. Glenn",
    last_name: "Smith",
    ballotpedia: "J._Glenn_Smith",
    party: "Independent",
  }]
}, {
  state: "AR",
  number: 3,
  candidates: [{
    first_name: "Michael",
    last_name: "Kalagias",
    ballotpedia: "Michael_Kalagias",
    party: "Libertarian",
  }, {
    first_name: "Celeste",
    last_name: "Williams",
    ballotpedia: "Celeste_Williams",
    party: "Democratic",
  }, {
    first_name: "Steve",
    last_name: "Womack",
    ballotpedia: "Steve_Womack",
    party: "Republican",
    wikipedia: "/wiki/Steve_Womack",
  }]
}, {
  state: "AR",
  number: 4,
  candidates: [{
    first_name: "Frank",
    last_name: "Gilbert",
    ballotpedia: "Frank_Gilbert",
    party: "Libertarian",
  }, {
    first_name: "William",
    last_name: "Hanson",
    ballotpedia: "William_Hanson_(Arkansas)",
    party: "Democratic",
  }, {
    first_name: "Bruce",
    last_name: "Westerman",
    ballotpedia: "Bruce_Westerman",
    party: "Republican",
    wikipedia: "/wiki/Bruce_Westerman",
  }]
}, {
  state: "CA",
  number: 1,
  candidates: [{
    first_name: "Audrey",
    last_name: "Denney",
    ballotpedia: "Audrey_Denney",
    party: "Democratic",
  }, {
    first_name: "Doug",
    last_name: "LaMalfa",
    ballotpedia: "Doug_LaMalfa",
    party: "Republican",
    wikipedia: "/wiki/Doug_LaMalfa",
  }]
}, {
  state: "CA",
  number: 2,
  candidates: [{
    first_name: "Jared",
    last_name: "Huffman",
    ballotpedia: "Jared_Huffman",
    party: "Democratic",
    wikipedia: "/wiki/Jared_Huffman",
  }, {
    first_name: "Dale",
    last_name: "Mensing",
    ballotpedia: "Dale_Mensing",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 3,
  candidates: [{
    first_name: "John",
    last_name: "Garamendi",
    ballotpedia: "John_Garamendi",
    party: "Democratic",
    wikipedia: "/wiki/John_Garamendi",
  }, {
    first_name: "Tamika",
    last_name: "Hamilton",
    ballotpedia: "Tamika_Hamilton",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 4,
  candidates: [{
    first_name: "Brynne",
    last_name: "Kennedy",
    ballotpedia: "Brynne_Kennedy",
    party: "Democratic",
  }, {
    first_name: "Tom",
    last_name: "McClintock",
    ballotpedia: "Tom_McClintock",
    party: "Republican",
    wikipedia: "/wiki/Tom_McClintock",
  }]
}, {
  state: "CA",
  number: 5,
  candidates: [{
    first_name: "Scott",
    last_name: "Giblin",
    ballotpedia: "Scott_Giblin",
    party: "Republican",
  }, {
    first_name: "Mike",
    last_name: "Thompson",
    ballotpedia: "Mike_Thompson_(California)",
    party: "Democratic",
    wikipedia: "/wiki/Mike_Thompson_(California_politician)",
  }]
}, {
  state: "CA",
  number: 6,
  candidates: [{
    first_name: "Chris",
    last_name: "Bish",
    ballotpedia: "Chris_Bish",
    party: "Republican",
  }, {
    first_name: "Doris",
    last_name: "Matsui",
    ballotpedia: "Doris_Matsui",
    party: "Democratic",
    wikipedia: "/wiki/Doris_Matsui",
  }]
}, {
  state: "CA",
  number: 7,
  candidates: [{
    first_name: "Ami",
    last_name: "Bera",
    ballotpedia: "Ami_Bera",
    party: "Democratic",
    wikipedia: "/wiki/Ami_Bera",
  }, {
    first_name: "Buzz",
    last_name: "Patterson",
    ballotpedia: "Buzz_Patterson",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 8,
  candidates: [{
    first_name: "Chris",
    last_name: "Bubser",
    ballotpedia: "Chris_Bubser",
    party: "Democratic",
  }, {
    first_name: "Jay",
    last_name: "Obernolte",
    ballotpedia: "Jay_Obernolte",
    party: "Republican",
    wikipedia: "/wiki/Jay_Obernolte",
  }]
}, {
  state: "CA",
  number: 9,
  candidates: [{
    first_name: "Tony",
    last_name: "Amador",
    ballotpedia: "Tony_Amador",
    party: "Republican",
  }, {
    first_name: "Jerry",
    last_name: "McNerney",
    ballotpedia: "Jerry_McNerney",
    party: "Democratic",
    wikipedia: "/wiki/Jerry_McNerney",
  }]
}, {
  state: "CA",
  number: 10,
  candidates: [{
    first_name: "Josh",
    last_name: "Harder",
    ballotpedia: "Josh_Harder",
    party: "Democratic",
    wikipedia: "/wiki/Josh_Harder",
  }, {
    first_name: "Ted",
    last_name: "Howze",
    ballotpedia: "Ted_Howze",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 11,
  candidates: [{
    first_name: "Mark",
    last_name: "DeSaulnier",
    ballotpedia: "Mark_DeSaulnier",
    party: "Democratic",
    wikipedia: "/wiki/Mark_DeSaulnier",
  }, {
    first_name: "Nisha",
    last_name: "Sharma",
    ballotpedia: "Nisha_Sharma",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 12,
  candidates: [{
    first_name: "Shahid",
    last_name: "Buttar",
    ballotpedia: "Shahid_Buttar",
    party: "Democratic",
  }, {
    first_name: "Nancy",
    last_name: "Pelosi",
    ballotpedia: "Nancy_Pelosi",
    party: "Democratic",
    wikipedia: "/wiki/Nancy_Pelosi",
  }]
}, {
  state: "CA",
  number: 13,
  candidates: [{
    first_name: "Barbara",
    last_name: "Lee",
    ballotpedia: "Barbara_Lee_(California)",
    party: "Democratic",
    wikipedia: "/wiki/Barbara_Lee",
  }, {
    first_name: "Nikka",
    last_name: "Piterman",
    ballotpedia: "Nikka_Piterman",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 14,
  candidates: [{
    first_name: "Ran",
    last_name: "Petel",
    ballotpedia: "Ran_Petel",
    party: "Republican",
  }, {
    first_name: "Jackie",
    last_name: "Speier",
    ballotpedia: "Jackie_Speier",
    party: "Democratic",
    wikipedia: "/wiki/Jackie_Speier",
  }]
}, {
  state: "CA",
  number: 15,
  candidates: [{
    first_name: "Alison",
    last_name: "Hayden",
    ballotpedia: "Alison_Hayden",
    party: "Republican",
  }, {
    first_name: "Eric",
    last_name: "Swalwell",
    ballotpedia: "Eric_Swalwell",
    party: "Democratic",
    wikipedia: "/wiki/Eric_Swalwell",
  }]
}, {
  state: "CA",
  number: 16,
  candidates: [{
    first_name: "Kevin",
    last_name: "Cookingham",
    ballotpedia: "Kevin_Cookingham",
    party: "Republican",
  }, {
    first_name: "Jim",
    last_name: "Costa",
    ballotpedia: "Jim_Costa",
    party: "Democratic",
    wikipedia: "/wiki/Jim_Costa",
  }]
}, {
  state: "CA",
  number: 17,
  candidates: [{
    first_name: "Ro",
    last_name: "Khanna",
    ballotpedia: "Ro_Khanna",
    party: "Democratic",
    wikipedia: "/wiki/Ro_Khanna",
  }, {
    first_name: "Ritesh",
    last_name: "Tandon",
    ballotpedia: "Ritesh_Tandon",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 18,
  candidates: [{
    first_name: "Anna",
    last_name: "Eshoo",
    ballotpedia: "Anna_Eshoo",
    party: "Democratic",
    wikipedia: "/wiki/Anna_Eshoo",
  }, {
    first_name: "Rishi",
    last_name: "Kumar",
    ballotpedia: "Rishi_Kumar",
    party: "Democratic",
  }]
}, {
  state: "CA",
  number: 19,
  candidates: [{
    first_name: "Justin",
    last_name: "Aguilera",
    ballotpedia: "Justin_Aguilera",
    party: "Republican",
  }, {
    first_name: "Zoe",
    last_name: "Lofgren",
    ballotpedia: "Zoe_Lofgren",
    party: "Democratic",
    wikipedia: "/wiki/Zoe_Lofgren",
  }]
}, {
  state: "CA",
  number: 20,
  candidates: [{
    first_name: "Jeff",
    last_name: "Gorman",
    ballotpedia: "Jeff_Gorman_(California)",
    party: "Republican",
  }, {
    first_name: "Jimmy",
    last_name: "Panetta",
    ballotpedia: "Jimmy_Panetta",
    party: "Democratic",
    wikipedia: "/wiki/Jimmy_Panetta",
  }]
}, {
  state: "CA",
  number: 21,
  candidates: [{
    first_name: "TJ",
    last_name: "Cox",
    ballotpedia: "TJ_Cox",
    party: "Democratic",
    wikipedia: "/wiki/TJ_Cox",
  }, {
    first_name: "David",
    last_name: "Valadao",
    ballotpedia: "David_Valadao",
    party: "Republican",
    wikipedia: "/wiki/David_Valadao",
  }]
}, {
  state: "CA",
  number: 22,
  candidates: [{
    first_name: "Phil",
    last_name: "Arballo",
    ballotpedia: "Phil_Arballo",
    party: "Democratic",
    wikipedia: "/wiki/Phil_Arballo",
  }, {
    first_name: "Devin",
    last_name: "Nunes",
    ballotpedia: "Devin_Nunes",
    party: "Republican",
    wikipedia: "/wiki/Devin_Nunes",
  }]
}, {
  state: "CA",
  number: 23,
  candidates: [{
    first_name: "Kim",
    last_name: "Mangone",
    ballotpedia: "Kim_Mangone",
    party: "Democratic",
  }, {
    first_name: "Kevin",
    last_name: "McCarthy",
    ballotpedia: "Kevin_McCarthy_(California)",
    party: "Republican",
    wikipedia: "/wiki/Kevin_McCarthy_(California_politician)",
  }]
}, {
  state: "CA",
  number: 24,
  candidates: [{
    first_name: "Andy",
    last_name: "Caldwell",
    ballotpedia: "Andy_Caldwell",
    party: "Republican",
  }, {
    first_name: "Salud",
    last_name: "Carbajal",
    ballotpedia: "Salud_Carbajal",
    party: "Democratic",
    wikipedia: "/wiki/Salud_Carbajal",
  }]
}, {
  state: "CA",
  number: 25,
  candidates: [{
    first_name: "Mike",
    last_name: "Garcia",
    ballotpedia: "Mike_Garcia",
    party: "Republican",
    wikipedia: "/wiki/Mike_Garcia_(politician)",
  }, {
    first_name: "Christy",
    last_name: "Smith",
    ballotpedia: "Christy_Smith",
    party: "Democratic",
    wikipedia: "/wiki/Christy_Smith_(politician)",
  }]
}, {
  state: "CA",
  number: 26,
  candidates: [{
    first_name: "Ronda",
    last_name: "Kennedy",
    ballotpedia: "Ronda_Baldwin-Kennedy",
    party: "Republican",
  }, {
    first_name: "Julia",
    last_name: "Brownley",
    ballotpedia: "Julia_Brownley",
    party: "Democratic",
    wikipedia: "/wiki/Julia_Brownley",
  }]
}, {
  state: "CA",
  number: 27,
  candidates: [{
    first_name: "Judy",
    last_name: "Chu",
    ballotpedia: "Judy_Chu",
    party: "Democratic",
    wikipedia: "/wiki/Judy_Chu",
  }, {
    first_name: "Johnny",
    last_name: "Nalbandian",
    ballotpedia: "Johnny_Nalbandian",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 28,
  candidates: [{
    first_name: "Eric",
    last_name: "Early",
    ballotpedia: "Eric_Early",
    party: "Republican",
  }, {
    first_name: "Adam",
    last_name: "Schiff",
    ballotpedia: "Adam_Schiff",
    party: "Democratic",
    wikipedia: "/wiki/Adam_B._Schiff",
  }]
}, {
  state: "CA",
  number: 29,
  candidates: [{
    first_name: "Tony",
    last_name: "Cárdenas",
    ballotpedia: "Tony_Cárdenas",
    party: "Democratic",
    wikipedia: "/wiki/Tony_C%C3%A1rdenas",
  }, {
    first_name: "Angélica",
    last_name: "Dueñas",
    ballotpedia: "Angelica_Dueñas",
    party: "Democratic",
  }]
}, {
  state: "CA",
  number: 30,
  candidates: [{
    first_name: "Mark",
    last_name: "Reed",
    ballotpedia: "Mark_Reed_(California)",
    party: "Republican",
  }, {
    first_name: "Brad",
    last_name: "Sherman",
    ballotpedia: "Brad_Sherman",
    party: "Democratic",
    wikipedia: "/wiki/Brad_Sherman",
  }]
}, {
  state: "CA",
  number: 31,
  candidates: [{
    first_name: "Pete",
    last_name: "Aguilar",
    ballotpedia: "Pete_Aguilar",
    party: "Democratic",
    wikipedia: "/wiki/Pete_Aguilar",
  }, {
    first_name: "Agnes",
    last_name: "Gibboney",
    ballotpedia: "Agnes_Gibboney",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 32,
  candidates: [{
    first_name: "Grace",
    last_name: "Napolitano",
    ballotpedia: "Grace_Napolitano",
    party: "Democratic",
    wikipedia: "/wiki/Grace_F._Napolitano",
  }, {
    first_name: "Joshua",
    last_name: "Scott",
    ballotpedia: "Joshua_Scott",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 33,
  candidates: [{
    first_name: "James",
    last_name: "Bradley",
    ballotpedia: "James_Bradley_(California)",
    party: "Republican",
  }, {
    first_name: "Ted",
    last_name: "Lieu",
    ballotpedia: "Ted_Lieu",
    party: "Democratic",
    wikipedia: "/wiki/Ted_W._Lieu",
  }]
}, {
  state: "CA",
  number: 34,
  candidates: [{
    first_name: "Jimmy",
    last_name: "Gomez",
    ballotpedia: "Jimmy_Gomez",
    party: "Democratic",
    wikipedia: "/wiki/Jimmy_Gomez",
  }, {
    first_name: "David",
    last_name: "Kim",
    ballotpedia: "David_Kim_(California)",
    party: "Democratic",
  }]
}, {
  state: "CA",
  number: 35,
  candidates: [{
    first_name: "Mike",
    last_name: "Cargile",
    ballotpedia: "Mike_Cargile",
    party: "Republican",
  }, {
    first_name: "Norma",
    last_name: "Torres",
    ballotpedia: "Norma_Torres",
    party: "Democratic",
    wikipedia: "/wiki/Norma_J._Torres",
  }]
}, {
  state: "CA",
  number: 36,
  candidates: [{
    first_name: "Erin",
    last_name: "Cruz",
    ballotpedia: "Erin_Cruz",
    party: "Republican",
  }, {
    first_name: "Raul",
    last_name: "Ruiz",
    ballotpedia: "Raul_Ruiz",
    party: "Democratic",
    wikipedia: "/wiki/Raul_Ruiz_(politician)",
  }]
}, {
  state: "CA",
  number: 37,
  candidates: [{
    first_name: "Karen",
    last_name: "Bass",
    ballotpedia: "Karen_Bass",
    party: "Democratic",
    wikipedia: "/wiki/Karen_Bass",
  }, {
    first_name: "Errol",
    last_name: "Webber",
    ballotpedia: "Errol_Webber",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 38,
  candidates: [{
    first_name: "Linda",
    last_name: "Sanchez",
    ballotpedia: "Linda_Sanchez",
    party: "Democratic",
    wikipedia: "/wiki/Linda_T._S%C3%A1nchez",
  }, {
    first_name: "Michael",
    last_name: "Tolar",
    ballotpedia: "Michael_Tolar",
    party: "Democratic",
  }]
}, {
  state: "CA",
  number: 39,
  candidates: [{
    first_name: "Gil",
    last_name: "Cisneros",
    ballotpedia: "Gil_Cisneros",
    party: "Democratic",
    wikipedia: "/wiki/Gil_Cisneros",
  }, {
    first_name: "Young",
    last_name: "Kim",
    ballotpedia: "Young_Kim_(California)",
    party: "Republican",
    wikipedia: "/wiki/Young_Kim",
  }]
}, {
  state: "CA",
  number: 40,
  candidates: [{
    first_name: "Lucille",
    last_name: "Roybal-Allard",
    ballotpedia: "Lucille_Roybal-Allard",
    party: "Democratic",
    wikipedia: "/wiki/Lucille_Roybal-Allard",
  }, {
    first_name: "C. Antonio",
    last_name: "Delgado",
    ballotpedia: "C._Antonio_Delgado_(California)",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 41,
  candidates: [{
    first_name: "Aja",
    last_name: "Smith",
    ballotpedia: "Aja_Smith",
    party: "Republican",
  }, {
    first_name: "Mark",
    last_name: "Takano",
    ballotpedia: "Mark_Takano",
    party: "Democratic",
    wikipedia: "/wiki/Mark_Takano",
  }]
}, {
  state: "CA",
  number: 42,
  candidates: [{
    first_name: "Ken",
    last_name: "Calvert",
    ballotpedia: "Ken_Calvert",
    party: "Republican",
    wikipedia: "/wiki/Ken_Calvert",
  }, {
    first_name: "Liam",
    last_name: "O'Mara",
    ballotpedia: "William_O%27Mara",
    party: "Democratic",
  }]
}, {
  state: "CA",
  number: 43,
  candidates: [{
    first_name: "Joe",
    last_name: "Collins",
    ballotpedia: "Joe_Collins_(California)",
    party: "Republican",
  }, {
    first_name: "Maxine",
    last_name: "Waters",
    ballotpedia: "Maxine_Waters",
    party: "Democratic",
    wikipedia: "/wiki/Maxine_Waters",
  }]
}, {
  state: "CA",
  number: 44,
  candidates: [{
    first_name: "Nanette",
    last_name: "Barragán",
    ballotpedia: "Nanette_Barragán",
    party: "Democratic",
    wikipedia: "/wiki/Nanette_Barrag%C3%A1n",
  }, {
    first_name: "Analilia",
    last_name: "Joya",
    ballotpedia: "Analilia_Joya",
    party: "Democratic",
  }]
}, {
  state: "CA",
  number: 45,
  candidates: [{
    first_name: "Katie",
    last_name: "Porter",
    ballotpedia: "Katie_Porter",
    party: "Democratic",
    wikipedia: "/wiki/Katie_Porter",
  }, {
    first_name: "Greg",
    last_name: "Raths",
    ballotpedia: "Greg_Raths",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 46,
  candidates: [{
    first_name: "Lou",
    last_name: "Correa",
    ballotpedia: "Lou_Correa",
    party: "Democratic",
    wikipedia: "/wiki/Lou_Correa",
  }, {
    first_name: "James",
    last_name: "Waters",
    ballotpedia: "James_Waters",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 47,
  candidates: [{
    first_name: "John",
    last_name: "Briscoe",
    ballotpedia: "John_Briscoe",
    party: "Republican",
  }, {
    first_name: "Alan",
    last_name: "Lowenthal",
    ballotpedia: "Alan_Lowenthal",
    party: "Democratic",
    wikipedia: "/wiki/Alan_Lowenthal",
  }]
}, {
  state: "CA",
  number: 48,
  candidates: [{
    first_name: "Harley",
    last_name: "Rouda",
    ballotpedia: "Harley_Rouda",
    party: "Democratic",
    wikipedia: "/wiki/Harley_Rouda",
  }, {
    first_name: "Michelle",
    last_name: "Steel",
    ballotpedia: "Michelle_Steel",
    party: "Republican",
    wikipedia: "/wiki/Michelle_Steel",
  }]
}, {
  state: "CA",
  number: 49,
  candidates: [{
    first_name: "Mike",
    last_name: "Levin",
    ballotpedia: "Mike_Levin",
    party: "Democratic",
    wikipedia: "/wiki/Mike_Levin",
  }, {
    first_name: "Brian",
    last_name: "Maryott",
    ballotpedia: "Brian_Maryott",
    party: "Republican",
  }]
}, {
  state: "CA",
  number: 50,
  candidates: [{
    first_name: "Ammar",
    last_name: "Campa-Najjar",
    ballotpedia: "Ammar_Campa-Najjar",
    party: "Democratic",
    wikipedia: "/wiki/Ammar_Campa-Najjar",
  }, {
    first_name: "Darrell",
    last_name: "Issa",
    ballotpedia: "Darrell_Issa",
    party: "Republican",
    wikipedia: "/wiki/Darrell_Issa",
  }]
}, {
  state: "CA",
  number: 51,
  candidates: [{
    first_name: "Juan",
    last_name: "Hidalgo",
    suffix: "Jr.",
    ballotpedia: "Juan_Hidalgo_Jr.",
    party: "Republican",
  }, {
    first_name: "Juan",
    last_name: "Vargas",
    ballotpedia: "Juan_Vargas",
    party: "Democratic",
    wikipedia: "/wiki/Juan_Vargas",
  }]
}, {
  state: "CA",
  number: 52,
  candidates: [{
    first_name: "Jim",
    last_name: "DeBello",
    ballotpedia: "Jim_DeBello",
    party: "Republican",
  }, {
    first_name: "Scott",
    last_name: "Peters",
    ballotpedia: "Scott_Peters",
    party: "Democratic",
    wikipedia: "/wiki/Scott_Peters_(politician)",
  }]
}, {
  state: "CA",
  number: 53,
  candidates: [{
    first_name: "Georgette",
    last_name: "Gómez",
    ballotpedia: "Georgette_Gómez",
    party: "Democratic",
    wikipedia: "/wiki/Georgette_G%C3%B3mez",
  }, {
    first_name: "Sara",
    last_name: "Jacobs",
    ballotpedia: "Sara_Jacobs",
    party: "Democratic",
  }]
}, {
  state: "CO",
  number: 1,
  candidates: [{
    first_name: "Shane",
    last_name: "Bolling",
    ballotpedia: "Shane_Bolling",
    party: "Republican",
  }, {
    first_name: "Diana",
    last_name: "DeGette",
    ballotpedia: "Diana_DeGette",
    party: "Democratic",
    wikipedia: "/wiki/Diana_DeGette",
  }, {
    first_name: "Paul",
    last_name: "Fiorino",
    ballotpedia: "Paul_Fiorino",
    party: "Unity",
  }, {
    first_name: "Kyle",
    last_name: "Furey",
    ballotpedia: "Kyle_Furey",
    party: "Libertarian",
  }, {
    first_name: "Jan",
    last_name: "Kok",
    ballotpedia: "Jan_Kok",
    party: "Approval Voting",
  }]
}, {
  state: "CO",
  number: 2,
  candidates: [{
    first_name: "Thom",
    last_name: "Atkinson",
    ballotpedia: "Thom_Atkinson",
    party: "Libertarian",
  }, {
    first_name: "Joe",
    last_name: "Neguse",
    ballotpedia: "Joe_Neguse",
    party: "Democratic",
    wikipedia: "/wiki/Joe_Neguse",
  }, {
    first_name: "Gary",
    last_name: "Swing",
    ballotpedia: "Gary_Swing",
    party: "Unity",
  }, {
    first_name: "Charlie",
    last_name: "Winn",
    ballotpedia: "Charles_Winn",
    party: "Republican",
  }]
}, {
  state: "CO",
  number: 3,
  candidates: [{
    first_name: "Lauren",
    last_name: "Boebert",
    ballotpedia: "Lauren_Boebert",
    party: "Republican",
    wikipedia: "/wiki/Lauren_Boebert",
  }, {
    first_name: "Diane Mitsch",
    last_name: "Bush",
    ballotpedia: "Diane_Mitsch_Bush",
    party: "Democratic",
    wikipedia: "/wiki/Diane_Mitsch_Bush",
  }, {
    first_name: "John Ryan",
    last_name: "Keil",
    ballotpedia: "John_Keil",
    party: "Libertarian",
  }, {
    first_name: "Critter",
    last_name: "Milton",
    ballotpedia: "Critter_Milton",
    party: "Unity",
  }]
}, {
  state: "CO",
  number: 4,
  candidates: [{
    first_name: "Ken",
    last_name: "Buck",
    ballotpedia: "Ken_Buck",
    party: "Republican",
    wikipedia: "/wiki/Ken_Buck",
  }, {
    first_name: "Bruce",
    last_name: "Griffith",
    ballotpedia: "Bruce_Griffith",
    party: "Libertarian",
  }, {
    first_name: "Laura",
    last_name: "Ireland",
    ballotpedia: "Laura_Ireland",
    party: "Unity",
  }, {
    first_name: "Ike",
    last_name: "McCorkle",
    ballotpedia: "Isaac_McCorkle",
    party: "Democratic",
  }]
}, {
  state: "CO",
  number: 5,
  candidates: [{
    first_name: "Ed",
    last_name: "Duffett",
    ballotpedia: "Ed_Duffett",
    party: "Libertarian",
  }, {
    first_name: "Jillian",
    last_name: "Freeland",
    ballotpedia: "Jillian_Freeland",
    party: "Democratic",
  }, {
    first_name: "Rebecca",
    last_name: "Keltie",
    ballotpedia: "Rebecca_Keltie",
    party: "Unity",
  }, {
    first_name: "Doug",
    last_name: "Lamborn",
    ballotpedia: "Doug_Lamborn",
    party: "Republican",
    wikipedia: "/wiki/Doug_Lamborn",
  }, {
    first_name: "Marcus",
    last_name: "Murphy",
    ballotpedia: "Marcus_Murphy",
    party: "Independent",
  }]
}, {
  state: "CO",
  number: 6,
  candidates: [{
    first_name: "Jason",
    last_name: "Crow",
    ballotpedia: "Jason_Crow",
    party: "Democratic",
    wikipedia: "/wiki/Jason_Crow",
  }, {
    first_name: "Steve",
    last_name: "House",
    ballotpedia: "Steve_House",
    party: "Republican",
  }, {
    first_name: "Jaimie",
    last_name: "Kulikowski",
    ballotpedia: "Jaimie_Kulikowski",
    party: "Unity",
  }, {
    first_name: "Norm",
    last_name: "Olsen",
    ballotpedia: "Norm_Olsen",
    party: "Libertarian",
  }]
}, {
  state: "CO",
  number: 7,
  candidates: [{
    first_name: "Ken",
    last_name: "Biles",
    ballotpedia: "Ken_Biles",
    party: "Libertarian",
  }, {
    first_name: "David R.",
    last_name: "Olszta",
    ballotpedia: "David_R._Olszta",
    party: "Unity",
  }, {
    first_name: "Ed",
    last_name: "Perlmutter",
    ballotpedia: "Ed_Perlmutter",
    party: "Democratic",
    wikipedia: "/wiki/Ed_Perlmutter",
  }, {
    first_name: "Casper",
    last_name: "Stockham",
    ballotpedia: "Casper_Stockham",
    party: "Republican",
  }]
}, {
  state: "CT",
  number: 1,
  candidates: [{
    first_name: "Mary",
    last_name: "Fay",
    ballotpedia: "Mary_Fay",
    party: "Republican",
  }, {
    first_name: "John",
    last_name: "Larson",
    ballotpedia: "John_Larson_(Connecticut)",
    party: "Democratic",
    wikipedia: "/wiki/John_B._Larson",
  }]
}, {
  state: "CT",
  number: 2,
  candidates: [{
    first_name: "Justin",
    last_name: "Anderson",
    ballotpedia: "Justin_Anderson_(Connecticut)",
    party: "Republican",
  }, {
    first_name: "Joe",
    last_name: "Courtney",
    ballotpedia: "Joe_Courtney",
    party: "Democratic",
    wikipedia: "/wiki/Joe_Courtney_(politician)",
  }]
}, {
  state: "CT",
  number: 3,
  candidates: [{
    first_name: "Rosa",
    last_name: "DeLauro",
    ballotpedia: "Rosa_DeLauro",
    party: "Democratic",
    wikipedia: "/wiki/Rosa_DeLauro",
  }, {
    first_name: "Margaret",
    last_name: "Streicker",
    ballotpedia: "Margaret_Streicker",
    party: "Republican",
  }]
}, {
  state: "CT",
  number: 4,
  candidates: [{
    first_name: "Jim",
    last_name: "Himes",
    ballotpedia: "Jim_Himes",
    party: "Democratic",
    wikipedia: "/wiki/Jim_Himes",
  }, {
    first_name: "Jonathan",
    last_name: "Riddle",
    ballotpedia: "Jonathan_Riddle",
    party: "Republican",
  }]
}, {
  state: "CT",
  number: 5,
  candidates: [{
    first_name: "Jahana",
    last_name: "Hayes",
    ballotpedia: "Jahana_Hayes",
    party: "Democratic",
    wikipedia: "/wiki/Jahana_Hayes",
  }, {
    first_name: "David X.",
    last_name: "Sullivan",
    ballotpedia: "David_Xavier_Sullivan_(Connecticut)",
    party: "Republican",
  }]
}, {
  state: "DE",
  number: 0,
  candidates: [{
    first_name: "Lisa Blunt",
    last_name: "Rochester",
    ballotpedia: "Lisa_Blunt_Rochester",
    party: "Democratic",
    wikipedia: "/wiki/Lisa_Blunt_Rochester",
  }, {
    first_name: "Matthew",
    last_name: "Morris",
    ballotpedia: "Matthew_Morris",
    party: "Republican",
  }, {
    first_name: "Catherine",
    last_name: "Purcell",
    ballotpedia: "Catherine_Purcell",
    party: "Independent",
  }, {
    first_name: "David",
    last_name: "Rogers",
    ballotpedia: "David_Rogers_(Delaware)",
    party: "Libertarian",
  }, {
    first_name: "Lee",
    last_name: "Murphy",
    ballotpedia: "Lee_Murphy_(Delaware)",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 1,
  candidates: [{
    first_name: "Phil",
    last_name: "Ehr",
    ballotpedia: "Phil_Ehr",
    party: "Democratic",
  }, {
    first_name: "Matt",
    last_name: "Gaetz",
    ballotpedia: "Matt_Gaetz",
    party: "Republican",
    wikipedia: "/wiki/Matt_Gaetz",
  }, {
    first_name: "Albert",
    last_name: "Oram",
    ballotpedia: "Albert_Oram",
    party: "Independent",
  }]
}, {
  state: "FL",
  number: 2,
  candidates: [{
    first_name: "Neal",
    last_name: "Dunn",
    ballotpedia: "Neal_Dunn",
    party: "Republican",
    wikipedia: "/wiki/Neal_Dunn",
  }]
}, {
  state: "FL",
  number: 3,
  candidates: [{
    first_name: "Kat",
    last_name: "Cammack",
    ballotpedia: "Kat_Cammack",
    party: "Republican",
    wikipedia: "/wiki/Kat_Cammack",
  }, {
    first_name: "Adam",
    last_name: "Christensen",
    ballotpedia: "Adam_Christensen",
    party: "Democratic",
  }]
}, {
  state: "FL",
  number: 4,
  candidates: [{
    first_name: "Donna",
    last_name: "Deegan",
    ballotpedia: "Donna_Deegan",
    party: "Democratic",
    wikipedia: "/wiki/Donna_Deegan",
  }, {
    first_name: "John",
    last_name: "Rutherford",
    ballotpedia: "John_Rutherford_(Florida)",
    party: "Republican",
    wikipedia: "/wiki/John_Rutherford_(Florida_politician)",
  }]
}, {
  state: "FL",
  number: 5,
  candidates: [{
    first_name: "Gary",
    last_name: "Adler",
    ballotpedia: "Gary_Adler",
    party: "Republican",
  }, {
    first_name: "Al",
    last_name: "Lawson",
    ballotpedia: "Al_Lawson",
    party: "Democratic",
    wikipedia: "/wiki/Al_Lawson",
  }]
}, {
  state: "FL",
  number: 6,
  candidates: [{
    first_name: "Clint",
    last_name: "Curtis",
    ballotpedia: "Clint_Curtis",
    party: "Democratic",
    wikipedia: "/wiki/Clint_Curtis",
  }, {
    first_name: "Michael",
    last_name: "Waltz",
    ballotpedia: "Michael_Waltz",
    party: "Republican",
    wikipedia: "/wiki/Michael_Waltz",
  }]
}, {
  state: "FL",
  number: 7,
  candidates: [{
    first_name: "William",
    last_name: "Garlington",
    ballotpedia: "William_Garlington",
    party: "Independent",
  }, {
    first_name: "Stephanie",
    last_name: "Murphy",
    ballotpedia: "Stephanie_Murphy",
    party: "Democratic",
    wikipedia: "/wiki/Stephanie_Murphy",
  }, {
    first_name: "Leo",
    last_name: "Valentin",
    ballotpedia: "Leo_Valentin",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 8,
  candidates: [{
    first_name: "Jim",
    last_name: "Kennedy",
    ballotpedia: "Jim_Kennedy_(Florida)",
    party: "Democratic",
  }, {
    first_name: "Bill",
    last_name: "Posey",
    ballotpedia: "Bill_Posey",
    party: "Republican",
    wikipedia: "/wiki/Bill_Posey",
  }]
}, {
  state: "FL",
  number: 9,
  candidates: [{
    first_name: "Bill",
    last_name: "Olson",
    ballotpedia: "Bill_Olson",
    party: "Republican",
  }, {
    first_name: "Darren",
    last_name: "Soto",
    ballotpedia: "Darren_Soto",
    party: "Democratic",
    wikipedia: "/wiki/Darren_Soto",
  }]
}, {
  state: "FL",
  number: 10,
  candidates: [{
    first_name: "Val",
    last_name: "Demings",
    ballotpedia: "Val_Demings",
    party: "Democratic",
    wikipedia: "/wiki/Val_Demings",
  }, {
    first_name: "Vennia",
    last_name: "Francois",
    ballotpedia: "Vennia_Francois",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 11,
  candidates: [{
    first_name: "Dana",
    last_name: "Cottrell",
    ballotpedia: "Dana_Cottrell",
    party: "Democratic",
  }, {
    first_name: "Daniel",
    last_name: "Webster",
    ballotpedia: "Daniel_Webster",
    party: "Republican",
    wikipedia: "/wiki/Daniel_Webster_(Florida_politician)",
  }]
}, {
  state: "FL",
  number: 12,
  candidates: [{
    first_name: "Gus",
    last_name: "Bilirakis",
    ballotpedia: "Gus_Bilirakis",
    party: "Republican",
    wikipedia: "/wiki/Gus_Bilirakis",
  }, {
    first_name: "Kimberly",
    last_name: "Walker",
    ballotpedia: "Kimberly_Walker",
    party: "Democratic",
  }]
}, {
  state: "FL",
  number: 13,
  candidates: [{
    first_name: "Charlie",
    last_name: "Crist",
    ballotpedia: "Charlie_Crist",
    party: "Democratic",
    wikipedia: "/wiki/Charlie_Crist",
  }, {
    first_name: "Anna Paulina",
    last_name: "Luna",
    ballotpedia: "Anna_Paulina_Luna",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 14,
  candidates: [{
    first_name: "Kathy",
    last_name: "Castor",
    ballotpedia: "Kathy_Castor",
    party: "Democratic",
    wikipedia: "/wiki/Kathy_Castor",
  }, {
    first_name: "Christine",
    last_name: "Quinn",
    ballotpedia: "Christine_Quinn",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 15,
  candidates: [{
    first_name: "Alan",
    last_name: "Cohn",
    ballotpedia: "Alan_Cohn",
    party: "Democratic",
    wikipedia: "/wiki/Alan_Cohn",
  }, {
    first_name: "Scott",
    last_name: "Franklin",
    ballotpedia: "Scott_Franklin",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 16,
  candidates: [{
    first_name: "Vern",
    last_name: "Buchanan",
    ballotpedia: "Vern_Buchanan",
    party: "Republican",
    wikipedia: "/wiki/Vern_Buchanan",
  }, {
    first_name: "Margaret",
    last_name: "Good",
    ballotpedia: "Margaret_Good",
    party: "Democratic",
    wikipedia: "/wiki/Margaret_Good",
  }]
}, {
  state: "FL",
  number: 17,
  candidates: [{
    first_name: "Allen",
    last_name: "Ellison",
    ballotpedia: "Allen_Ellison",
    party: "Democratic",
  }, {
    first_name: "Theodore",
    last_name: "Murray",
    ballotpedia: "Theodore_Murray",
    party: "Independent",
  }, {
    first_name: "Greg",
    last_name: "Steube",
    ballotpedia: "Greg_Steube",
    party: "Republican",
    wikipedia: "/wiki/Greg_Steube",
  }]
}, {
  state: "FL",
  number: 18,
  candidates: [{
    first_name: "Pam",
    last_name: "Keith",
    ballotpedia: "Pam_Keith",
    party: "Democratic",
  }, {
    first_name: "Brian",
    last_name: "Mast",
    ballotpedia: "Brian_Mast",
    party: "Republican",
    wikipedia: "/wiki/Brian_Mast",
  }, {
    first_name: "K. W.",
    last_name: "Miller",
    ballotpedia: "K.W._Miller",
    party: "Independent",
  }]
}, {
  state: "FL",
  number: 19,
  candidates: [{
    first_name: "Cindy",
    last_name: "Banyai",
    ballotpedia: "Cindy_Banyai",
    party: "Democratic",
  }, {
    first_name: "Byron",
    last_name: "Donalds",
    ballotpedia: "Byron_Donalds",
    party: "Republican",
    wikipedia: "/wiki/Byron_Donalds",
  }]
}, {
  state: "FL",
  number: 20,
  candidates: [{
    first_name: "Alcee",
    last_name: "Hastings",
    ballotpedia: "Alcee_Hastings",
    party: "Democratic",
    wikipedia: "/wiki/Alcee_Hastings",
  }, {
    first_name: "Lateresa",
    last_name: "Jones",
    ballotpedia: "Lateresa_Jones",
    party: "Independent",
  }, {
    first_name: "Greg",
    last_name: "Musselwhite",
    ballotpedia: "Greg_Musselwhite",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 21,
  candidates: [{
    first_name: "Lois",
    last_name: "Frankel",
    ballotpedia: "Lois_Frankel",
    party: "Democratic",
    wikipedia: "/wiki/Lois_Frankel",
  }, {
    first_name: "Laura",
    last_name: "Loomer",
    ballotpedia: "Laura_Loomer",
    party: "Republican",
    wikipedia: "/wiki/Laura_Loomer",
  }, {
    first_name: "Charleston",
    last_name: "Malkemus",
    ballotpedia: "Charleston_Malkemus",
    party: "Independent",
  }]
}, {
  state: "FL",
  number: 22,
  candidates: [{
    first_name: "Ted",
    last_name: "Deutch",
    ballotpedia: "Ted_Deutch",
    party: "Democratic",
    wikipedia: "/wiki/Ted_Deutch",
  }, {
    first_name: "Jim",
    last_name: "Pruden",
    ballotpedia: "James_Pruden_(Florida)",
    party: "Republican",
  }]
}, {
  state: "FL",
  number: 23,
  candidates: [{
    first_name: "Carla",
    last_name: "Spalding",
    ballotpedia: "Carla_Spalding",
    party: "Republican",
  }, {
    first_name: "Debbie Wasserman",
    last_name: "Schultz",
    ballotpedia: "Debbie_Wasserman_Schultz",
    party: "Democratic",
    wikipedia: "/wiki/Debbie_Wasserman_Schultz",
  }]
}, {
  state: "FL",
  number: 24,
  candidates: [{
    first_name: "Christine",
    last_name: "Olivo",
    ballotpedia: "Christine_Alexandria_Olivo",
    party: "Independent",
  }, {
    first_name: "Lavern",
    last_name: "Spicer",
    ballotpedia: "Lavern_Spicer",
    party: "Republican",
  }, {
    first_name: "Frederica",
    last_name: "Wilson",
    ballotpedia: "Frederica_Wilson",
    party: "Democratic",
    wikipedia: "/wiki/Frederica_Wilson",
  }]
}, {
  state: "FL",
  number: 25,
  candidates: [{
    first_name: "Mario",
    last_name: "Díaz-Balart",
    ballotpedia: "https://ballotpedia.org/Mario_Diaz-Balart",
    party: "Republican",
    wikipedia: "/wiki/Mario_D%C3%ADaz-Balart",
  }]
}, {
  state: "FL",
  number: 26,
  candidates: [{
    first_name: "Carlos",
    last_name: "Gimenez",
    ballotpedia: "Carlos_Gimenez",
    party: "Republican",
    wikipedia: "/wiki/Carlos_A._Gim%C3%A9nez",
  }, {
    first_name: "Debbie",
    last_name: "Mucarsel-Powell",
    ballotpedia: "Debbie_Mucarsel-Powell",
    party: "Democratic",
    wikipedia: "/wiki/Debbie_Mucarsel-Powell",
  }]
}, {
  state: "FL",
  number: 27,
  candidates: [{
    first_name: "Maria Elvira",
    last_name: "Salazar",
    ballotpedia: "Maria_Elvira_Salazar",
    party: "Republican",
    wikipedia: "/wiki/Maria_Elvira_Salazar",
  }, {
    first_name: "Donna",
    last_name: "Shalala",
    ballotpedia: "Donna_Shalala",
    party: "Democratic",
    wikipedia: "/wiki/Donna_Shalala",
  }]
}, {
  state: "GA",
  number: 1,
  candidates: [{
    first_name: "Buddy",
    last_name: "Carter",
    ballotpedia: "Buddy_Carter",
    party: "Republican",
    wikipedia: "/wiki/Buddy_Carter",
  }, {
    first_name: "Joyce",
    last_name: "Griggs",
    ballotpedia: "Joyce_Griggs",
    party: "Democratic",
  }]
}, {
  state: "GA",
  number: 2,
  candidates: [{
    first_name: "Sanford",
    last_name: "Bishop",
    ballotpedia: "Sanford_Bishop",
    party: "Democratic",
    wikipedia: "/wiki/Sanford_Bishop",
  }, {
    first_name: "Don",
    last_name: "Cole",
    ballotpedia: "Donald_Cole",
    party: "Republican",
  }]
}, {
  state: "GA",
  number: 3,
  candidates: [{
    first_name: "Val",
    last_name: "Almonord",
    ballotpedia: "Val_Almonord",
    party: "Democratic",
  }, {
    first_name: "Drew",
    last_name: "Ferguson",
    ballotpedia: "Drew_Ferguson",
    party: "Republican",
    wikipedia: "/wiki/Drew_Ferguson_(politician)",
  }]
}, {
  state: "GA",
  number: 4,
  candidates: [{
    first_name: "Johsie",
    last_name: "Cruz",
    ballotpedia: "Johsie_Cruz",
    party: "Republican",
  }, {
    first_name: "Hank",
    last_name: "Johnson",
    ballotpedia: "Hank_Johnson",
    party: "Democratic",
    wikipedia: "/wiki/Hank_Johnson",
  }]
}, {
  state: "GA",
  number: 5,
  candidates: [{
    first_name: "Angela",
    last_name: "Stanton-King",
    ballotpedia: "Angela_Stanton-King",
    party: "Republican",
    wikipedia: "/wiki/Angela_Stanton-King",
  }, {
    first_name: "Nikema",
    last_name: "Williams",
    ballotpedia: "Nikema_Williams",
    party: "Democratic",
    wikipedia: "/wiki/Nikema_Williams",
  }]
}, {
  state: "GA",
  number: 6,
  candidates: [{
    first_name: "Karen",
    last_name: "Handel",
    ballotpedia: "Karen_Handel",
    party: "Republican",
    wikipedia: "/wiki/Karen_Handel",
  }, {
    first_name: "Lucy",
    last_name: "McBath",
    ballotpedia: "Lucy_McBath",
    party: "Democratic",
    wikipedia: "/wiki/Lucy_McBath",
  }]
}, {
  state: "GA",
  number: 7,
  candidates: [{
    first_name: "Carolyn",
    last_name: "Bourdeaux",
    ballotpedia: "Carolyn_Bourdeaux",
    party: "Democratic",
    wikipedia: "/wiki/Carolyn_Bourdeaux",
  }, {
    first_name: "Rich",
    last_name: "McCormick",
    ballotpedia: "Rich_McCormick",
    party: "Republican",
  }]
}, {
  state: "GA",
  number: 8,
  candidates: [{
    first_name: "Jimmy",
    last_name: "Cooper",
    ballotpedia: "James_Cooper_III_(Georgia)",
    party: "Green",
  }, {
    first_name: "Lindsay",
    last_name: "Holliday",
    ballotpedia: "Lindsay_Holliday",
    party: "Democratic",
  }, {
    first_name: "Austin",
    last_name: "Scott",
    ballotpedia: "Austin_Scott",
    party: "Republican",
    wikipedia: "/wiki/Austin_Scott_(politician)",
  }]
}, {
  state: "GA",
  number: 9,
  candidates: [{
    first_name: "Andrew",
    last_name: "Clyde",
    ballotpedia: "Andrew_Clyde",
    party: "Republican",
  }, {
    first_name: "Devin",
    last_name: "Pandy",
    ballotpedia: "Devin_Pandy",
    party: "Democratic",
  }]
}, {
  state: "GA",
  number: 10,
  candidates: [{
    first_name: "Jody",
    last_name: "Hice",
    ballotpedia: "Jody_Hice",
    party: "Republican",
    wikipedia: "/wiki/Jody_Hice",
  }, {
    first_name: "Tabitha",
    last_name: "Johnson-Green",
    ballotpedia: "Tabitha_Johnson-Green",
    party: "Democratic",
  }]
}, {
  state: "GA",
  number: 11,
  candidates: [{
    first_name: "Dana",
    last_name: "Barrett",
    ballotpedia: "Dana_Barrett",
    party: "Democratic",
  }, {
    first_name: "Barry",
    last_name: "Loudermilk",
    ballotpedia: "Barry_Loudermilk",
    party: "Republican",
    wikipedia: "/wiki/Barry_Loudermilk",
  }]
}, {
  state: "GA",
  number: 12,
  candidates: [{
    first_name: "Rick",
    last_name: "Allen",
    ballotpedia: "Rick_Allen",
    party: "Republican",
    wikipedia: "/wiki/Rick_W._Allen",
  }, {
    first_name: "Liz",
    last_name: "Johnson",
    ballotpedia: "Liz_Johnson_(Georgia)",
    party: "Democratic",
  }, {
    first_name: "Donald",
    last_name: "Keller",
    ballotpedia: "Donald_Keller_(Georgia)",
    party: "Independent",
  }]
}, {
  state: "GA",
  number: 13,
  candidates: [{
    first_name: "Martin",
    last_name: "Cowen",
    ballotpedia: "Martin_Cowen",
    party: "Libertarian",
  }, {
    first_name: "Becky",
    last_name: "Hites",
    ballotpedia: "Becky_Hites",
    party: "Republican",
  }, {
    first_name: "David",
    last_name: "Scott",
    ballotpedia: "David_Scott_(Georgia)",
    party: "Democratic",
    wikipedia: "/wiki/David_Scott_(Georgia_politician)",
  }]
}, {
  state: "GA",
  number: 14,
  candidates: [{
    first_name: "Marjorie Taylor",
    last_name: "Greene",
    ballotpedia: "Marjorie_Taylor_Greene",
    party: "Republican",
    wikipedia: "/wiki/Marjorie_Taylor_Greene",
  }]
}, {
  state: "HI",
  number: 1,
  candidates: [{
    first_name: "Ed",
    last_name: "Case",
    ballotpedia: "Ed_Case",
    party: "Democratic",
    wikipedia: "/wiki/Ed_Case",
  }, {
    first_name: "Ron",
    last_name: "Curtis",
    ballotpedia: "Ron_Curtis",
    party: "Republican",
  }, {
    first_name: "Calvin",
    last_name: "Griffin",
    ballotpedia: "Calvin_Griffin",
    party: "Independent",
  }]
}, {
  state: "HI",
  number: 2,
  candidates: [{
    first_name: "Joe",
    last_name: "Akana",
    ballotpedia: "Joseph_Akana",
    party: "Republican",
  }, {
    first_name: "Ron",
    last_name: "Burrus",
    ballotpedia: "Ron_Burrus",
    party: "Independent",
  }, {
    first_name: "John",
    last_name: "Giuffre",
    ballotpedia: "John_Giuffre",
    party: "American Shopping",
  }, {
    first_name: "Jonathan",
    last_name: "Hoomanawanui",
    ballotpedia: "Jonathan_K._Hoomanawanui",
    party: "Aloha Aina",
  }, {
    first_name: "Kai",
    last_name: "Kahele",
    ballotpedia: "Kai_Kahele",
    party: "Democratic",
    wikipedia: "/wiki/Kai_Kahele",
  }, {
    first_name: "Byron",
    last_name: "McCorriston",
    ballotpedia: "Byron_McCorriston",
    party: "Independent",
  }, {
    first_name: "Michelle",
    last_name: "Tippens",
    ballotpedia: "Michelle_Rose_Tippens",
    party: "Libertarian",
  }]
}, {
  state: "ID",
  number: 1,
  candidates: [{
    first_name: "Joe",
    last_name: "Evans",
    ballotpedia: "Joe_Evans_(Idaho_congressional_candidate)",
    party: "Libertarian",
  }, {
    first_name: "Russ",
    last_name: "Fulcher",
    ballotpedia: "Russ_Fulcher",
    party: "Republican",
    wikipedia: "/wiki/Russ_Fulcher",
  }, {
    first_name: "Rudy",
    last_name: "Soto",
    ballotpedia: "Rudy_Soto",
    party: "Democratic",
  }]
}, {
  state: "ID",
  number: 2,
  candidates: [{
    first_name: "Idaho",
    last_name: "Law",
    ballotpedia: "Idaho_Law",
    party: "Libertarian",
  }, {
    last_name: "Pro-Life",
    ballotpedia: "Pro-Life",
    party: "Constitution",
    wikipedia: "/wiki/Pro-Life_(politician)",
  }, {
    first_name: "Mike",
    last_name: "Simpson",
    ballotpedia: "Mike_Simpson_(Idaho)",
    party: "Republican",
    wikipedia: "/wiki/Mike_Simpson",
  }, {
    first_name: "Aaron",
    last_name: "Swisher",
    ballotpedia: "Aaron_Swisher",
    party: "Democratic",
  }]
}, {
  state: "IL",
  number: 1,
  candidates: [{
    first_name: "Bobby",
    last_name: "Rush",
    ballotpedia: "Bobby_Rush",
    party: "Democratic",
    wikipedia: "/wiki/Bobby_Rush",
  }, {
    first_name: "Philanise",
    last_name: "White",
    ballotpedia: "Philanise_White",
    party: "Republican",
  }]
}, {
  state: "IL",
  number: 2,
  candidates: [{
    first_name: "Robin",
    last_name: "Kelly",
    ballotpedia: "Robin_Kelly",
    party: "Democratic",
    wikipedia: "/wiki/Robin_Kelly",
  }, {
    first_name: "Theresa",
    last_name: "Raborn",
    ballotpedia: "Theresa_Raborn",
    party: "Republican",
  }]
}, {
  state: "IL",
  number: 3,
  candidates: [{
    first_name: "Mike",
    last_name: "Fricilone",
    ballotpedia: "Mike_Fricilone",
    party: "Republican",
  }, {
    first_name: "Marie",
    last_name: "Newman",
    ballotpedia: "Marie_Newman",
    party: "Democratic",
    wikipedia: "/wiki/Marie_Newman",
  }]
}, {
  state: "IL",
  number: 4,
  candidates: [{
    first_name: "Jesus G. \"Chuy\"",
    last_name: "García",
    ballotpedia: "Jesus_Garcia",
    party: "Democratic",
    wikipedia: "/wiki/Jes%C3%BAs_%22Chuy%22_Garc%C3%ADa",
  }, {
    first_name: "Jesus",
    last_name: "Solorio",
    ballotpedia: "Jesus_Solorio",
    party: "Republican",
  }]
}, {
  state: "IL",
  number: 5,
  candidates: [{
    first_name: "Tommy",
    last_name: "Hanson",
    ballotpedia: "Tommy_Hanson",
    party: "Republican",
  }, {
    first_name: "Mike",
    last_name: "Quigley",
    ballotpedia: "Mike_Quigley",
    party: "Democratic",
    wikipedia: "/wiki/Mike_Quigley_(politician)",
  }, {
    first_name: "Thomas",
    last_name: "Wilda",
    ballotpedia: "Thomas_Wilda",
    party: "Green",
  }]
}, {
  state: "IL",
  number: 6,
  candidates: [{
    first_name: "Sean",
    last_name: "Casten",
    ballotpedia: "Sean_Casten",
    party: "Democratic",
    wikipedia: "/wiki/Sean_Casten",
  }, {
    first_name: "Jeanne",
    last_name: "Ives",
    ballotpedia: "Jeanne_Ives",
    party: "Republican",
    wikipedia: "/wiki/Jeanne_Ives",
  }, {
    first_name: "Bill",
    last_name: "Redpath",
    ballotpedia: "Bill_Redpath",
    party: "Libertarian",
  }]
}, {
  state: "IL",
  number: 7,
  candidates: [{
    first_name: "Craig",
    last_name: "Cameron",
    ballotpedia: "Craig_Cameron",
    party: "Republican",
  }, {
    first_name: "Danny",
    last_name: "Davis",
    ballotpedia: "Danny_K._Davis",
    party: "Democratic",
    wikipedia: "/wiki/Danny_K._Davis",
  }, {
    first_name: "Tracy",
    last_name: "Jennings",
    ballotpedia: "Tracy_Jennings",
    party: "Independent",
  }]
}, {
  state: "IL",
  number: 8,
  candidates: [{
    first_name: "Raja",
    last_name: "Krishnamoorthi",
    ballotpedia: "Raja_Krishnamoorthi",
    party: "Democratic",
    wikipedia: "/wiki/Raja_Krishnamoorthi",
  }, {
    first_name: "Preston",
    last_name: "Nelson",
    ballotpedia: "Preston_Nelson",
    party: "Libertarian",
  }]
}, {
  state: "IL",
  number: 9,
  candidates: [{
    first_name: "Sargis",
    last_name: "Sangari",
    ballotpedia: "Sargis_Sangari",
    party: "Republican",
  }, {
    first_name: "Jan",
    last_name: "Schakowsky",
    ballotpedia: "Jan_Schakowsky",
    party: "Democratic",
    wikipedia: "/wiki/Jan_Schakowsky",
  }]
}, {
  state: "IL",
  number: 10,
  candidates: [{
    first_name: "Valerie",
    last_name: "Ramirez Mukherjee",
    ballotpedia: "Valerie_Ramirez_Mukherjee",
    party: "Republican",
  }, {
    first_name: "Brad",
    last_name: "Schneider",
    ballotpedia: "Brad_Schneider",
    party: "Democratic",
    wikipedia: "/wiki/Brad_Schneider",
  }]
}, {
  state: "IL",
  number: 11,
  candidates: [{
    first_name: "Bill",
    last_name: "Foster",
    ballotpedia: "Bill_Foster_(Illinois)",
    party: "Democratic",
    wikipedia: "/wiki/Bill_Foster_(politician)",
  }, {
    first_name: "Rick",
    last_name: "Laib",
    ballotpedia: "Rick_Laib",
    party: "Republican",
  }]
}, {
  state: "IL",
  number: 12,
  candidates: [{
    first_name: "Mike",
    last_name: "Bost",
    ballotpedia: "Mike_Bost",
    party: "Republican",
    wikipedia: "/wiki/Mike_Bost",
  }, {
    first_name: "Ray",
    last_name: "Lenzi",
    ballotpedia: "Ray_Lenzi",
    party: "Democratic",
  }]
}, {
  state: "IL",
  number: 13,
  candidates: [{
    first_name: "Rodney",
    last_name: "Davis",
    ballotpedia: "Rodney_Davis_(Illinois)",
    party: "Republican",
    wikipedia: "/wiki/Rodney_Davis_(politician)",
  }, {
    first_name: "Betsy",
    last_name: "Dirksen Londrigan",
    ballotpedia: "Betsy_Londrigan",
    party: "Democratic",
  }]
}, {
  state: "IL",
  number: 14,
  candidates: [{
    first_name: "Jim",
    last_name: "Oberweis",
    ballotpedia: "Jim_Oberweis",
    party: "Republican",
    wikipedia: "/wiki/Jim_Oberweis",
  }, {
    first_name: "Lauren",
    last_name: "Underwood",
    ballotpedia: "Lauren_Underwood",
    party: "Democratic",
    wikipedia: "/wiki/Lauren_Underwood",
  }]
}, {
  state: "IL",
  number: 15,
  candidates: [{
    first_name: "Mary",
    last_name: "Miller",
    ballotpedia: "Mary_Miller_(Illinois)",
    party: "Republican",
  }, {
    first_name: "Erika",
    last_name: "Weaver",
    ballotpedia: "Erika_Weaver",
    party: "Democratic",
  }]
}, {
  state: "IL",
  number: 16,
  candidates: [{
    first_name: "Dani",
    last_name: "Brzozowski",
    ballotpedia: "Dani_Brzozowski",
    party: "Democratic",
  }, {
    first_name: "Adam",
    last_name: "Kinzinger",
    ballotpedia: "Adam_Kinzinger",
    party: "Republican",
    wikipedia: "/wiki/Adam_Kinzinger",
  }]
}, {
  state: "IL",
  number: 17,
  candidates: [{
    first_name: "Cheri",
    last_name: "Bustos",
    ballotpedia: "Cheri_Bustos",
    party: "Democratic",
    wikipedia: "/wiki/Cheri_Bustos",
  }, {
    first_name: "Esther Joy",
    last_name: "King",
    ballotpedia: "Esther_Joy_King",
    party: "Republican",
  }]
}, {
  state: "IL",
  number: 18,
  candidates: [{
    first_name: "Darin",
    last_name: "LaHood",
    ballotpedia: "Darin_LaHood",
    party: "Republican",
    wikipedia: "/wiki/Darin_LaHood",
  }, {
    first_name: "George",
    last_name: "Petrilli",
    ballotpedia: "George_Petrilli",
    party: "Democratic",
  }]
}, {
  state: "IN",
  number: 1,
  candidates: [{
    first_name: "Mark",
    last_name: "Leyva",
    ballotpedia: "Mark_Leyva",
    party: "Republican",
  }, {
    first_name: "Frank",
    last_name: "Mrvan",
    ballotpedia: "Frank_Mrvan_(Indiana_congressional_candidate)",
    party: "Democratic",
    wikipedia: "/wiki/Frank_J._Mrvan",
  }, {
    first_name: "Michael",
    last_name: "Strauss",
    ballotpedia: "Michael_Strauss",
    party: "Libertarian",
  }]
}, {
  state: "IN",
  number: 2,
  candidates: [{
    first_name: "Pat",
    last_name: "Hackett",
    ballotpedia: "Pat_Hackett",
    party: "Democratic",
  }, {
    first_name: "Jackie",
    last_name: "Walorski",
    ballotpedia: "Jackie_Walorski",
    party: "Republican",
    wikipedia: "/wiki/Jackie_Walorski",
  }]
}, {
  state: "IN",
  number: 3,
  candidates: [{
    first_name: "Jim",
    last_name: "Banks",
    ballotpedia: "Jim_Banks_(Indiana)",
    party: "Republican",
    wikipedia: "/wiki/Jim_Banks",
  }, {
    first_name: "Chip",
    last_name: "Coldiron",
    ballotpedia: "Chip_Coldiron",
    party: "Democratic",
  }]
}, {
  state: "IN",
  number: 4,
  candidates: [{
    first_name: "Jim",
    last_name: "Baird",
    ballotpedia: "Jim_Baird",
    party: "Republican",
    wikipedia: "/wiki/Jim_Baird_(American_politician)",
  }, {
    first_name: "Joe",
    last_name: "Mackey",
    ballotpedia: "Joe_Mackey",
    party: "Democratic",
  }]
}, {
  state: "IN",
  number: 5,
  candidates: [{
    first_name: "Christina",
    last_name: "Hale",
    ballotpedia: "Christina_Hale",
    party: "Democratic",
    wikipedia: "/wiki/Christina_Hale",
  }, {
    first_name: "Victoria",
    last_name: "Spartz",
    ballotpedia: "Victoria_Spartz",
    party: "Republican",
    wikipedia: "/wiki/Victoria_Spartz",
  }, {
    first_name: "Ken",
    last_name: "Tucker",
    ballotpedia: "Ken_Tucker_(Indiana)",
    party: "Libertarian",
  }]
}, {
  state: "IN",
  number: 6,
  candidates: [{
    first_name: "Tom",
    last_name: "Ferkinhoff",
    ballotpedia: "Tom_Ferkinhoff",
    party: "Libertarian",
  }, {
    first_name: "Jeannine Lee",
    last_name: "Lake",
    ballotpedia: "Jeannine_Lee_Lake",
    party: "Democratic",
  }, {
    first_name: "Greg",
    last_name: "Pence",
    ballotpedia: "Greg_Pence",
    party: "Republican",
    wikipedia: "/wiki/Greg_Pence",
  }]
}, {
  state: "IN",
  number: 7,
  candidates: [{
    first_name: "André",
    last_name: "Carson",
    ballotpedia: "André_Carson",
    party: "Democratic",
    wikipedia: "/wiki/Andr%C3%A9_Carson",
  }, {
    first_name: "Susan Marie",
    last_name: "Smith",
    ballotpedia: "Susan_Marie_Smith",
    party: "Republican",
  }]
}, {
  state: "IN",
  number: 8,
  candidates: [{
    first_name: "Larry",
    last_name: "Bucshon",
    ballotpedia: "Larry_Bucshon",
    party: "Republican",
    wikipedia: "/wiki/Larry_Bucshon",
  }, {
    first_name: "E. Thomasina",
    last_name: "Marsili",
    ballotpedia: "Thomasina_Marsili",
    party: "Democratic",
  }, {
    first_name: "James",
    last_name: "Rodenberger",
    ballotpedia: "James_Rodenberger",
    party: "Libertarian",
  }]
}, {
  state: "IN",
  number: 9,
  candidates: [{
    first_name: "Trey",
    last_name: "Hollingsworth",
    ballotpedia: "Trey_Hollingsworth",
    party: "Republican",
    wikipedia: "/wiki/Trey_Hollingsworth",
  }, {
    first_name: "Tonya",
    last_name: "Millis",
    ballotpedia: "Tonya_Millis",
    party: "Libertarian",
  }, {
    first_name: "Andy",
    last_name: "Ruff",
    ballotpedia: "Andy_Ruff",
    party: "Democratic",
  }]
}, {
  state: "IA",
  number: 1,
  candidates: [{
    first_name: "Abby",
    last_name: "Finkenauer",
    ballotpedia: "Abby_Finkenauer",
    party: "Democratic",
    wikipedia: "/wiki/Abby_Finkenauer",
  }, {
    first_name: "Ashley",
    last_name: "Hinson",
    ballotpedia: "Ashley_Hinson",
    party: "Republican",
    wikipedia: "/wiki/Ashley_Hinson",
  }]
}, {
  state: "IA",
  number: 2,
  candidates: [{
    first_name: "Rita",
    last_name: "Hart",
    ballotpedia: "Rita_Hart",
    party: "Democratic",
    wikipedia: "/wiki/Rita_Hart",
  }, {
    first_name: "Mariannette",
    last_name: "Miller-Meeks",
    ballotpedia: "Mariannette_Miller-Meeks",
    party: "Republican",
    wikipedia: "/wiki/Mariannette_Miller-Meeks",
  }]
}, {
  state: "IA",
  number: 3,
  candidates: [{
    first_name: "Cindy",
    last_name: "Axne",
    ballotpedia: "Cindy_Axne",
    party: "Democratic",
    wikipedia: "/wiki/Cindy_Axne",
  }, {
    first_name: "Bryan",
    last_name: "Holder",
    ballotpedia: "Bryan_Holder",
    party: "Libertarian",
  }, {
    first_name: "David",
    last_name: "Young",
    ballotpedia: "David_Young_(Iowa)",
    party: "Republican",
    wikipedia: "/wiki/David_Young_(Iowa_politician)",
  }]
}, {
  state: "IA",
  number: 4,
  candidates: [{
    first_name: "Randy",
    last_name: "Feenstra",
    ballotpedia: "Randy_Feenstra",
    party: "Republican",
    wikipedia: "/wiki/Randy_Feenstra",
  }, {
    first_name: "J. D.",
    last_name: "Scholten",
    ballotpedia: "J.D._Scholten",
    party: "Democratic",
    wikipedia: "/wiki/J._D._Scholten",
  }]
}, {
  state: "KS",
  number: 1,
  candidates: [{
    first_name: "Kali",
    last_name: "Barnett",
    ballotpedia: "Kali_Barnett",
    party: "Democratic",
  }, {
    first_name: "Tracey",
    last_name: "Mann",
    ballotpedia: "Tracey_Mann",
    party: "Republican",
    wikipedia: "/wiki/Tracey_Mann",
  }]
}, {
  state: "KS",
  number: 2,
  candidates: [{
    first_name: "Michelle",
    last_name: "De La Isla",
    ballotpedia: "Michelle_De_La_Isla",
    party: "Democratic",
    wikipedia: "/wiki/Michelle_De_La_Isla",
  }, {
    first_name: "Robert",
    last_name: "Garrard",
    ballotpedia: "Robert_Garrard",
    party: "Libertarian",
  }, {
    first_name: "Jake",
    last_name: "LaTurner",
    ballotpedia: "Jacob_LaTurner",
    party: "Republican",
    wikipedia: "/wiki/Jake_LaTurner",
  }]
}, {
  state: "KS",
  number: 3,
  candidates: [{
    first_name: "Amanda",
    last_name: "Adkins",
    ballotpedia: "Amanda_Adkins",
    party: "Republican",
    wikipedia: "/wiki/Amanda_Adkins_(politician)",
  }, {
    first_name: "Sharice",
    last_name: "Davids",
    ballotpedia: "Sharice_Davids",
    party: "Democratic",
    wikipedia: "/wiki/Sharice_Davids",
  }, {
    first_name: "Steve",
    last_name: "Hohe",
    ballotpedia: "Steve_Hohe",
    party: "Libertarian",
  }]
}, {
  state: "KS",
  number: 4,
  candidates: [{
    first_name: "Ron",
    last_name: "Estes",
    ballotpedia: "Ron_Estes_(U.S._House_representative)",
    party: "Republican",
    wikipedia: "/wiki/Ron_Estes",
  }, {
    first_name: "Laura",
    last_name: "Lombard",
    ballotpedia: "Laura_Lombard",
    party: "Democratic",
  }]
}, {
  state: "KY",
  number: 1,
  candidates: [{
    first_name: "James",
    last_name: "Comer",
    ballotpedia: "James_Comer",
    party: "Republican",
    wikipedia: "/wiki/James_Comer_(politician)",
  }, {
    first_name: "James",
    last_name: "Rhodes",
    ballotpedia: "James_Rhodes_(Kentucky)",
    party: "Democratic",
  }]
}, {
  state: "KY",
  number: 2,
  candidates: [{
    first_name: "Lewis",
    last_name: "Carter",
    ballotpedia: "Lewis_Carter",
    party: "Populist",
  }, {
    first_name: "Brett",
    last_name: "Guthrie",
    ballotpedia: "Brett_Guthrie",
    party: "Republican",
    wikipedia: "/wiki/Brett_Guthrie",
  }, {
    first_name: "Hank",
    last_name: "Linderman",
    ballotpedia: "Hank_Linderman",
    party: "Democratic",
  }, {
    first_name: "Robert Lee",
    last_name: "Perry",
    ballotpedia: "Robert_Lee_Perry_(Kentucky)",
    party: "Libertarian",
  }]
}, {
  state: "KY",
  number: 3,
  candidates: [{
    first_name: "Rhonda",
    last_name: "Palazzo",
    ballotpedia: "Rhonda_Palazzo",
    party: "Republican",
  }, {
    first_name: "John",
    last_name: "Yarmuth",
    ballotpedia: "John_Yarmuth",
    party: "Democratic",
    wikipedia: "/wiki/John_Yarmuth",
  }]
}, {
  state: "KY",
  number: 4,
  candidates: [{
    first_name: "Thomas",
    last_name: "Massie",
    ballotpedia: "Thomas_Massie",
    party: "Republican",
    wikipedia: "/wiki/Thomas_Massie",
  }, {
    first_name: "Alexandra",
    last_name: "Owensby",
    ballotpedia: "Alexandra_Owensby",
    party: "Democratic",
  }]
}, {
  state: "KY",
  number: 5,
  candidates: [{
    first_name: "Matthew Ryan",
    last_name: "Best",
    ballotpedia: "Matthew_Ryan_Best",
    party: "Democratic",
  }, {
    first_name: "Hal",
    last_name: "Rogers",
    ballotpedia: "Hal_Rogers",
    party: "Republican",
    wikipedia: "/wiki/Hal_Rogers",
  }]
}, {
  state: "KY",
  number: 6,
  candidates: [{
    first_name: "Andy",
    last_name: "Barr",
    ballotpedia: "Andy_Barr",
    party: "Republican",
    wikipedia: "/wiki/Andy_Barr_(American_politician)",
  }, {
    first_name: "Frank",
    last_name: "Harris",
    ballotpedia: "Frank_Harris",
    party: "Libertarian",
  }, {
    first_name: "Josh",
    last_name: "Hicks",
    ballotpedia: "Josh_Hicks_(Kentucky)",
    party: "Democratic",
  }]
}, {
  state: "LA",
  number: 1,
  candidates: [{
    first_name: "Lee Ann",
    last_name: "Dugas",
    ballotpedia: "Lee_Ann_Dugas",
    party: "Democratic",
  }, {
    first_name: "Howard",
    last_name: "Kearney",
    ballotpedia: "Howard_Kearney",
    party: "Libertarian",
  }, {
    first_name: "Steve",
    last_name: "Scalise",
    ballotpedia: "Steve_Scalise",
    party: "Republican",
    wikipedia: "/wiki/Steve_Scalise",
  }]
}, {
  state: "LA",
  number: 2,
  candidates: [{
    first_name: "Belden",
    last_name: "Batiste",
    ballotpedia: "Belden_Batiste",
    party: "Independent",
  }, {
    first_name: "Glenn",
    last_name: "Harris",
    ballotpedia: "Glenn_Harris",
    party: "Democratic",
  }, {
    first_name: "Colby",
    last_name: "James",
    ballotpedia: "Colby_James",
    party: "Independent",
  }, {
    first_name: "Cedric",
    last_name: "Richmond",
    ballotpedia: "Cedric_Richmond",
    party: "Democratic",
    wikipedia: "/wiki/Cedric_Richmond",
  }, {
    first_name: "David",
    last_name: "Schilling",
    ballotpedia: "David_Schilling",
    party: "Republican",
  }, {
    first_name: "Sheldon",
    last_name: "Vincent",
    suffix: "Sr.",
    ballotpedia: "Sheldon_Vincent_Sr.",
    party: "Republican",
  }]
}, {
  state: "LA",
  number: 3,
  candidates: [{
    first_name: "Rob",
    last_name: "Anderson",
    ballotpedia: "Rob_Anderson",
    party: "Democratic",
  }, {
    first_name: "Braylon",
    last_name: "Harris",
    ballotpedia: "Braylon_Harris",
    party: "Democratic",
  }, {
    first_name: "Clay",
    last_name: "Higgins",
    ballotpedia: "Clay_Higgins",
    party: "Republican",
    wikipedia: "/wiki/Clay_Higgins",
  }, {
    first_name: "Brandon",
    last_name: "Leleux",
    ballotpedia: "Brandon_Leleux",
    party: "Libertarian",
  }]
}, {
  state: "LA",
  number: 4,
  candidates: [{
    first_name: "Ben",
    last_name: "Gibson",
    ballotpedia: "Ben_Gibson",
    party: "Republican",
  }, {
    first_name: "Kenny",
    last_name: "Houston",
    ballotpedia: "Kenny_Houston",
    party: "Democratic",
  }, {
    first_name: "Mike",
    last_name: "Johnson",
    ballotpedia: "Mike_Johnson_(Louisiana)",
    party: "Republican",
    wikipedia: "/wiki/Mike_Johnson_(Louisiana_politician)",
  }, {
    first_name: "Ryan",
    last_name: "Trundle",
    ballotpedia: "Ryan_Trundle",
    party: "Democratic",
  }]
}, {
  state: "LA",
  number: 5,
  candidates: [{
    first_name: "Candy",
    last_name: "Christophe",
    ballotpedia: "Candy_Christophe",
    party: "Democratic",
  }, {
    first_name: "Allen",
    last_name: "Guillory",
    suffix: "Sr.",
    ballotpedia: "Allen_Guillory_Sr.",
    party: "Republican",
  }, {
    first_name: "Lance",
    last_name: "Harris",
    ballotpedia: "Lance_Harris",
    party: "Republican",
    wikipedia: "/wiki/Lance_Harris",
  }, {
    first_name: "Matt",
    last_name: "Hasty",
    ballotpedia: "Matt_Hasty",
    party: "Republican",
  }, {
    first_name: "Jesse",
    last_name: "Lagarde",
    ballotpedia: "Jesse_Lagarde",
    party: "Democratic",
  }, {
    first_name: "Martin",
    last_name: "Lemelle",
    ballotpedia: "Martin_Lemelle",
    party: "Democratic",
  }, {
    first_name: "Luke",
    last_name: "Letlow",
    ballotpedia: "Luke_Letlow",
    party: "Republican",
  }, {
    first_name: "Scotty",
    last_name: "Robinson",
    ballotpedia: "Scotty_Robinson",
    party: "Republican",
  }, {
    first_name: "Phillip",
    last_name: "Snowden",
    ballotpedia: "Phillip_Snowden",
    party: "Democratic",
  }]
}, {
  state: "LA",
  number: 6,
  candidates: [{
    first_name: "Garret",
    last_name: "Graves",
    ballotpedia: "Garret_Graves",
    party: "Republican",
    wikipedia: "/wiki/Garret_Graves",
  }, {
    first_name: "Shannon",
    last_name: "Sloan",
    ballotpedia: "Shannon_Sloan",
    party: "Libertarian",
  }, {
    first_name: "Richard",
    last_name: "Torregano",
    ballotpedia: "Richard_Torregano",
    party: "Independent",
  }, {
    first_name: "Dartanyon",
    last_name: "Williams",
    ballotpedia: "Dartanyon_Williams",
    party: "Democratic",
  }]
}, {
  state: "ME",
  number: 1,
  candidates: [{
    first_name: "Jay",
    last_name: "Allen",
    ballotpedia: "Jay_Allen",
    party: "Republican",
  }, {
    first_name: "Chellie",
    last_name: "Pingree",
    ballotpedia: "Chellie_Pingree",
    party: "Democratic",
    wikipedia: "/wiki/Chellie_Pingree",
  }]
}, {
  state: "ME",
  number: 2,
  candidates: [{
    first_name: "Dale",
    last_name: "Crafts",
    ballotpedia: "Dale_Crafts",
    party: "Republican",
    wikipedia: "/wiki/Dale_J._Crafts",
  }, {
    first_name: "Jared",
    last_name: "Golden",
    ballotpedia: "Jared_Golden",
    party: "Democratic",
    wikipedia: "/wiki/Jared_Golden",
  }]
}, {
  state: "MD",
  number: 1,
  candidates: [{
    first_name: "Andy",
    last_name: "Harris",
    ballotpedia: "Andy_Harris",
    party: "Republican",
    wikipedia: "/wiki/Andrew_P._Harris",
  }, {
    first_name: "Mia",
    last_name: "Mason",
    ballotpedia: "Mia_Mason",
    party: "Democratic",
  }]
}, {
  state: "MD",
  number: 2,
  candidates: [{
    first_name: "Dutch",
    last_name: "Ruppersberger",
    ballotpedia: "Dutch_Ruppersberger",
    party: "Democratic",
    wikipedia: "/wiki/Dutch_Ruppersberger",
  }, {
    first_name: "Johnny Ray",
    last_name: "Salling",
    ballotpedia: "Johnny_Ray_Salling",
    party: "Republican",
    wikipedia: "/wiki/Johnny_Ray_Salling",
  }]
}, {
  state: "MD",
  number: 3,
  candidates: [{
    first_name: "Charles",
    last_name: "Anthony",
    ballotpedia: "Charles_Anthony",
    party: "Republican",
  }, {
    first_name: "John",
    last_name: "Sarbanes",
    ballotpedia: "John_Sarbanes",
    party: "Democratic",
    wikipedia: "/wiki/John_Sarbanes",
  }]
}, {
  state: "MD",
  number: 4,
  candidates: [{
    first_name: "Anthony",
    last_name: "Brown",
    ballotpedia: "Anthony_Brown_(Maryland)",
    party: "Democratic",
    wikipedia: "/wiki/Anthony_G._Brown",
  }, {
    first_name: "George",
    last_name: "McDermott",
    ballotpedia: "George_McDermott",
    party: "Republican",
  }]
}, {
  state: "MD",
  number: 5,
  candidates: [{
    first_name: "Steny",
    last_name: "Hoyer",
    ballotpedia: "Steny_Hoyer",
    party: "Democratic",
    wikipedia: "/wiki/Steny_Hoyer",
  }, {
    first_name: "Chris",
    last_name: "Palombi",
    ballotpedia: "Chris_Palombi",
    party: "Republican",
  }]
}, {
  state: "MD",
  number: 6,
  candidates: [{
    first_name: "George",
    last_name: "Gluck",
    ballotpedia: "George_Gluck",
    party: "Green",
  }, {
    first_name: "Neil",
    last_name: "Parrott",
    ballotpedia: "Neil_Parrott",
    party: "Republican",
    wikipedia: "/wiki/Neil_Parrott",
  }, {
    first_name: "David",
    last_name: "Trone",
    ballotpedia: "David_Trone",
    party: "Democratic",
    wikipedia: "/wiki/David_Trone",
  }]
}, {
  state: "MD",
  number: 7,
  candidates: [{
    first_name: "Kimberly",
    last_name: "Klacik",
    ballotpedia: "Kimberly_Klacik",
    party: "Republican",
    wikipedia: "/wiki/Kimberly_Klacik",
  }, {
    first_name: "Kweisi",
    last_name: "Mfume",
    ballotpedia: "Kweisi_Mfume",
    party: "Democratic",
    wikipedia: "/wiki/Kweisi_Mfume",
  }]
}, {
  state: "MD",
  number: 8,
  candidates: [{
    first_name: "Gregory",
    last_name: "Coll",
    ballotpedia: "Gregory_Coll",
    party: "Republican",
  }, {
    first_name: "Jamie",
    last_name: "Raskin",
    ballotpedia: "Jamie_Raskin",
    party: "Democratic",
    wikipedia: "/wiki/Jamie_Raskin",
  }]
}, {
  state: "MA",
  number: 1,
  candidates: [{
    first_name: "Richard",
    last_name: "Neal",
    ballotpedia: "Richard_Neal",
    party: "Democratic",
    wikipedia: "/wiki/Richard_Neal",
  }]
}, {
  state: "MA",
  number: 2,
  candidates: [{
    first_name: "Tracy",
    last_name: "Lovvorn",
    ballotpedia: "Tracy_Lovvorn",
    party: "Republican",
  }, {
    first_name: "Jim",
    last_name: "McGovern",
    ballotpedia: "Jim_McGovern_(Massachusetts)",
    party: "Democratic",
    wikipedia: "/wiki/Jim_McGovern_(American_politician)",
  }]
}, {
  state: "MA",
  number: 3,
  candidates: [{
    first_name: "Lori",
    last_name: "Trahan",
    ballotpedia: "Lori_Trahan",
    party: "Democratic",
    wikipedia: "/wiki/Lori_Trahan",
  }]
}, {
  state: "MA",
  number: 4,
  candidates: [{
    first_name: "Jake",
    last_name: "Auchincloss",
    ballotpedia: "Jake_Auchincloss",
    party: "Democratic",
    wikipedia: "/wiki/Jake_Auchincloss",
  }, {
    first_name: "Julie",
    last_name: "Hall",
    ballotpedia: "Julie_Hall_(Massachusetts)",
    party: "Republican",
  }]
}, {
  state: "MA",
  number: 5,
  candidates: [{
    first_name: "Katherine",
    last_name: "Clark",
    ballotpedia: "Katherine_Clark",
    party: "Democratic",
    wikipedia: "/wiki/Katherine_Clark",
  }, {
    first_name: "Caroline",
    last_name: "Colarusso",
    ballotpedia: "Caroline_Colarusso",
    party: "Republican",
  }]
}, {
  state: "MA",
  number: 6,
  candidates: [{
    first_name: "Christopher",
    last_name: "Fisher",
    ballotpedia: "Christopher_Fisher",
    party: "Independent",
  }, {
    first_name: "John Paul",
    last_name: "Moran",
    ballotpedia: "John_Paul_Moran",
    party: "Republican",
  }, {
    first_name: "Seth",
    last_name: "Moulton",
    ballotpedia: "Seth_Moulton",
    party: "Democratic",
    wikipedia: "/wiki/Seth_Moulton",
  }]
}, {
  state: "MA",
  number: 7,
  candidates: [{
    first_name: "Ayanna",
    last_name: "Pressley",
    ballotpedia: "Ayanna_Pressley",
    party: "Democratic",
    wikipedia: "/wiki/Ayanna_Pressley",
  }]
}, {
  state: "MA",
  number: 8,
  candidates: [{
    first_name: "Stephen",
    last_name: "Lynch",
    ballotpedia: "Stephen_Lynch",
    party: "Democratic",
    wikipedia: "/wiki/Stephen_F._Lynch",
  }]
}, {
  state: "MA",
  number: 9,
  candidates: [{
    first_name: "Helen",
    last_name: "Brady",
    ballotpedia: "Helen_Brady",
    party: "Republican",
  }, {
    first_name: "Bill",
    last_name: "Keating",
    ballotpedia: "Bill_Keating",
    party: "Democratic",
    wikipedia: "/wiki/Bill_Keating_(politician)",
  }]
}, {
  state: "MI",
  number: 1,
  candidates: [{
    first_name: "Jack",
    last_name: "Bergman",
    ballotpedia: "Jack_Bergman",
    party: "Republican",
    wikipedia: "/wiki/Jack_Bergman",
  }, {
    first_name: "Ben",
    last_name: "Boren",
    ballotpedia: "Ben_Boren",
    party: "Libertarian",
  }, {
    first_name: "Dana",
    last_name: "Ferguson",
    ballotpedia: "Dana_Alan_Ferguson",
    party: "Democratic",
  }]
}, {
  state: "MI",
  number: 2,
  candidates: [{
    first_name: "Bryan",
    last_name: "Berghoef",
    ballotpedia: "Bryan_Berghoef",
    party: "Democratic",
  }, {
    first_name: "Jean-Michel",
    last_name: "Creviere",
    ballotpedia: "Jean-Michel_Creviere",
    party: "Green",
  }, {
    first_name: "Bill",
    last_name: "Huizenga",
    ballotpedia: "Bill_Huizenga",
    party: "Republican",
    wikipedia: "/wiki/Bill_Huizenga",
  }, {
    first_name: "Max",
    last_name: "Riekse",
    ballotpedia: "Max_Riekse",
    party: "Libertarian",
  }, {
    first_name: "Gerald T.",
    last_name: "Van Sickle",
    ballotpedia: "Gerald_T._Van_Sickle",
    party: "U.S. Taxpayers",
  }]
}, {
  state: "MI",
  number: 3,
  candidates: [{
    first_name: "Peter",
    last_name: "Meijer",
    ballotpedia: "Peter_Meijer",
    party: "Republican",
    wikipedia: "/wiki/Peter_Meijer",
  }, {
    first_name: "Hillary",
    last_name: "Scholten",
    ballotpedia: "Hillary_Scholten",
    party: "Democratic",
  }]
}, {
  state: "MI",
  number: 4,
  candidates: [{
    first_name: "David",
    last_name: "Canny",
    ballotpedia: "David_Canny",
    party: "Libertarian",
  }, {
    first_name: "Jerry",
    last_name: "Hilliard",
    ballotpedia: "Jerry_Hilliard",
    party: "Democratic",
  }, {
    first_name: "John",
    last_name: "Moolenaar",
    ballotpedia: "John_Moolenaar",
    party: "Republican",
    wikipedia: "/wiki/John_Moolenaar",
  }, {
    first_name: "Amy",
    last_name: "Slepr",
    ballotpedia: "Amy_Slepr",
    party: "Green",
  }]
}, {
  state: "MI",
  number: 5,
  candidates: [{
    first_name: "Kathy",
    last_name: "Goodwin",
    ballotpedia: "Kathy_Goodwin",
    party: "Working Class",
  }, {
    first_name: "James",
    last_name: "Harris",
    ballotpedia: "James_Harris_(Michigan)",
    party: "Libertarian",
  }, {
    first_name: "Dan",
    last_name: "Kildee",
    ballotpedia: "Dan_Kildee",
    party: "Democratic",
    wikipedia: "/wiki/Dan_Kildee",
  }, {
    first_name: "Tim",
    last_name: "Kelly",
    ballotpedia: "Tim_Kelly_(Michigan_state_representative)",
    party: "Republican",
    wikipedia: "/wiki/Tim_Kelly_(Michigan_politician)",
  }]
}, {
  state: "MI",
  number: 6,
  candidates: [{
    first_name: "Jeff",
    last_name: "DePoy",
    ballotpedia: "Jeff_DePoy",
    party: "Libertarian",
  }, {
    first_name: "Jon",
    last_name: "Hoadley",
    ballotpedia: "Jon_Hoadley",
    party: "Democratic",
    wikipedia: "/wiki/Jon_Hoadley",
  }, {
    first_name: "John",
    last_name: "Lawrence",
    ballotpedia: "John_Lawrence_(Michigan)",
    party: "Green",
  }, {
    first_name: "Fred",
    last_name: "Upton",
    ballotpedia: "Fred_Upton",
    party: "Republican",
    wikipedia: "/wiki/Fred_Upton",
  }]
}, {
  state: "MI",
  number: 7,
  candidates: [{
    first_name: "Gretchen",
    last_name: "Driskell",
    ballotpedia: "Gretchen_Driskell",
    party: "Democratic",
    wikipedia: "/wiki/Gretchen_Driskell",
  }, {
    first_name: "Tim",
    last_name: "Walberg",
    ballotpedia: "Tim_Walberg",
    party: "Republican",
    wikipedia: "/wiki/Tim_Walberg",
  }]
}, {
  state: "MI",
  number: 8,
  candidates: [{
    first_name: "Joe",
    last_name: "Hartman",
    ballotpedia: "Joe_Hartman",
    party: "Libertarian",
  }, {
    first_name: "Paul",
    last_name: "Junge",
    ballotpedia: "Paul_Junge",
    party: "Republican",
  }, {
    first_name: "Elissa",
    last_name: "Slotkin",
    ballotpedia: "Elissa_Slotkin",
    party: "Democratic",
    wikipedia: "/wiki/Elissa_Slotkin",
  }]
}, {
  state: "MI",
  number: 9,
  candidates: [{
    first_name: "Andrea",
    last_name: "Kirby",
    ballotpedia: "Andrea_Kirby",
    party: "Working Class",
  }, {
    first_name: "Charles",
    last_name: "Langworthy",
    ballotpedia: "Charles_Langworthy",
    party: "Republican",
  }, {
    first_name: "Andy",
    last_name: "Levin",
    ballotpedia: "Andy_Levin",
    party: "Democratic",
    wikipedia: "/wiki/Andy_Levin",
  }, {
    first_name: "Mike",
    last_name: "Saliba",
    ballotpedia: "Mike_Saliba",
    party: "Libertarian",
  }]
}, {
  state: "MI",
  number: 10,
  candidates: [{
    first_name: "Kimberly",
    last_name: "Bizon",
    ballotpedia: "Kimberly_Bizon",
    party: "Democratic",
  }, {
    first_name: "Lisa",
    last_name: "McClain",
    ballotpedia: "Lisa_McClain",
    party: "Republican",
  }]
}, {
  state: "MI",
  number: 11,
  candidates: [{
    first_name: "Eric",
    last_name: "Esshaki",
    ballotpedia: "Eric_Esshaki",
    party: "Republican",
  }, {
    first_name: "Leonard",
    last_name: "Schwartz",
    ballotpedia: "Leonard_Schwartz",
    party: "Libertarian",
  }, {
    first_name: "Haley",
    last_name: "Stevens",
    ballotpedia: "Haley_Stevens",
    party: "Democratic",
    wikipedia: "/wiki/Haley_Stevens",
  }]
}, {
  state: "MI",
  number: 12,
  candidates: [{
    first_name: "Debbie",
    last_name: "Dingell",
    ballotpedia: "Debbie_Dingell",
    party: "Democratic",
    wikipedia: "/wiki/Debbie_Dingell",
  }, {
    first_name: "Jeff",
    last_name: "Jones",
    ballotpedia: "Jeff_Jones_(Michigan)",
    party: "Republican",
  }, {
    first_name: "Gary",
    last_name: "Walkowicz",
    ballotpedia: "Gary_Walkowicz",
    party: "Working Class",
  }]
}, {
  state: "MI",
  number: 13,
  candidates: [{
    first_name: "Articia",
    last_name: "Bomer",
    ballotpedia: "Articia_Bomer",
    party: "U.S. Taxpayers",
  }, {
    first_name: "David",
    last_name: "Dudenhoefer",
    ballotpedia: "David_Dudenhoefer",
    party: "Republican",
  }, {
    first_name: "Sam",
    last_name: "Johnson",
    ballotpedia: "Sam_Johnson_(Michigan)",
    party: "Working Class",
  }, {
    first_name: "Rashida",
    last_name: "Tlaib",
    ballotpedia: "Rashida_Tlaib",
    party: "Democratic",
    wikipedia: "/wiki/Rashida_Tlaib",
  }, {
    first_name: "D. Etta",
    last_name: "Wilcoxon",
    ballotpedia: "D._Etta_Wilcoxon",
    party: "Green",
  }]
}, {
  state: "MI",
  number: 14,
  candidates: [{
    first_name: "Lisa Lane",
    last_name: "Gioia",
    ballotpedia: "Lisa_Lane_Gioia",
    party: "Libertarian",
  }, {
    first_name: "Philip",
    last_name: "Kolody",
    ballotpedia: "Philip_Kolody",
    party: "Working Class",
  }, {
    first_name: "Brenda",
    last_name: "Lawrence",
    ballotpedia: "Brenda_Lawrence_(Michigan)",
    party: "Democratic",
    wikipedia: "/wiki/Brenda_Lawrence",
  }, {
    first_name: "R. Vance",
    last_name: "Patrick",
    ballotpedia: "Robert_Vance_Patrick",
    party: "Republican",
  }, {
    first_name: "Clyde",
    last_name: "Shabazz",
    ballotpedia: "Clyde_Shabazz",
    party: "Green",
  }]
}, {
  state: "MN",
  number: 1,
  candidates: [{
    first_name: "Dan",
    last_name: "Feehan",
    ballotpedia: "Dan_Feehan",
    party: "Democratic",
    wikipedia: "/wiki/Dan_Feehan_(politician)",
  }, {
    first_name: "Jim",
    last_name: "Hagedorn",
    ballotpedia: "Jim_Hagedorn",
    party: "Republican",
    wikipedia: "/wiki/Jim_Hagedorn",
  }, {
    first_name: "Bill",
    last_name: "Rood",
    ballotpedia: "Bill_Rood",
    party: "Legalize Cannabis",
  }]
}, {
  state: "MN",
  number: 2,
  candidates: [{
    first_name: "Angie",
    last_name: "Craig",
    ballotpedia: "Angie_Craig",
    party: "Democratic",
    wikipedia: "/wiki/Angie_Craig",
  }, {
    first_name: "Tyler",
    last_name: "Kistner",
    ballotpedia: "Tyler_Kistner",
    party: "Republican",
  }, {
    first_name: "Adam",
    last_name: "Weeks",
    ballotpedia: "Adam_Weeks_(Minnesota)",
    party: "Legal Marijuana",
  }]
}, {
  state: "MN",
  number: 3,
  candidates: [{
    first_name: "Dean",
    last_name: "Phillips",
    ballotpedia: "Dean_Phillips",
    party: "Democratic",
    wikipedia: "/wiki/Dean_Phillips",
  }, {
    first_name: "Kendall",
    last_name: "Qualls",
    ballotpedia: "Kendall_Qualls",
    party: "Republican",
  }]
}, {
  state: "MN",
  number: 4,
  candidates: [{
    first_name: "Sia",
    last_name: "Lo",
    ballotpedia: "Sia_Lo",
    party: "Republican",
  }, {
    first_name: "Betty",
    last_name: "McCollum",
    ballotpedia: "Betty_McCollum",
    party: "Democratic",
    wikipedia: "/wiki/Betty_McCollum",
  }, {
    first_name: "Gene",
    last_name: "Rechtzigel",
    ballotpedia: "Gene_Rechtzigel",
    party: "Republican",
  }, {
    first_name: "Susan",
    last_name: "Sindt",
    ballotpedia: "Susan_Pendergast_Sindt",
    party: "Legalize Cannabis",
  }]
}, {
  state: "MN",
  number: 5,
  candidates: [{
    first_name: "Lacy",
    last_name: "Johnson",
    ballotpedia: "Lacy_Johnson_(Minnesota)",
    party: "Republican",
  }, {
    first_name: "Michael",
    last_name: "Moore",
    ballotpedia: "Michael_Moore_(Minnesota)",
    party: "Legal Marijuana",
  }, {
    first_name: "Ilhan",
    last_name: "Omar",
    ballotpedia: "Ilhan_Omar",
    party: "Democratic",
    wikipedia: "/wiki/Ilhan_Omar",
  }]
}, {
  state: "MN",
  number: 6,
  candidates: [{
    first_name: "Tom",
    last_name: "Emmer",
    ballotpedia: "Tom_Emmer",
    party: "Republican",
    wikipedia: "/wiki/Tom_Emmer",
  }, {
    first_name: "Tawnja",
    last_name: "Zahradka",
    ballotpedia: "Tawnja_Zahradka",
    party: "Democratic",
  }]
}, {
  state: "MN",
  number: 7,
  candidates: [{
    first_name: "Michelle",
    last_name: "Fischbach",
    ballotpedia: "Michelle_Fischbach",
    party: "Republican",
    wikipedia: "/wiki/Michelle_Fischbach",
  }, {
    first_name: "Rae Hart",
    last_name: "Anderson",
    ballotpedia: "Rae_Hart_Anderson",
    party: "Legalize Cannabis",
  }, {
    first_name: "Slater",
    last_name: "Johnson",
    ballotpedia: "Slater_Johnson",
    party: "Legal Marijuana",
  }, {
    first_name: "Collin",
    last_name: "Peterson",
    ballotpedia: "Collin_Peterson",
    party: "Democratic",
    wikipedia: "/wiki/Collin_Peterson",
  }]
}, {
  state: "MN",
  number: 8,
  candidates: [{
    first_name: "Quinn",
    last_name: "Nystrom",
    ballotpedia: "Quinn_Nystrom",
    party: "Democratic",
  }, {
    first_name: "Judith",
    last_name: "Schwartzbacker",
    ballotpedia: "Judith_Schwartzbacker",
    party: "Legalize Cannabis",
  }, {
    first_name: "Pete",
    last_name: "Stauber",
    ballotpedia: "Pete_Stauber",
    party: "Republican",
    wikipedia: "/wiki/Pete_Stauber",
  }]
}, {
  state: "MS",
  number: 1,
  candidates: [{
    first_name: "Antonia",
    last_name: "Eliason",
    ballotpedia: "Antonia_Eliason",
    party: "Democratic",
  }, {
    first_name: "Trent",
    last_name: "Kelly",
    ballotpedia: "Trent_Kelly",
    party: "Republican",
    wikipedia: "/wiki/Trent_Kelly",
  }]
}, {
  state: "MS",
  number: 2,
  candidates: [{
    first_name: "Brian",
    last_name: "Flowers",
    ballotpedia: "Brian_Flowers",
    party: "Republican",
  }, {
    first_name: "Bennie",
    last_name: "Thompson",
    ballotpedia: "Bennie_Thompson",
    party: "Democratic",
    wikipedia: "/wiki/Bennie_Thompson",
  }]
}, {
  state: "MS",
  number: 3,
  candidates: [{
    first_name: "Dorothy \"Dot\"",
    last_name: "Benford",
    ballotpedia: "Dorothy_Benford",
    party: "Democratic",
  }, {
    first_name: "Michael",
    last_name: "Guest",
    ballotpedia: "Michael_Guest",
    party: "Republican",
    wikipedia: "/wiki/Michael_Guest_(politician)",
  }]
}, {
  state: "MS",
  number: 4,
  candidates: [{
    first_name: "Steven",
    last_name: "Palazzo",
    ballotpedia: "Steven_Palazzo",
    party: "Republican",
    wikipedia: "/wiki/Steven_Palazzo",
  }]
}, {
  state: "MO",
  number: 1,
  candidates: [{
    first_name: "Cori",
    last_name: "Bush",
    ballotpedia: "Cori_Bush",
    party: "Democratic",
    wikipedia: "/wiki/Cori_Bush",
  }, {
    first_name: "Alex",
    last_name: "Furman",
    ballotpedia: "Alex_Furman",
    party: "Libertarian",
  }, {
    first_name: "Anthony",
    last_name: "Rogers",
    ballotpedia: "Anthony_Rogers",
    party: "Republican",
  }]
}, {
  state: "MO",
  number: 2,
  candidates: [{
    first_name: "Martin",
    last_name: "Schulte",
    ballotpedia: "Martin_Schulte",
    party: "Libertarian",
  }, {
    first_name: "Jill",
    last_name: "Schupp",
    ballotpedia: "Jill_Schupp",
    party: "Democratic",
    wikipedia: "/wiki/Jill_Schupp",
  }, {
    first_name: "Ann",
    last_name: "Wagner",
    ballotpedia: "Ann_Wagner",
    party: "Republican",
    wikipedia: "/wiki/Ann_Wagner",
  }]
}, {
  state: "MO",
  number: 3,
  candidates: [{
    first_name: "Blaine",
    last_name: "Luetkemeyer",
    ballotpedia: "Blaine_Luetkemeyer",
    party: "Republican",
    wikipedia: "/wiki/Blaine_Luetkemeyer",
  }, {
    first_name: "Megan",
    last_name: "Rezabek",
    ballotpedia: "Megan_Rezabek",
    party: "Democratic",
  }, {
    first_name: "Leonard",
    last_name: "Steinman II",
    ballotpedia: "Leonard_Steinman_II",
    party: "Libertarian",
  }]
}, {
  state: "MO",
  number: 4,
  candidates: [{
    first_name: "Vicky",
    last_name: "Hartzler",
    ballotpedia: "Vicky_Hartzler",
    party: "Republican",
    wikipedia: "/wiki/Vicky_Hartzler",
  }, {
    first_name: "Steven",
    last_name: "Koonse",
    ballotpedia: "Steven_Koonse",
    party: "Libertarian",
  }, {
    first_name: "Lindsey",
    last_name: "Simmons",
    ballotpedia: "Lindsey_Simmons",
    party: "Democratic",
  }]
}, {
  state: "MO",
  number: 5,
  candidates: [{
    first_name: "Emanuel",
    last_name: "Cleaver",
    ballotpedia: "Emanuel_Cleaver",
    party: "Democratic",
    wikipedia: "/wiki/Emanuel_Cleaver",
  }, {
    first_name: "Ryan",
    last_name: "Derks",
    ballotpedia: "Ryan_Derks",
    party: "Republican",
  }, {
    first_name: "Robin",
    last_name: "Dominick",
    ballotpedia: "Robin_Dominick",
    party: "Libertarian",
  }]
}, {
  state: "MO",
  number: 6,
  candidates: [{
    first_name: "Sam",
    last_name: "Graves",
    ballotpedia: "Sam_Graves",
    party: "Republican",
    wikipedia: "/wiki/Sam_Graves",
  }, {
    first_name: "Jim",
    last_name: "Higgins",
    ballotpedia: "Jim_Higgins",
    party: "Libertarian",
  }, {
    honorific: "Dr.",
    first_name: "Gena L.",
    last_name: "Ross",
    ballotpedia: "Gena_Ross",
    party: "Democratic",
  }]
}, {
  state: "MO",
  number: 7,
  candidates: [{
    first_name: "Kevin",
    last_name: "Craig",
    ballotpedia: "Kevin_Craig_(Missouri)",
    party: "Libertarian",
  }, {
    first_name: "Billy",
    last_name: "Long",
    ballotpedia: "Billy_Long",
    party: "Republican",
    wikipedia: "/wiki/Billy_Long",
  }, {
    first_name: "Teresa",
    last_name: "Montseny",
    ballotpedia: "Teresa_Montseny",
    party: "Democratic",
  }]
}, {
  state: "MO",
  number: 8,
  candidates: [{
    first_name: "Kathy",
    last_name: "Ellis",
    ballotpedia: "Kathy_Ellis",
    party: "Democratic",
  }, {
    first_name: "Jason",
    last_name: "Smith",
    ballotpedia: "Jason_Smith_(Missouri_representative)",
    party: "Republican",
    wikipedia: "/wiki/Jason_Smith_(politician)",
  }, {
    first_name: "Tom",
    last_name: "Schmitz",
    ballotpedia: "Tom_Schmitz",
    party: "Libertarian",
  }]
}, {
  state: "MT",
  number: 0,
  candidates: [{
    first_name: "Matt",
    last_name: "Rosendale",
    ballotpedia: "Matt_Rosendale",
    party: "Republican",
    wikipedia: "/wiki/Matt_Rosendale",
  }, {
    first_name: "Kathleen",
    last_name: "Williams",
    ballotpedia: "Kathleen_Williams",
    party: "Democratic",
    wikipedia: "/wiki/Kathleen_Williams_(politician)",
  }]
}, {
  state: "NE",
  number: 1,
  candidates: [{
    first_name: "Kate",
    last_name: "Bolz",
    ballotpedia: "Kate_Bolz",
    party: "Democratic",
    wikipedia: "/wiki/Kate_Bolz",
  }, {
    first_name: "Jeff",
    last_name: "Fortenberry",
    ballotpedia: "Jeff_Fortenberry",
    party: "Republican",
    wikipedia: "/wiki/Jeff_Fortenberry",
  }, {
    first_name: "Dennis",
    last_name: "Grace",
    ballotpedia: "Dennis_Grace",
    party: "Libertarian",
  }]
}, {
  state: "NE",
  number: 2,
  candidates: [{
    first_name: "Don",
    last_name: "Bacon",
    ballotpedia: "Don_Bacon",
    party: "Republican",
    wikipedia: "/wiki/Don_Bacon_(politician)",
  }, {
    first_name: "Kara",
    last_name: "Eastman",
    ballotpedia: "Kara_Eastman",
    party: "Democratic",
  }, {
    first_name: "Tyler",
    last_name: "Schaeffer",
    ballotpedia: "Tyler_Schaeffer",
    party: "Libertarian",
  }]
}, {
  state: "NE",
  number: 3,
  candidates: [{
    first_name: "Mark",
    last_name: "Elworth",
    ballotpedia: "Mark_Elworth",
    party: "Democratic",
  }, {
    first_name: "Dustin",
    last_name: "Hobbs",
    ballotpedia: "Dustin_Hobbs",
    party: "Libertarian",
  }, {
    first_name: "Adrian",
    last_name: "Smith",
    ballotpedia: "Adrian_Smith",
    party: "Republican",
    wikipedia: "/wiki/Adrian_Smith_(politician)",
  }]
}, {
  state: "NV",
  number: 1,
  candidates: [{
    first_name: "Kamau",
    last_name: "Bakari",
    ballotpedia: "Kamau_Bakari",
    party: "Independent American",
  }, {
    first_name: "Joyce",
    last_name: "Bentley",
    ballotpedia: "Joyce_Bentley",
    party: "Republican",
  }, {
    first_name: "Joseph",
    last_name: "Maridon",
    ballotpedia: "Joseph_Maridon_Jr.",
    party: "Independent",
  }, {
    first_name: "Robert",
    last_name: "Van Strawder",
    ballotpedia: "Robert_Van_Strawder",
    party: "Libertarian",
  }, {
    first_name: "Dina",
    last_name: "Titus",
    ballotpedia: "Dina_Titus",
    party: "Democratic",
    wikipedia: "/wiki/Dina_Titus",
  }]
}, {
  state: "NV",
  number: 2,
  candidates: [{
    first_name: "Patricia",
    last_name: "Ackerman",
    ballotpedia: "Patricia_Ackerman",
    party: "Democratic",
  }, {
    first_name: "Mark",
    last_name: "Amodei",
    ballotpedia: "Mark_Amodei",
    party: "Republican",
    wikipedia: "/wiki/Mark_Amodei",
  }, {
    first_name: "Richard",
    last_name: "Dunn",
    ballotpedia: "Richard_Dunn",
    party: "Independent",
  }, {
    first_name: "Janine",
    last_name: "Hansen",
    ballotpedia: "Janine_Hansen",
    party: "Independent American",
  }]
}, {
  state: "NV",
  number: 3,
  candidates: [{
    first_name: "Edward",
    last_name: "Bridges II",
    ballotpedia: "Edward_Bridges_II",
    party: "Independent American",
  }, {
    first_name: "Gary",
    last_name: "Crispin",
    ballotpedia: "Gary_Crispin",
    party: "Independent",
  }, {
    first_name: "Susie",
    last_name: "Lee",
    ballotpedia: "Susie_Lee",
    party: "Democratic",
    wikipedia: "/wiki/Susie_Lee",
  }, {
    first_name: "Dan",
    last_name: "Rodimer",
    ballotpedia: "Dan_Rodimer",
    party: "Republican",
    wikipedia: "/wiki/Daniel_Rodimer",
  }]
}, {
  state: "NV",
  number: 4,
  candidates: [{
    first_name: "Jonathan",
    last_name: "Esteban",
    ballotpedia: "Jonathan_Esteban",
    party: "Libertarian",
  }, {
    first_name: "Steven",
    last_name: "Horsford",
    ballotpedia: "Steven_Horsford",
    party: "Democratic",
    wikipedia: "/wiki/Steven_Horsford",
  }, {
    first_name: "Jim",
    last_name: "Marchant",
    ballotpedia: "Jim_Marchant",
    party: "Republican",
    wikipedia: "/wiki/Jim_Marchant",
  }, {
    first_name: "Barry",
    last_name: "Rubinson",
    ballotpedia: "Barry_Rubinson",
    party: "Independent American",
  }]
}, {
  state: "NH",
  number: 1,
  candidates: [{
    first_name: "Zachary",
    last_name: "Dumont",
    ballotpedia: "Zachary_Dumont",
    party: "Libertarian",
  }, {
    first_name: "Matt",
    last_name: "Mowers",
    ballotpedia: "Matt_Mowers",
    party: "Republican",
  }, {
    first_name: "Chris",
    last_name: "Pappas",
    ballotpedia: "Chris_Pappas",
    party: "Democratic",
    wikipedia: "/wiki/Chris_Pappas_(politician)",
  }]
}, {
  state: "NH",
  number: 2,
  candidates: [{
    first_name: "Ann",
    last_name: "Kuster",
    ballotpedia: "Ann_Kuster",
    party: "Democratic",
    wikipedia: "/wiki/Ann_Kuster",
  }, {
    first_name: "Steve",
    last_name: "Negron",
    ballotpedia: "Steve_Negron",
    party: "Republican",
    wikipedia: "/wiki/Steve_Negron",
  }, {
    first_name: "Andrew",
    last_name: "Olding",
    ballotpedia: "Andrew_Olding",
    party: "Libertarian",
  }]
}, {
  state: "NJ",
  number: 1,
  candidates: [{
    first_name: "Claire",
    last_name: "Gustafson",
    ballotpedia: "Claire_Gustafson",
    party: "Republican",
  }, {
    first_name: "Donald",
    last_name: "Norcross",
    ballotpedia: "Donald_Norcross",
    party: "Democratic",
    wikipedia: "/wiki/Donald_Norcross",
  }]
}, {
  state: "NJ",
  number: 2,
  candidates: [{
    first_name: "Jesse",
    last_name: "Ehrnstrom",
    ballotpedia: "Jesse_Ehrnstrom",
    party: "Libertarian",
  }, {
    first_name: "Jenna",
    last_name: "Harvey",
    ballotpedia: "Jenna_Harvey",
    party: "Independent",
  }, {
    first_name: "Amy",
    last_name: "Kennedy",
    ballotpedia: "Amy_Kennedy",
    party: "Democratic",
    wikipedia: "/wiki/Amy_Kennedy",
  }, {
    first_name: "Jeff",
    last_name: "Van Drew",
    ballotpedia: "Jeff_Van_Drew",
    party: "Republican",
    wikipedia: "/wiki/Jeff_Van_Drew",
  }]
}, {
  state: "NJ",
  number: 3,
  candidates: [{
    first_name: "Andy",
    last_name: "Kim",
    ballotpedia: "Andrew_Kim_(New_Jersey)",
    party: "Democratic",
    wikipedia: "/wiki/Andy_Kim_(politician)",
  }, {
    first_name: "David",
    last_name: "Richter",
    ballotpedia: "David_Richter_(New_Jersey)",
    party: "Republican",
  }, {
    first_name: "Robert",
    last_name: "Shapiro",
    ballotpedia: "Robert_Shapiro",
    party: "Independent Constitution",
  }, {
    first_name: "Martin",
    last_name: "Weber",
    ballotpedia: "Martin_Weber",
    party: "Independent",
  }]
}, {
  state: "NJ",
  number: 4,
  candidates: [{
    first_name: "Andrew",
    last_name: "Machuta",
    party: "Independent",
  }, {
    first_name: "Michael",
    last_name: "Rufo",
    ballotpedia: "Michael_Rufo",
    party: "Libertarian",
  }, {
    first_name: "Stephanie",
    last_name: "Schmid",
    ballotpedia: "Stephanie_Schmid",
    party: "Democratic",
  }, {
    first_name: "Hank",
    last_name: "Schroeder",
    ballotpedia: "Hank_Schroeder",
    party: "Independent",
  }, {
    first_name: "Chris",
    last_name: "Smith",
    ballotpedia: "Chris_Smith_(New_Jersey)",
    party: "Republican",
    wikipedia: "/wiki/Chris_Smith_(New_Jersey_politician)",
  }]
}, {
  state: "NJ",
  number: 5,
  candidates: [{
    first_name: "Josh",
    last_name: "Gottheimer",
    ballotpedia: "Josh_Gottheimer",
    party: "Democratic",
    wikipedia: "/wiki/Josh_Gottheimer",
  }, {
    first_name: "Frank",
    last_name: "Pallotta",
    ballotpedia: "Frank_Pallotta",
    party: "Republican",
  }, {
    first_name: "Louis",
    last_name: "Vellucci",
    ballotpedia: "Louis_Vellucci",
    party: "Independent",
  }]
}, {
  state: "NJ",
  number: 6,
  candidates: [{
    first_name: "Frank",
    last_name: "Pallone",
    ballotpedia: "Frank_Pallone",
    party: "Democratic",
    wikipedia: "/wiki/Frank_Pallone",
  }, {
    first_name: "Christian",
    last_name: "Onuoha",
    ballotpedia: "Christian_Onuoha",
    party: "Republican",
  }]
}, {
  state: "NJ",
  number: 7,
  candidates: [{
    first_name: "Thomas",
    last_name: "Kean",
    suffix: "Jr.",
    ballotpedia: "Thomas_Kean_Jr.",
    party: "Republican",
    wikipedia: "/wiki/Thomas_Kean_Jr.",
  }, {
    first_name: "Tom",
    last_name: "Malinowski",
    ballotpedia: "Tom_Malinowski",
    party: "Democratic",
    wikipedia: "/wiki/Tom_Malinowski",
  }]
}, {
  state: "NJ",
  number: 8,
  candidates: [{
    first_name: "Dan",
    last_name: "Delaney",
    ballotpedia: "Dan_Delaney",
    party: "Libertarian",
  }, {
    first_name: "Jason",
    last_name: "Mushnick",
    ballotpedia: "Jason_Mushnick",
    party: "Republican",
  }, {
    first_name: "Albio",
    last_name: "Sires",
    ballotpedia: "Albio_Sires",
    party: "Democratic",
    wikipedia: "/wiki/Albio_Sires",
  }]
}, {
  state: "NJ",
  number: 9,
  candidates: [{
    first_name: "Chris",
    last_name: "Auriemma",
    ballotpedia: "Chris_Auriemma",
    party: "Independent",
  }, {
    first_name: "Bill",
    last_name: "Pascrell",
    ballotpedia: "Bill_Pascrell",
    party: "Democratic",
    wikipedia: "/wiki/Bill_Pascrell",
  }, {
    first_name: "Billy",
    last_name: "Prempeh",
    ballotpedia: "Billy_Prempeh",
    party: "Republican",
  }]
}, {
  state: "NJ",
  number: 10,
  candidates: [{
    first_name: "Khaliah",
    last_name: "Fitchette",
    ballotpedia: "Khaliah_Fitchette",
    party: "Independent",
  }, {
    first_name: "Akil",
    last_name: "Khalfani",
    ballotpedia: "Akil_Khalfani",
    party: "Independent",
  }, {
    first_name: "John",
    last_name: "Mirrione",
    ballotpedia: "John_Mirrione",
    party: "Libertarian",
  }, {
    first_name: "Donald M.",
    last_name: "Payne",
    suffix: "Jr.",
    ballotpedia: "Donald_Payne_Jr.",
    party: "Democratic",
    wikipedia: "/wiki/Donald_Payne_Jr.",
  }, {
    first_name: "Jennifer",
    last_name: "Zinone",
    ballotpedia: "Jennifer_Zinone",
    party: "Republican",
  }]
}, {
  state: "NJ",
  number: 11,
  candidates: [{
    first_name: "Rosemary",
    last_name: "Becchi",
    ballotpedia: "Rosemary_Becchi",
    party: "Republican",
  }, {
    first_name: "Mikie",
    last_name: "Sherrill",
    ballotpedia: "Mikie_Sherrill",
    party: "Democratic",
    wikipedia: "/wiki/Mikie_Sherrill",
  }]
}, {
  state: "NJ",
  number: 12,
  candidates: [{
    first_name: "Kenneth",
    last_name: "Cody",
    ballotpedia: "Kenneth_Cody",
    party: "Independent",
  }, {
    first_name: "Edward",
    last_name: "Forchion",
    ballotpedia: "Edward_Forchion",
    party: "Legalize Marijuana",
    wikipedia: "/wiki/Ed_Forchion",
  }, {
    first_name: "Mark",
    last_name: "Razzoli",
    ballotpedia: "Mark_Razzoli",
    party: "Republican",
  }, {
    first_name: "Bonnie Watson",
    last_name: "Coleman",
    ballotpedia: "Bonnie_Watson_Coleman",
    party: "Democratic",
    wikipedia: "/wiki/Bonnie_Watson_Coleman",
  }]
}, {
  state: "NM",
  number: 1,
  candidates: [{
    first_name: "Deb",
    last_name: "Haaland",
    ballotpedia: "Debra_Haaland",
    party: "Democratic",
    wikipedia: "/wiki/Deb_Haaland",
  }, {
    first_name: "Michelle",
    last_name: "Garcia Holmes",
    ballotpedia: "Michelle_Garcia_Holmes",
    party: "Republican",
  }]
}, {
  state: "NM",
  number: 2,
  candidates: [{
    first_name: "Yvette",
    last_name: "Herrell",
    ballotpedia: "Yvette_Herrell",
    party: "Republican",
    wikipedia: "/wiki/Yvette_Herrell",
  }, {
    first_name: "Xochitl",
    last_name: "Torres Small",
    ballotpedia: "Xochitl_Torres_Small",
    party: "Democratic",
    wikipedia: "/wiki/Xochitl_Torres_Small",
  }]
}, {
  state: "NM",
  number: 3,
  candidates: [{
    first_name: "Alexis",
    last_name: "Johnson",
    ballotpedia: "Alexis_Johnson",
    party: "Republican",
  }, {
    first_name: "Teresa",
    last_name: "Leger Fernandez",
    ballotpedia: "Teresa_Leger_Fernandez",
    party: "Democratic",
    wikipedia: "/wiki/Teresa_Leger_Fernandez",
  }]
}, {
  state: "NY",
  number: 1,
  candidates: [{
    first_name: "Joshua",
    last_name: "Goldfein",
    ballotpedia: "Joshua_Goldfein",
    party: "Working Families",
  }, {
    first_name: "Nancy",
    last_name: "Goroff",
    ballotpedia: "Nancy_Goroff",
    party: "Democratic",
  }, {
    first_name: "Lee",
    last_name: "Zeldin",
    ballotpedia: "Lee_Zeldin",
    party: "Republican",
    wikipedia: "/wiki/Lee_Zeldin",
  }]
}, {
  state: "NY",
  number: 2,
  candidates: [{
    first_name: "Harry",
    last_name: "Burger",
    ballotpedia: "Harry_Burger",
    party: "Green",
  }, {
    first_name: "Andrew",
    last_name: "Garbarino",
    ballotpedia: "Andrew_Garbarino",
    party: "Republican",
    wikipedia: "/wiki/Andrew_Garbarino",
  }, {
    first_name: "Jackie",
    last_name: "Gordon",
    ballotpedia: "Jackie_Gordon",
    party: "Democratic",
  }]
}, {
  state: "NY",
  number: 3,
  candidates: [{
    first_name: "Howard",
    last_name: "Rabin",
    ballotpedia: "Howard_Rabin",
    party: "Libertarian",
  }, {
    first_name: "George",
    last_name: "Santos",
    ballotpedia: "George_Devolder-Santos",
    party: "Republican",
  }, {
    first_name: "Tom",
    last_name: "Suozzi",
    ballotpedia: "Tom_Suozzi",
    party: "Democratic",
    wikipedia: "/wiki/Tom_Suozzi",
  }]
}, {
  state: "NY",
  number: 4,
  candidates: [{
    first_name: "Joseph",
    last_name: "Naham",
    ballotpedia: "Joseph_R._Naham",
    party: "Green",
  }, {
    first_name: "Kathleen",
    last_name: "Rice",
    ballotpedia: "Kathleen_Rice",
    party: "Democratic",
    wikipedia: "/wiki/Kathleen_Rice",
  }, {
    first_name: "Douglas",
    last_name: "Tuman",
    ballotpedia: "Douglas_Tuman",
    party: "Republican",
  }]
}, {
  state: "NY",
  number: 5,
  candidates: [{
    first_name: "Gregory",
    last_name: "Meeks",
    ballotpedia: "Gregory_Meeks",
    party: "Democratic",
    wikipedia: "/wiki/Gregory_Meeks",
  }]
}, {
  state: "NY",
  number: 6,
  candidates: [{
    first_name: "Grace",
    last_name: "Meng",
    ballotpedia: "Grace_Meng",
    party: "Democratic",
    wikipedia: "/wiki/Grace_Meng",
  }, {
    first_name: "Tom",
    last_name: "Zmich",
    ballotpedia: "Thomas_Zmich",
    party: "Republican",
  }]
}, {
  state: "NY",
  number: 7,
  candidates: [{
    first_name: "Brian",
    last_name: "Kelly",
    ballotpedia: "Brian_Kelly_(New_York)",
    party: "Republican",
  }, {
    first_name: "Gilbert",
    last_name: "Midonnet",
    ballotpedia: "Gilbert_Midonnet",
    party: "Libertarian",
  }, {
    first_name: "Nydia",
    last_name: "Velázquez",
    ballotpedia: "Nydia_Velázquez",
    party: "Democratic",
    wikipedia: "/wiki/Nydia_Vel%C3%A1zquez",
  }]
}, {
  state: "NY",
  number: 8,
  candidates: [{
    first_name: "Hakeem",
    last_name: "Jeffries",
    ballotpedia: "Hakeem_Jeffries",
    party: "Democratic",
    wikipedia: "/wiki/Hakeem_Jeffries",
  }, {
    first_name: "Garfield",
    last_name: "Wallace",
    ballotpedia: "Garfield_Wallace",
    party: "Republican",
  }]
}, {
  state: "NY",
  number: 9,
  candidates: [{
    first_name: "Joel",
    last_name: "Anabilah-Azumah",
    ballotpedia: "Joel_Anabilah-Azumah",
    party: "Serve America",
  }, {
    first_name: "Yvette",
    last_name: "Clarke",
    ballotpedia: "Yvette_Clarke",
    party: "Democratic",
    wikipedia: "/wiki/Yvette_Clarke",
  }, {
    first_name: "Judith",
    last_name: "Goldiner",
    ballotpedia: "Judith_Goldiner",
    party: "Working Families",
  }, {
    first_name: "Constantin",
    last_name: "Jean-Pierre",
    ballotpedia: "Constantin_Jean-Pierre",
    party: "Republican",
  }, {
    first_name: "Gary",
    last_name: "Popkin",
    ballotpedia: "Gary_Popkin",
    party: "Libertarian",
  }]
}, {
  state: "NY",
  number: 10,
  candidates: [{
    first_name: "Cathy",
    last_name: "Bernstein",
    ballotpedia: "Cathy_Bernstein",
    party: "Republican",
  }, {
    first_name: "Michael",
    last_name: "Madrid",
    ballotpedia: "Michael_Madrid",
    party: "Libertarian",
  }, {
    first_name: "Jerry",
    last_name: "Nadler",
    ballotpedia: "Jerry_Nadler",
    party: "Democratic",
    wikipedia: "/wiki/Jerry_Nadler",
  }]
}, {
  state: "NY",
  number: 11,
  candidates: [{
    first_name: "Nicole",
    last_name: "Malliotakis",
    ballotpedia: "Nicole_Malliotakis",
    party: "Republican",
    wikipedia: "/wiki/Nicole_Malliotakis",
  }, {
    first_name: "Max",
    last_name: "Rose",
    ballotpedia: "Max_Rose",
    party: "Democratic",
    wikipedia: "/wiki/Max_Rose",
  }]
}, {
  state: "NY",
  number: 12,
  candidates: [{
    first_name: "Carlos",
    last_name: "Santiago-Cano",
    ballotpedia: "Carlos_Santiago-Cano",
    party: "Republican",
  }, {
    first_name: "Steven",
    last_name: "Kolln",
    ballotpedia: "Steven_Kolln",
    party: "Libertarian",
  }, {
    first_name: "Carolyn",
    last_name: "Maloney",
    ballotpedia: "Carolyn_Maloney",
    party: "Democratic",
    wikipedia: "/wiki/Carolyn_Maloney",
  }]
}, {
  state: "NY",
  number: 13,
  candidates: [{
    first_name: "Adriano",
    last_name: "Espaillat",
    ballotpedia: "Adriano_Espaillat",
    party: "Democratic",
    wikipedia: "/wiki/Adriano_Espaillat",
  }, {
    first_name: "Christopher",
    last_name: "Morris-Perry",
    ballotpedia: "Christopher_Morris-Perry",
    party: "Conservative",
  }, {
    first_name: "Lovelynn",
    last_name: "Gwinn",
    ballotpedia: "Lovelynn_Gwinn",
    party: "Republican",
  }]
}, {
  state: "NY",
  number: 14,
  candidates: [{
    first_name: "Michelle",
    last_name: "Caruso-Cabrera",
    ballotpedia: "Michelle_Caruso-Cabrera",
    party: "Serve America",
    wikipedia: "/wiki/Michelle_Caruso-Cabrera",
  }, {
    first_name: "John",
    last_name: "Cummings",
    ballotpedia: "John_Cummings_(New_York)",
    party: "Republican",
  }, {
    first_name: "Alexandria",
    last_name: "Ocasio-Cortez",
    ballotpedia: "Alexandria_Ocasio-Cortez",
    party: "Democratic",
    wikipedia: "/wiki/Alexandria_Ocasio-Cortez",
  }]
}, {
  state: "NY",
  number: 15,
  candidates: [{
    first_name: "Patrick",
    last_name: "Delices",
    ballotpedia: "Patrick_Delices",
    party: "Republican",
  }, {
    first_name: "Ritchie",
    last_name: "Torres",
    ballotpedia: "Ritchie_Torres",
    party: "Democratic",
    wikipedia: "/wiki/Ritchie_Torres",
  }]
}, {
  state: "NY",
  number: 16,
  candidates: [{
    first_name: "Jamaal",
    last_name: "Bowman",
    ballotpedia: "Jamaal_Bowman",
    party: "Democratic",
    wikipedia: "/wiki/Jamaal_Bowman",
  }, {
    first_name: "Patrick",
    last_name: "McManus",
    ballotpedia: "Patrick_McManus",
    party: "Conservative",
  }, {
    first_name: "Kenneth",
    last_name: "Schaeffer",
    ballotpedia: "Kenneth_Schaeffer",
    party: "Working Families",
  }]
}, {
  state: "NY",
  number: 17,
  candidates: [{
    first_name: "Joshua",
    last_name: "Eisen",
    ballotpedia: "Joshua_Eisen",
    party: "Independent",
  }, {
    first_name: "Yehudis",
    last_name: "Gottesfeld",
    ballotpedia: "Yehudis_Gottesfeld",
    party: "Conservative",
  }, {
    first_name: "Mondaire",
    last_name: "Jones",
    ballotpedia: "Mondaire_Jones",
    party: "Democratic",
    wikipedia: "/wiki/Mondaire_Jones",
  }, {
    first_name: "Maureen",
    last_name: "McArdle-Schulman",
    ballotpedia: "Maureen_McArdle_Schulman",
    party: "Republican",
  }, {
    first_name: "Michael",
    last_name: "Parietti",
    ballotpedia: "Michael_Parietti",
    party: "Serve America",
  }]
}, {
  state: "NY",
  number: 18,
  candidates: [{
    first_name: "Chele",
    last_name: "Farley",
    ballotpedia: "Chele_Farley",
    party: "Republican",
  }, {
    first_name: "Sean Patrick",
    last_name: "Maloney",
    ballotpedia: "Sean_Patrick_Maloney",
    party: "Democratic",
    wikipedia: "/wiki/Sean_Patrick_Maloney",
  }, {
    first_name: "Scott",
    last_name: "Smith",
    ballotpedia: "Scott_Smith_(New_York)",
    party: "Libertarian",
  }]
}, {
  state: "NY",
  number: 19,
  candidates: [{
    first_name: "Victoria",
    last_name: "Alexander",
    ballotpedia: "Victoria_Alexander",
    party: "Libertarian",
  }, {
    first_name: "Antonio",
    last_name: "Delgado",
    ballotpedia: "Antonio_Delgado_(New_York)",
    party: "Democratic",
    wikipedia: "/wiki/Antonio_Delgado_(politician)",
  }, {
    first_name: "Steve",
    last_name: "Greenfield",
    ballotpedia: "Steven_Greenfield",
    party: "Green",
  }, {
    first_name: "Kyle",
    last_name: "Van De Water",
    ballotpedia: "Kyle_Van_De_Water",
    party: "Republican",
  }]
}, {
  state: "NY",
  number: 20,
  candidates: [{
    first_name: "Elizabeth",
    last_name: "Joy",
    ballotpedia: "Elizabeth_Joy",
    party: "Republican",
  }, {
    first_name: "Paul",
    last_name: "Tonko",
    ballotpedia: "Paul_Tonko",
    party: "Democratic",
    wikipedia: "/wiki/Paul_Tonko",
  }]
}, {
  state: "NY",
  number: 21,
  candidates: [{
    first_name: "Tedra",
    last_name: "Cobb",
    ballotpedia: "Tedra_Cobb",
    party: "Democratic",
    wikipedia: "/wiki/Tedra_Cobb",
  }, {
    first_name: "Elise",
    last_name: "Stefanik",
    ballotpedia: "Elise_Stefanik",
    party: "Republican",
    wikipedia: "/wiki/Elise_Stefanik",
  }]
}, {
  state: "NY",
  number: 22,
  candidates: [{
    first_name: "Anthony",
    last_name: "Brindisi",
    ballotpedia: "Anthony_Brindisi",
    party: "Democratic",
    wikipedia: "/wiki/Anthony_Brindisi",
  }, {
    first_name: "Keith",
    last_name: "Price",
    ballotpedia: "Keith_Price",
    party: "Libertarian",
  }, {
    first_name: "Claudia",
    last_name: "Tenney",
    ballotpedia: "Claudia_Tenney",
    party: "Republican",
    wikipedia: "/wiki/Claudia_Tenney",
  }]
}, {
  state: "NY",
  number: 23,
  candidates: [{
    first_name: "Andrew",
    last_name: "Kolstee",
    ballotpedia: "Andrew_Kolstee",
    party: "Libertarian",
  }, {
    first_name: "Tracy",
    last_name: "Mitrano",
    ballotpedia: "Tracy_Mitrano",
    party: "Democratic",
  }, {
    first_name: "Tom",
    last_name: "Reed",
    ballotpedia: "Tom_Reed_(New_York)",
    party: "Republican",
    wikipedia: "/wiki/Tom_Reed_(politician)",
  }]
}, {
  state: "NY",
  number: 24,
  candidates: [{
    first_name: "Dana",
    last_name: "Balter",
    ballotpedia: "Dana_Balter",
    party: "Democratic",
  }, {
    first_name: "John",
    last_name: "Katko",
    ballotpedia: "John_Katko",
    party: "Republican",
    wikipedia: "/wiki/John_Katko",
  }, {
    first_name: "Steven",
    last_name: "Williams",
    ballotpedia: "Steven_Williams_(New_York)",
    party: "Working Families",
  }]
}, {
  state: "NY",
  number: 25,
  candidates: [{
    first_name: "George",
    last_name: "Mitris",
    ballotpedia: "George_Mitris",
    party: "Republican",
  }, {
    first_name: "Joseph",
    last_name: "Morelle",
    ballotpedia: "Joseph_Morelle",
    party: "Democratic",
    wikipedia: "/wiki/Joseph_Morelle",
  }, {
    first_name: "Kevin",
    last_name: "Wilson",
    ballotpedia: "Kevin_Wilson_(New_York)",
    party: "Libertarian",
  }]
}, {
  state: "NY",
  number: 26,
  candidates: [{
    first_name: "Ricky",
    last_name: "Donovan",
    ballotpedia: "Ricky_Donovan,_Sr.",
    party: "Republican",
  }, {
    first_name: "Brian",
    last_name: "Higgins",
    ballotpedia: "Brian_Higgins",
    party: "Democratic",
    wikipedia: "/wiki/Brian_Higgins",
  }, {
    first_name: "Mike",
    last_name: "Raleigh",
    ballotpedia: "Mike_Raleigh",
    party: "Green",
  }]
}, {
  state: "NY",
  number: 27,
  candidates: [{
    first_name: "Chris",
    last_name: "Jacobs",
    ballotpedia: "Christopher_Jacobs",
    party: "Republican",
    wikipedia: "/wiki/Chris_Jacobs_(politician)",
  }, {
    first_name: "Nate",
    last_name: "McMurray",
    ballotpedia: "Nate_McMurray",
    party: "Democratic",
  }, {
    first_name: "Duane",
    last_name: "Whitmer",
    ballotpedia: "Duane_Whitmer",
    party: "Libertarian",
  }]
}, {
  state: "NC",
  number: 1,
  candidates: [{
    first_name: "G. K.",
    last_name: "Butterfield",
    ballotpedia: "G._K._Butterfield",
    party: "Democratic",
    wikipedia: "/wiki/G._K._Butterfield",
  }, {
    first_name: "Sandy",
    last_name: "Smith",
    ballotpedia: "Sandy_Smith_(North_Carolina)",
    party: "Republican",
  }]
}, {
  state: "NC",
  number: 2,
  candidates: [{
    first_name: "Jeff",
    last_name: "Matemu",
    ballotpedia: "Jeff_Matemu",
    party: "Libertarian",
  }, {
    first_name: "Deborah",
    last_name: "Ross",
    ballotpedia: "Deborah_Ross",
    party: "Democratic",
    wikipedia: "/wiki/Deborah_K._Ross",
  }, {
    first_name: "Alan",
    last_name: "Swain",
    ballotpedia: "Alan_Swain",
    party: "Republican",
  }]
}, {
  state: "NC",
  number: 3,
  candidates: [{
    first_name: "Daryl T.",
    last_name: "Farrow",
    ballotpedia: "Daryl_Farrow",
    party: "Democratic",
  }, {
    first_name: "Greg",
    last_name: "Murphy",
    ballotpedia: "Greg_Murphy",
    party: "Republican",
    wikipedia: "/wiki/Greg_Murphy_(politician)",
  }]
}, {
  state: "NC",
  number: 4,
  candidates: [{
    first_name: "David",
    last_name: "Price",
    ballotpedia: "David_Price",
    party: "Democratic",
    wikipedia: "/wiki/David_Price_(American_politician)",
  }, {
    first_name: "Robert",
    last_name: "Thomas",
    ballotpedia: "Robert_Thomas_(North_Carolina)",
    party: "Republican",
  }]
}, {
  state: "NC",
  number: 5,
  candidates: [{
    first_name: "David Wilson",
    last_name: "Brown",
    ballotpedia: "David_Wilson_Brown",
    party: "Democratic",
  }, {
    first_name: "VA",
    last_name: "Foxx",
    ballotpedia: "Virginia_Foxx",
    party: "Republican",
    wikipedia: "/wiki/Virginia_Foxx",
  }, {
    first_name: "Jeff",
    last_name: "Gregory",
    ballotpedia: "Jeff_Gregory",
    party: "Constitution",
  }]
}, {
  state: "NC",
  number: 6,
  candidates: [{
    first_name: "Lee",
    last_name: "Haywood",
    ballotpedia: "Lee_Haywood",
    party: "Republican",
  }, {
    first_name: "Kathy",
    last_name: "Manning",
    ballotpedia: "Kathy_Manning",
    party: "Democratic",
  }]
}, {
  state: "NC",
  number: 7,
  candidates: [{
    first_name: "David",
    last_name: "Rouzer",
    ballotpedia: "David_Rouzer",
    party: "Republican",
    wikipedia: "/wiki/David_Rouzer",
  }, {
    first_name: "Chris",
    last_name: "Ward",
    ballotpedia: "Christopher_Ward_(North_Carolina)",
    party: "Democratic",
  }]
}, {
  state: "NC",
  number: 8,
  candidates: [{
    first_name: "Richard",
    last_name: "Hudson",
    ballotpedia: "Richard_Hudson",
    party: "Republican",
    wikipedia: "/wiki/Richard_Hudson_(American_politician)",
  }, {
    first_name: "Patricia",
    last_name: "Timmons-Goodson",
    ballotpedia: "Patricia_Timmons-Goodson",
    party: "Democratic",
    wikipedia: "/wiki/Patricia_Timmons-Goodson",
  }]
}, {
  state: "NC",
  number: 9,
  candidates: [{
    first_name: "Dan",
    last_name: "Bishop",
    ballotpedia: "Dan_Bishop",
    party: "Republican",
    wikipedia: "/wiki/Dan_Bishop",
  }, {
    first_name: "Cynthia",
    last_name: "Wallace",
    ballotpedia: "Cynthia_Wallace",
    party: "Democratic",
  }]
}, {
  state: "NC",
  number: 10,
  candidates: [{
    first_name: "Patrick",
    last_name: "McHenry",
    ballotpedia: "Patrick_McHenry",
    party: "Republican",
    wikipedia: "/wiki/Patrick_McHenry",
  }, {
    first_name: "David",
    last_name: "Parker",
    ballotpedia: "David_Parker_(North_Carolina)",
    party: "Democratic",
    wikipedia: "/wiki/David_Parker_(attorney)",
  }]
}, {
  state: "NC",
  number: 11,
  candidates: [{
    first_name: "Madison",
    last_name: "Cawthorn",
    ballotpedia: "Madison_Cawthorn",
    party: "Republican",
    wikipedia: "/wiki/Madison_Cawthorn",
  }, {
    first_name: "Morris",
    last_name: "Davis",
    ballotpedia: "Morris_Davis",
    party: "Democratic",
    wikipedia: "/wiki/Morris_Davis",
  }, {
    first_name: "Tracey",
    last_name: "DeBruhl",
    ballotpedia: "Tracey_DeBruhl",
    party: "Libertarian",
  }, {
    first_name: "Tamara",
    last_name: "Zwinak",
    ballotpedia: "Tamara_Zwinak",
    party: "Green",
  }]
}, {
  state: "NC",
  number: 12,
  candidates: [{
    first_name: "Alma",
    last_name: "Adams",
    ballotpedia: "Alma_Adams",
    party: "Democratic",
    wikipedia: "/wiki/Alma_Adams",
  }]
}, {
  state: "NC",
  number: 13,
  candidates: [{
    first_name: "Ted",
    last_name: "Budd",
    ballotpedia: "Ted_Budd",
    party: "Republican",
    wikipedia: "/wiki/Ted_Budd",
  }, {
    first_name: "Scott",
    last_name: "Huffman",
    ballotpedia: "Scott_Huffman",
    party: "Democratic",
  }]
}, {
  state: "ND",
  number: 0,
  candidates: [{
    first_name: "Kelly",
    last_name: "Armstrong",
    ballotpedia: "Kelly_Armstrong",
    party: "Republican",
    wikipedia: "/wiki/Kelly_Armstrong",
  }, {
    first_name: "Steven",
    last_name: "Peterson",
    ballotpedia: "Steven_Peterson_(North_Dakota)",
    party: "Libertarian",
  }, {
    first_name: "Zach",
    last_name: "Raknerud",
    ballotpedia: "Zach_Raknerud",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 1,
  candidates: [{
    first_name: "Steve",
    last_name: "Chabot",
    ballotpedia: "Steve_Chabot",
    party: "Republican",
    wikipedia: "/wiki/Steve_Chabot",
  }, {
    first_name: "Kevin",
    last_name: "Kahn",
    ballotpedia: "Kevin_Kahn",
    party: "Libertarian",
  }, {
    first_name: "Kate",
    last_name: "Schroder",
    ballotpedia: "Kate_Schroder",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 2,
  candidates: [{
    first_name: "Jaime",
    last_name: "Castle",
    ballotpedia: "Jaime_Castle",
    party: "Democratic",
  }, {
    first_name: "Brad",
    last_name: "Wenstrup",
    ballotpedia: "Brad_Wenstrup",
    party: "Republican",
    wikipedia: "/wiki/Brad_Wenstrup",
  }]
}, {
  state: "OH",
  number: 3,
  candidates: [{
    first_name: "Joyce",
    last_name: "Beatty",
    ballotpedia: "Joyce_Beatty",
    party: "Democratic",
    wikipedia: "/wiki/Joyce_Beatty",
  }, {
    first_name: "Mark",
    last_name: "Richardson",
    ballotpedia: "Mark_Richardson_(Ohio)",
    party: "Republican",
  }]
}, {
  state: "OH",
  number: 4,
  candidates: [{
    first_name: "Shannon",
    last_name: "Freshour",
    ballotpedia: "Shannon_Freshour",
    party: "Democratic",
  }, {
    first_name: "Jim",
    last_name: "Jordan",
    ballotpedia: "Jim_Jordan_(Ohio)",
    party: "Republican",
    wikipedia: "/wiki/Jim_Jordan_(American_politician)",
  }, {
    first_name: "Steve",
    last_name: "Perkins",
    ballotpedia: "Steve_Perkins",
    party: "Libertarian",
  }]
}, {
  state: "OH",
  number: 5,
  candidates: [{
    first_name: "Bob",
    last_name: "Latta",
    ballotpedia: "Bob_Latta",
    party: "Republican",
    wikipedia: "/wiki/Bob_Latta",
  }, {
    first_name: "Nick",
    last_name: "Rubando",
    ballotpedia: "Nick_Rubando",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 6,
  candidates: [{
    first_name: "Bill",
    last_name: "Johnson",
    ballotpedia: "Bill_Johnson_(Ohio)",
    party: "Republican",
    wikipedia: "/wiki/Bill_Johnson_(Ohio_politician)",
  }, {
    first_name: "Shawna",
    last_name: "Roberts",
    ballotpedia: "Shawna_Roberts",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 7,
  candidates: [{
    first_name: "Bob",
    last_name: "Gibbs",
    ballotpedia: "Bob_Gibbs",
    party: "Republican",
    wikipedia: "/wiki/Bob_Gibbs",
  }, {
    first_name: "Brandon",
    last_name: "Lape",
    ballotpedia: "Brandon_Lape",
    party: "Libertarian",
  }, {
    first_name: "Quentin",
    last_name: "Potter",
    ballotpedia: "Quentin_Potter",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 8,
  candidates: [{
    first_name: "Warren",
    last_name: "Davidson",
    ballotpedia: "Warren_Davidson",
    party: "Republican",
    wikipedia: "/wiki/Warren_Davidson",
  }, {
    first_name: "Vanessa",
    last_name: "Enoch",
    ballotpedia: "Vanessa_Enoch",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 9,
  candidates: [{
    first_name: "Marcy",
    last_name: "Kaptur",
    ballotpedia: "Marcy_Kaptur",
    party: "Democratic",
    wikipedia: "/wiki/Marcy_Kaptur",
  }, {
    first_name: "Rob",
    last_name: "Weber",
    ballotpedia: "Rob_Weber",
    party: "Republican",
  }]
}, {
  state: "OH",
  number: 10,
  candidates: [{
    first_name: "Desiree",
    last_name: "Tims",
    ballotpedia: "Desiree_Tims",
    party: "Democratic",
  }, {
    first_name: "Mike",
    last_name: "Turner",
    ballotpedia: "Mike_Turner_(Ohio)",
    party: "Republican",
    wikipedia: "/wiki/Mike_Turner",
  }]
}, {
  state: "OH",
  number: 11,
  candidates: [{
    first_name: "Marcia",
    last_name: "Fudge",
    ballotpedia: "Marcia_Fudge",
    party: "Democratic",
    wikipedia: "/wiki/Marcia_Fudge",
  }, {
    first_name: "Laverne",
    last_name: "Gore",
    ballotpedia: "Laverne_Gore",
    party: "Republican",
  }]
}, {
  state: "OH",
  number: 12,
  candidates: [{
    first_name: "Troy",
    last_name: "Balderson",
    ballotpedia: "Troy_Balderson",
    party: "Republican",
    wikipedia: "/wiki/Troy_Balderson",
  }, {
    first_name: "Alaina",
    last_name: "Shearer",
    ballotpedia: "Alaina_Shearer",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 13,
  candidates: [{
    first_name: "Michael",
    last_name: "Fricke",
    ballotpedia: "Michael_Fricke",
    party: "Libertarian",
  }, {
    first_name: "Christina",
    last_name: "Hagan",
    ballotpedia: "Christina_Hagan",
    party: "Republican",
    wikipedia: "/wiki/Christina_Hagan",
  }, {
    first_name: "Tim",
    last_name: "Ryan",
    ballotpedia: "Tim_Ryan_(Ohio)",
    party: "Democratic",
    wikipedia: "/wiki/Tim_Ryan_(Ohio_politician)",
  }]
}, {
  state: "OH",
  number: 14,
  candidates: [{
    first_name: "David",
    last_name: "Joyce",
    ballotpedia: "David_Joyce",
    party: "Republican",
    wikipedia: "/wiki/David_Joyce_(politician)",
  }, {
    first_name: "Hillary",
    last_name: "O'Connor Mueri",
    ballotpedia: "Hillary_O%27Connor_Mueri",
    party: "Democratic",
  }]
}, {
  state: "OH",
  number: 15,
  candidates: [{
    first_name: "Joel",
    last_name: "Newby",
    ballotpedia: "Joel_Newby",
    party: "Democratic",
  }, {
    first_name: "Steve",
    last_name: "Stivers",
    ballotpedia: "Steve_Stivers",
    party: "Republican",
    wikipedia: "/wiki/Steve_Stivers",
  }]
}, {
  state: "OH",
  number: 16,
  candidates: [{
    first_name: "Aaron Paul",
    last_name: "Godfrey",
    ballotpedia: "Aaron_Godfrey",
    party: "Democratic",
  }, {
    first_name: "Anthony",
    last_name: "Gonzalez",
    ballotpedia: "Anthony_Gonzalez",
    party: "Republican",
    wikipedia: "/wiki/Anthony_Gonzalez",
  }]
}, {
  state: "OK",
  number: 1,
  candidates: [{
    first_name: "Kojo",
    last_name: "Asamoa-Caesar",
    ballotpedia: "Kojo_Asamoa-Caesar",
    party: "Democratic",
    wikipedia: "/wiki/Kojo_Asamoa-Caesar",
  }, {
    first_name: "Kevin",
    last_name: "Hern",
    ballotpedia: "Kevin_Hern",
    party: "Republican",
    wikipedia: "/wiki/Kevin_Hern",
  }, {
    first_name: "Evelyn",
    last_name: "Rogers",
    ballotpedia: "Evelyn_Rogers",
    party: "Independent",
  }]
}, {
  state: "OK",
  number: 2,
  candidates: [{
    first_name: "Richie",
    last_name: "Castaldo",
    ballotpedia: "Richard_Castaldo",
    party: "Libertarian",
  }, {
    first_name: "Danyell",
    last_name: "Lanier",
    ballotpedia: "Danyell_Lanier",
    party: "Democratic",
  }, {
    first_name: "Markwayne",
    last_name: "Mullin",
    ballotpedia: "Markwayne_Mullin",
    party: "Republican",
    wikipedia: "/wiki/Markwayne_Mullin",
  }]
}, {
  state: "OK",
  number: 3,
  candidates: [{
    first_name: "Frank",
    last_name: "Lucas",
    ballotpedia: "Frank_Lucas",
    party: "Republican",
    wikipedia: "/wiki/Frank_Lucas_(Oklahoma_politician)",
  }, {
    first_name: "Zoe",
    last_name: "Midyett",
    ballotpedia: "Zoe_Ann_Midyett",
    party: "Democratic",
  }]
}, {
  state: "OK",
  number: 4,
  candidates: [{
    first_name: "Mary",
    last_name: "Brannon",
    ballotpedia: "Mary_Brannon",
    party: "Democratic",
  }, {
    first_name: "Tom",
    last_name: "Cole",
    ballotpedia: "Tom_Cole_(Oklahoma)",
    party: "Republican",
    wikipedia: "/wiki/Tom_Cole",
  }, {
    first_name: "Bob",
    last_name: "White",
    ballotpedia: "Bob_White_(Oklahoma)",
    party: "Libertarian",
  }]
}, {
  state: "OK",
  number: 5,
  candidates: [{
    first_name: "Stephanie",
    last_name: "Bice",
    ballotpedia: "Stephanie_Bice",
    party: "Republican",
    wikipedia: "/wiki/Stephanie_Bice",
  }, {
    first_name: "Kendra",
    last_name: "Horn",
    ballotpedia: "Kendra_Horn",
    party: "Democratic",
    wikipedia: "/wiki/Kendra_Horn",
  }]
}, {
  state: "OR",
  number: 1,
  candidates: [{
    first_name: "Suzanne",
    last_name: "Bonamici",
    ballotpedia: "Suzanne_Bonamici",
    party: "Democratic",
    wikipedia: "/wiki/Suzanne_Bonamici",
  }, {
    first_name: "Christopher",
    last_name: "Christensen",
    ballotpedia: "Christopher_Christensen",
    party: "Republican",
  }]
}, {
  state: "OR",
  number: 2,
  candidates: [{
    first_name: "Cliff",
    last_name: "Bentz",
    ballotpedia: "Cliff_Bentz",
    party: "Republican",
    wikipedia: "/wiki/Cliff_Bentz",
  }, {
    first_name: "Alex",
    last_name: "Spenser",
    ballotpedia: "Alex_Spenser",
    party: "Democratic",
  }, {
    first_name: "Robert",
    last_name: "Werch",
    ballotpedia: "Robert_Werch",
    party: "Libertarian",
  }]
}, {
  state: "OR",
  number: 3,
  candidates: [{
    first_name: "Earl",
    last_name: "Blumenauer",
    ballotpedia: "Earl_Blumenauer",
    party: "Democratic",
    wikipedia: "/wiki/Earl_Blumenauer",
  }, {
    first_name: "Alex",
    last_name: "DiBlasi",
    ballotpedia: "Alex_DiBlasi",
    party: "Pacific Green",
  }, {
    first_name: "Joanna",
    last_name: "Harbour",
    ballotpedia: "Joanna_Harbour",
    party: "Republican",
  }, {
    first_name: "Josh",
    last_name: "Solomon",
    ballotpedia: "Josh_Solomon",
    party: "Libertarian",
  }]
}, {
  state: "OR",
  number: 4,
  candidates: [{
    first_name: "Peter",
    last_name: "DeFazio",
    ballotpedia: "Peter_DeFazio",
    party: "Democratic",
    wikipedia: "/wiki/Peter_DeFazio",
  }, {
    first_name: "Daniel",
    last_name: "Hoffay",
    ballotpedia: "Daniel_Hoffay",
    party: "Pacific Green",
  }, {
    first_name: "Alek",
    last_name: "Skarlatos",
    ballotpedia: "Alek_Skarlatos",
    party: "Republican",
    wikipedia: "/wiki/Alek_Skarlatos",
  }]
}, {
  state: "OR",
  number: 5,
  candidates: [{
    first_name: "Amy Ryan",
    last_name: "Courser",
    ballotpedia: "Amy_Ryan_Courser",
    party: "Republican",
  }, {
    first_name: "Matthew",
    last_name: "Rix",
    ballotpedia: "Matthew_Rix",
    party: "Libertarian",
  }, {
    first_name: "Kurt",
    last_name: "Schrader",
    ballotpedia: "Kurt_Schrader",
    party: "Democratic",
    wikipedia: "/wiki/Kurt_Schrader",
  }]
}, {
  state: "PA",
  number: 1,
  candidates: [{
    first_name: "Christina",
    last_name: "Finello",
    ballotpedia: "Christina_Finello",
    party: "Democratic",
  }, {
    first_name: "Brian",
    last_name: "Fitzpatrick",
    ballotpedia: "Brian_Fitzpatrick",
    party: "Republican",
    wikipedia: "/wiki/Brian_Fitzpatrick_(American_politician)",
  }]
}, {
  state: "PA",
  number: 2,
  candidates: [{
    first_name: "Brendan",
    last_name: "Boyle",
    ballotpedia: "Brendan_Boyle",
    party: "Democratic",
    wikipedia: "/wiki/Brendan_Boyle",
  }, {
    first_name: "David",
    last_name: "Torres",
    ballotpedia: "David_Torres_(Pennsylvania)",
    party: "Republican",
  }]
}, {
  state: "PA",
  number: 3,
  candidates: [{
    first_name: "Dwight",
    last_name: "Evans",
    ballotpedia: "Dwight_Evans",
    party: "Democratic",
    wikipedia: "/wiki/Dwight_Evans_(politician)",
  }, {
    first_name: "Michael",
    last_name: "Harvey",
    ballotpedia: "Michael_Harvey_(Pennsylvania)",
    party: "Republican",
  }]
}, {
  state: "PA",
  number: 4,
  candidates: [{
    first_name: "Kathy",
    last_name: "Barnette",
    ballotpedia: "Kathy_Barnette",
    party: "Republican",
  }, {
    first_name: "Madeleine",
    last_name: "Dean",
    ballotpedia: "Madeleine_Dean",
    party: "Democratic",
    wikipedia: "/wiki/Madeleine_Dean",
  }]
}, {
  state: "PA",
  number: 5,
  candidates: [{
    first_name: "Dasha",
    last_name: "Pruett",
    ballotpedia: "Dasha_Pruett",
    party: "Republican",
  }, {
    first_name: "Mary Gay",
    last_name: "Scanlon",
    ballotpedia: "Mary_Gay_Scanlon",
    party: "Democratic",
    wikipedia: "/wiki/Mary_Gay_Scanlon",
  }]
}, {
  state: "PA",
  number: 6,
  candidates: [{
    first_name: "John",
    last_name: "Emmons",
    ballotpedia: "John_Emmons",
    party: "Republican",
  }, {
    first_name: "Chrissy",
    last_name: "Houlahan",
    ballotpedia: "Chrissy_Houlahan",
    party: "Democratic",
    wikipedia: "/wiki/Chrissy_Houlahan",
  }]
}, {
  state: "PA",
  number: 7,
  candidates: [{
    first_name: "Lisa",
    last_name: "Scheller",
    ballotpedia: "Lisa_Scheller",
    party: "Republican",
  }, {
    first_name: "Susan",
    last_name: "Wild",
    ballotpedia: "Susan_Wild",
    party: "Democratic",
    wikipedia: "/wiki/Susan_Wild",
  }]
}, {
  state: "PA",
  number: 8,
  candidates: [{
    first_name: "Jim",
    last_name: "Bognet",
    ballotpedia: "Jim_Bognet",
    party: "Republican",
  }, {
    first_name: "Matt",
    last_name: "Cartwright",
    ballotpedia: "Matt_Cartwright",
    party: "Democratic",
    wikipedia: "/wiki/Matt_Cartwright",
  }]
}, {
  state: "PA",
  number: 9,
  candidates: [{
    first_name: "Dan",
    last_name: "Meuser",
    ballotpedia: "Dan_Meuser",
    party: "Republican",
    wikipedia: "/wiki/Dan_Meuser",
  }, {
    first_name: "Gary",
    last_name: "Wegman",
    ballotpedia: "Gary_Wegman",
    party: "Democratic",
  }]
}, {
  state: "PA",
  number: 10,
  candidates: [{
    first_name: "Eugene",
    last_name: "DePasquale",
    ballotpedia: "Eugene_DePasquale",
    party: "Democratic",
    wikipedia: "/wiki/Eugene_DePasquale",
  }, {
    first_name: "Scott",
    last_name: "Perry",
    ballotpedia: "Scott_Perry",
    party: "Republican",
    wikipedia: "/wiki/Scott_Perry_(politician)",
  }]
}, {
  state: "PA",
  number: 11,
  candidates: [{
    first_name: "Sarah",
    last_name: "Hammond",
    ballotpedia: "Sarah_Hammond",
    party: "Democratic",
  }, {
    first_name: "Lloyd",
    last_name: "Smucker",
    ballotpedia: "Lloyd_Smucker",
    party: "Republican",
    wikipedia: "/wiki/Lloyd_Smucker",
  }]
}, {
  state: "PA",
  number: 12,
  candidates: [{
    first_name: "Lee",
    last_name: "Griffin",
    ballotpedia: "Lee_Griffin",
    party: "Democratic",
  }, {
    first_name: "Fred",
    last_name: "Keller",
    ballotpedia: "Fred_Keller",
    party: "Republican",
    wikipedia: "/wiki/Fred_Keller_(politician)",
  }]
}, {
  state: "PA",
  number: 13,
  candidates: [{
    first_name: "John",
    last_name: "Joyce",
    ballotpedia: "John_Joyce_(Pennsylvania)",
    party: "Republican",
    wikipedia: "/wiki/John_Joyce_(American_politician)",
  }, {
    first_name: "Todd",
    last_name: "Rowley",
    ballotpedia: "Todd_Rowley",
    party: "Democratic",
  }]
}, {
  state: "PA",
  number: 14,
  candidates: [{
    first_name: "Bill",
    last_name: "Marx",
    ballotpedia: "William_Marx",
    party: "Democratic",
  }, {
    first_name: "Guy",
    last_name: "Reschenthaler",
    ballotpedia: "Guy_Reschenthaler",
    party: "Republican",
    wikipedia: "/wiki/Guy_Reschenthaler",
  }]
}, {
  state: "PA",
  number: 15,
  candidates: [{
    first_name: "Glenn",
    last_name: "Thompson",
    ballotpedia: "Glenn_Thompson_(Pennsylvania)",
    party: "Republican",
    wikipedia: "/wiki/Glenn_Thompson_(politician)",
  }, {
    first_name: "Robert",
    last_name: "Williams",
    ballotpedia: "Robert_M._Williams_(Pennsylvania_congressional_candidate)",
    party: "Democratic",
  }]
}, {
  state: "PA",
  number: 16,
  candidates: [{
    first_name: "Kristy",
    last_name: "Gnibus",
    ballotpedia: "Kristy_Gnibus",
    party: "Democratic",
  }, {
    first_name: "Mike",
    last_name: "Kelly",
    ballotpedia: "Mike_Kelly_(Pennsylvania)",
    party: "Republican",
    wikipedia: "/wiki/Mike_Kelly_(Pennsylvania_politician)",
  }]
}, {
  state: "PA",
  number: 17,
  candidates: [{
    first_name: "Conor",
    last_name: "Lamb",
    ballotpedia: "Conor_Lamb",
    party: "Democratic",
    wikipedia: "/wiki/Conor_Lamb",
  }, {
    first_name: "Sean",
    last_name: "Parnell",
    ballotpedia: "Sean_Parnell_(Pennsylvania)",
    party: "Republican",
  }]
}, {
  state: "PA",
  number: 18,
  candidates: [{
    first_name: "Mike",
    last_name: "Doyle",
    ballotpedia: "Mike_Doyle_(Pennsylvania)",
    party: "Democratic",
    wikipedia: "/wiki/Michael_F._Doyle",
  }, {
    first_name: "Luke",
    last_name: "Negron",
    ballotpedia: "Luke_Negron",
    party: "Republican",
  }]
}, {
  state: "RI",
  number: 1,
  candidates: [{
    first_name: "David",
    last_name: "Cicilline",
    ballotpedia: "David_Cicilline",
    party: "Democratic",
    wikipedia: "/wiki/David_Cicilline",
  }, {
    first_name: "Jeffrey",
    last_name: "Lemire",
    ballotpedia: "Jeffrey_Lemire",
    party: "Independent",
  }, {
    first_name: "Frederick",
    last_name: "Wysocki",
    ballotpedia: "Frederick_Wysocki",
    party: "Independent",
  }]
}, {
  state: "RI",
  number: 2,
  candidates: [{
    first_name: "Jim",
    last_name: "Langevin",
    ballotpedia: "Jim_Langevin",
    party: "Democratic",
    wikipedia: "/wiki/James_Langevin",
  }, {
    first_name: "Robert",
    last_name: "Lancia",
    ballotpedia: "Robert_Lancia",
    party: "Republican",
  }]
}, {
  state: "SC",
  number: 1,
  candidates: [{
    first_name: "Joe",
    last_name: "Cunningham",
    ballotpedia: "Joe_Cunningham",
    party: "Democratic",
    wikipedia: "/wiki/Joe_Cunningham_(American_politician)",
  }, {
    first_name: "Nancy",
    last_name: "Mace",
    ballotpedia: "Nancy_Mace",
    party: "Republican",
    wikipedia: "/wiki/Nancy_Mace",
  }]
}, {
  state: "SC",
  number: 2,
  candidates: [{
    first_name: "Adair",
    last_name: "Boroughs",
    ballotpedia: "Adair_Ford_Boroughs",
    party: "Democratic",
  }, {
    first_name: "Sonny",
    last_name: "Narang",
    ballotpedia: "Sonny_Narang",
    party: "American",
  }, {
    first_name: "Joe",
    last_name: "Wilson",
    ballotpedia: "Joe_Wilson_(South_Carolina)",
    party: "Republican",
    wikipedia: "/wiki/Joe_Wilson_(U.S._politician)",
  }, {
    first_name: "Kathleen",
    last_name: "Wright",
    ballotpedia: "Kathleen_Wright",
    party: "Constitution",
  }]
}, {
  state: "SC",
  number: 3,
  candidates: [{
    first_name: "Hosea",
    last_name: "Cleveland",
    ballotpedia: "Hosea_Cleveland",
    party: "Democratic",
  }, {
    first_name: "Jeff",
    last_name: "Duncan",
    ballotpedia: "Jeff_Duncan_(South_Carolina)",
    party: "Republican",
    wikipedia: "/wiki/Jeff_Duncan_(politician)",
  }]
}, {
  state: "SC",
  number: 4,
  candidates: [{
    first_name: "Michael",
    last_name: "Chandler",
    ballotpedia: "Michael_Chandler_(South_Carolina)",
    party: "Constitution",
  }, {
    first_name: "Kim",
    last_name: "Nelson",
    ballotpedia: "Kim_Nelson",
    party: "Democratic",
  }, {
    first_name: "William",
    last_name: "Timmons",
    ballotpedia: "William_Timmons",
    party: "Republican",
    wikipedia: "/wiki/William_Timmons",
  }]
}, {
  state: "SC",
  number: 5,
  candidates: [{
    first_name: "Moe",
    last_name: "Brown",
    ballotpedia: "Moe_Brown",
    party: "Democratic",
  }, {
    first_name: "Ralph",
    last_name: "Norman",
    ballotpedia: "Ralph_Norman",
    party: "Republican",
    wikipedia: "/wiki/Ralph_Norman",
  }]
}, {
  state: "SC",
  number: 6,
  candidates: [{
    first_name: "Jim",
    last_name: "Clyburn",
    ballotpedia: "Jim_Clyburn",
    party: "Democratic",
    wikipedia: "/wiki/Jim_Clyburn",
  }, {
    first_name: "Mark",
    last_name: "Hackett",
    ballotpedia: "Mark_Hackett",
    party: "Constitution",
  }, {
    first_name: "John",
    last_name: "McCollum",
    ballotpedia: "John_McCollum",
    party: "Republican",
  }]
}, {
  state: "SC",
  number: 7,
  candidates: [{
    first_name: "Tom",
    last_name: "Rice",
    ballotpedia: "Tom_Rice_(South_Carolina)",
    party: "Republican",
    wikipedia: "/wiki/Tom_Rice",
  }, {
    first_name: "Melissa",
    last_name: "Watson",
    ballotpedia: "Melissa_Watson",
    party: "Democratic",
  }]
}, {
  state: "SD",
  number: 0,
  candidates: [{
    first_name: "Dusty",
    last_name: "Johnson",
    ballotpedia: "Dusty_Johnson",
    party: "Republican",
    wikipedia: "/wiki/Dusty_Johnson",
  }, {
    first_name: "Randy",
    last_name: "Luallin",
    ballotpedia: "Randy_Luallin",
    party: "Libertarian",
  }]
}, {
  state: "TN",
  number: 1,
  candidates: [{
    first_name: "Diana",
    last_name: "Harshbarger",
    ballotpedia: "Diana_Harshbarger",
    party: "Republican",
  }, {
    first_name: "Steve",
    last_name: "Holder",
    ballotpedia: "Steve_Holder",
    party: "Independent",
  }, {
    first_name: "Blair",
    last_name: "Walsingham",
    ballotpedia: "Blair_Walsingham",
    party: "Democratic",
  }]
}, {
  state: "TN",
  number: 2,
  candidates: [{
    first_name: "Tim",
    last_name: "Burchett",
    ballotpedia: "Tim_Burchett",
    party: "Republican",
    wikipedia: "/wiki/Tim_Burchett",
  }, {
    first_name: "Matthew",
    last_name: "Campbell",
    ballotpedia: "Matthew_Campbell",
    party: "Independent",
  }, {
    first_name: "Renee",
    last_name: "Hoyos",
    ballotpedia: "Renee_Hoyos",
    party: "Democratic",
    wikipedia: "/wiki/Renee_Hoyos",
  }]
}, {
  state: "TN",
  number: 3,
  candidates: [{
    first_name: "Chuck",
    last_name: "Fleischmann",
    ballotpedia: "Chuck_Fleischmann",
    party: "Republican",
    wikipedia: "/wiki/Chuck_Fleischmann",
  }, {
    first_name: "Meg",
    last_name: "Gorman",
    ballotpedia: "Meg_Gorman",
    party: "Democratic",
  }, {
    first_name: "Amber",
    last_name: "Hysell",
    ballotpedia: "Amber_Hysell",
    party: "Independent",
  }, {
    first_name: "Keith",
    last_name: "Sweitzer",
    ballotpedia: "Keith_Douglas_Sweitzer",
    party: "Independent",
  }]
}, {
  state: "TN",
  number: 4,
  candidates: [{
    first_name: "Scott",
    last_name: "DesJarlais",
    ballotpedia: "Scott_DesJarlais",
    party: "Republican",
    wikipedia: "/wiki/Scott_DesJarlais",
  }, {
    first_name: "Christopher",
    last_name: "Hale",
    ballotpedia: "Christopher_Hale",
    party: "Democratic",
  }]
}, {
  state: "TN",
  number: 5,
  candidates: [{
    first_name: "Jim",
    last_name: "Cooper",
    ballotpedia: "Jim_Cooper_(Tennessee)",
    party: "Democratic",
    wikipedia: "/wiki/Jim_Cooper",
  }]
}, {
  state: "TN",
  number: 6,
  candidates: [{
    first_name: "Christopher",
    last_name: "Finley",
    ballotpedia: "Christopher_Finley",
    party: "Democratic",
  }, {
    first_name: "Christopher",
    last_name: "Monday",
    ballotpedia: "Christopher_Monday",
    party: "Independent",
  }, {
    first_name: "John",
    last_name: "Rose",
    ballotpedia: "John_Rose_(Tennessee)",
    party: "Republican",
    wikipedia: "/wiki/John_W._Rose",
  }]
}, {
  state: "TN",
  number: 7,
  candidates: [{
    first_name: "Ronald",
    last_name: "Brown",
    ballotpedia: "Ronald_Brown_(Tennessee)",
    party: "Independent",
  }, {
    first_name: "Mark",
    last_name: "Green",
    ballotpedia: "Mark_Green_(Tennessee)",
    party: "Republican",
    wikipedia: "/wiki/Mark_E._Green",
  }, {
    first_name: "Kiran",
    last_name: "Sreepada",
    ballotpedia: "Kiran_Sreepada",
    party: "Democratic",
  }, {
    first_name: "Scott ",
    last_name: "Vieira",
    ballotpedia: "Scott_Vieira",
    party: "Independent",
  }]
}, {
  state: "TN",
  number: 8,
  candidates: [{
    first_name: "Jon",
    last_name: "Dillard",
    ballotpedia: "Jon_Dillard",
    party: "Independent",
  }, {
    first_name: "James",
    last_name: "Hart",
    ballotpedia: "James_Hart_(Tennessee)",
    party: "Independent",
  }, {
    first_name: "David",
    last_name: "Kustoff",
    ballotpedia: "David_Kustoff",
    party: "Republican",
    wikipedia: "/wiki/David_Kustoff",
  }, {
    first_name: "Erika Stotts",
    last_name: "Pearson",
    ballotpedia: "Erika_Stotts_Pearson",
    party: "Democratic",
  }]
}, {
  state: "TN",
  number: 9,
  candidates: [{
    first_name: "Charlotte",
    last_name: "Bergmann",
    ballotpedia: "Charlotte_Bergmann",
    party: "Republican",
  }, {
    first_name: "Dennis",
    last_name: "Clark",
    ballotpedia: "Dennis_Clark_(Tennessee_congressional_candidate)",
    party: "Independent",
  }, {
    first_name: "Steve",
    last_name: "Cohen",
    ballotpedia: "Steve_Cohen",
    party: "Democratic",
    wikipedia: "/wiki/Steve_Cohen",
  }, {
    first_name: "Bobby",
    last_name: "Lyons",
    ballotpedia: "Bobby_Lyons",
    party: "Independent",
  }]
}, {
  state: "TX",
  number: 1,
  candidates: [{
    first_name: "Hank",
    last_name: "Gilbert",
    ballotpedia: "Hank_Gilbert",
    party: "Democratic",
  }, {
    first_name: "Louie",
    last_name: "Gohmert",
    ballotpedia: "Louie_Gohmert",
    party: "Republican",
    wikipedia: "/wiki/Louie_Gohmert",
  }]
}, {
  state: "TX",
  number: 2,
  candidates: [{
    first_name: "Dan",
    last_name: "Crenshaw",
    ballotpedia: "Daniel_Crenshaw",
    party: "Republican",
    wikipedia: "/wiki/Dan_Crenshaw",
  }, {
    first_name: "Sima",
    last_name: "Ladjevardian",
    ballotpedia: "Sima_Ladjevardian",
    party: "Democratic",
  }, {
    first_name: "Elliott",
    last_name: "Scheirman",
    ballotpedia: "Elliott_Scheirman",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 3,
  candidates: [{
    first_name: "Lulu",
    last_name: "Seikaly",
    ballotpedia: "Lulu_Seikaly",
    party: "Democratic",
  }, {
    first_name: "Van",
    last_name: "Taylor",
    ballotpedia: "Van_Taylor",
    party: "Republican",
    wikipedia: "/wiki/Van_Taylor",
  }]
}, {
  state: "TX",
  number: 4,
  candidates: [{
    first_name: "Lou",
    last_name: "Antonelli",
    ballotpedia: "Lou_Antonelli",
    party: "Libertarian",
  }, {
    first_name: "Pat",
    last_name: "Fallon",
    ballotpedia: "Pat_Fallon",
    party: "Republican",
    wikipedia: "/wiki/Pat_Fallon",
  }, {
    first_name: "Russell",
    last_name: "Foster",
    ballotpedia: "Russell_Foster",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 5,
  candidates: [{
    first_name: "Lance",
    last_name: "Gooden",
    ballotpedia: "Lance_Gooden",
    party: "Republican",
    wikipedia: "/wiki/Lance_Gooden",
  }, {
    first_name: "Kevin",
    last_name: "Hale",
    ballotpedia: "Kevin_Hale",
    party: "Libertarian",
  }, {
    first_name: "Carolyn",
    last_name: "Salter",
    ballotpedia: "Carolyn_Salter",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 6,
  candidates: [{
    first_name: "Melanie",
    last_name: "Black",
    ballotpedia: "Melanie_Black",
    party: "Libertarian",
  }, {
    first_name: "Stephen",
    last_name: "Daniel",
    ballotpedia: "Stephen_Daniel",
    party: "Democratic",
  }, {
    first_name: "Ron",
    last_name: "Wright",
    ballotpedia: "Ronald_Wright",
    party: "Republican",
    wikipedia: "/wiki/Ron_Wright_(politician)",
  }]
}, {
  state: "TX",
  number: 7,
  candidates: [{
    first_name: "Lizzie",
    last_name: "Fletcher",
    ballotpedia: "Lizzie_Pannill_Fletcher",
    party: "Democratic",
    wikipedia: "/wiki/Lizzie_Fletcher",
  }, {
    first_name: "Wesley",
    last_name: "Hunt",
    ballotpedia: "Wesley_Hunt_(Congressional_candidate,_Texas)",
    party: "Republican",
  }, {
    first_name: "Shawn",
    last_name: "Kelly",
    ballotpedia: "Shawn_Kelly_(Texas)",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 8,
  candidates: [{
    first_name: "Kevin",
    last_name: "Brady",
    ballotpedia: "Kevin_Brady_(Texas)",
    party: "Republican",
    wikipedia: "/wiki/Kevin_Brady",
  }, {
    first_name: "Chris",
    last_name: "Duncan",
    ballotpedia: "Chris_Duncan_(Texas)",
    party: "Libertarian",
  }, {
    first_name: "Elizabeth",
    last_name: "Hernandez",
    ballotpedia: "Elizabeth_Hernandez_(Texas)",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 9,
  candidates: [{
    first_name: "Al",
    last_name: "Green",
    ballotpedia: "Al_Green_(Texas)",
    party: "Democratic",
    wikipedia: "/wiki/Al_Green_(politician)",
  }, {
    first_name: "José",
    last_name: "Sosa",
    ballotpedia: "Jose_Sosa",
    party: "Libertarian",
  }, {
    first_name: "Johnny",
    last_name: "Teague",
    ballotpedia: "Johnny_Teague",
    party: "Republican",
  }]
}, {
  state: "TX",
  number: 10,
  candidates: [{
    first_name: "Roy",
    last_name: "Eriksen",
    ballotpedia: "Roy_Eriksen",
    party: "Libertarian",
  }, {
    first_name: "Michael",
    last_name: "McCaul",
    ballotpedia: "Michael_McCaul",
    party: "Republican",
    wikipedia: "/wiki/Michael_McCaul",
  }, {
    first_name: "Mike",
    last_name: "Siegel",
    ballotpedia: "Mike_Siegel",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 11,
  candidates: [{
    first_name: "Wacey Alpha",
    last_name: "Cody",
    ballotpedia: "Wacey_Alpha_Cody",
    party: "Libertarian",
  }, {
    first_name: "Jon Mark",
    last_name: "Hogg",
    ballotpedia: "Jon_Mark_Hogg",
    party: "Democratic",
  }, {
    first_name: "August",
    last_name: "Pfluger",
    ballotpedia: "August_Pfluger",
    party: "Republican",
  }]
}, {
  state: "TX",
  number: 12,
  candidates: [{
    first_name: "Kay",
    last_name: "Granger",
    ballotpedia: "Kay_Granger",
    party: "Republican",
    wikipedia: "/wiki/Kay_Granger",
  }, {
    first_name: "Trey",
    last_name: "Holcomb",
    ballotpedia: "Trey_Holcomb",
    party: "Libertarian",
  }, {
    first_name: "Lisa",
    last_name: "Welch",
    ballotpedia: "Lisa_Welch",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 13,
  candidates: [{
    first_name: "Ronny",
    last_name: "Jackson",
    ballotpedia: "Ronny_Jackson",
    party: "Republican",
    wikipedia: "/wiki/Ronny_Jackson",
  }, {
    first_name: "Gus",
    last_name: "Trujillo",
    ballotpedia: "Gus_Trujillo",
    party: "Democratic",
  }, {
    first_name: "Jack",
    last_name: "Westbrook",
    ballotpedia: "Jack_Westbrook",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 14,
  candidates: [{
    first_name: "Adrienne",
    last_name: "Bell",
    ballotpedia: "Adrienne_Bell",
    party: "Democratic",
  }, {
    first_name: "Randy",
    last_name: "Weber",
    ballotpedia: "Randy_Weber",
    party: "Republican",
    wikipedia: "/wiki/Randy_Weber",
  }]
}, {
  state: "TX",
  number: 15,
  candidates: [{
    first_name: "Monica",
    last_name: "De La Cruz-Hernandez",
    ballotpedia: "Monica_De_La_Cruz_Hernandez",
    party: "Republican",
  }, {
    first_name: "Vicente",
    last_name: "González",
    ballotpedia: "Vicente_González",
    party: "Democratic",
    wikipedia: "/wiki/Vicente_Gonzalez_(politician)",
  }, {
    first_name: "Ross Lynn",
    last_name: "Leone",
    ballotpedia: "Ross_Lynn_Leone",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 16,
  candidates: [{
    first_name: "Irene",
    last_name: "Armendariz-Jackson",
    ballotpedia: "Irene_Armendariz-Jackson",
    party: "Republican",
  }, {
    first_name: "Veronica",
    last_name: "Escobar",
    ballotpedia: "Veronica_Escobar",
    party: "Democratic",
    wikipedia: "/wiki/Veronica_Escobar",
  }]
}, {
  state: "TX",
  number: 17,
  candidates: [{
    first_name: "Ted",
    last_name: "Brown",
    ballotpedia: "Ted_Brown_(Texas)",
    party: "Libertarian",
  }, {
    first_name: "Rick",
    last_name: "Kennedy",
    ballotpedia: "Rick_Kennedy",
    party: "Democratic",
  }, {
    first_name: "Pete",
    last_name: "Sessions",
    ballotpedia: "Pete_Sessions",
    party: "Republican",
    wikipedia: "/wiki/Pete_Sessions",
  }]
}, {
  state: "TX",
  number: 18,
  candidates: [{
    first_name: "Wendell",
    last_name: "Champion",
    ballotpedia: "Wendell_Champion",
    party: "Republican",
  }, {
    first_name: "Vince",
    last_name: "Duncan",
    ballotpedia: "Vince_Duncan",
    party: "Independent",
  }, {
    first_name: "Sheila",
    last_name: "Jackson Lee",
    ballotpedia: "Sheila_Jackson_Lee",
    party: "Democratic",
    wikipedia: "/wiki/Sheila_Jackson_Lee",
  }, {
    first_name: "Luke",
    last_name: "Spencer",
    ballotpedia: "Luke_Spencer",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 19,
  candidates: [{
    first_name: "Jodey",
    last_name: "Arrington",
    ballotpedia: "Jodey_Arrington",
    party: "Republican",
    wikipedia: "/wiki/Jodey_Arrington",
  }, {
    first_name: "Joe",
    last_name: "Burnes",
    ballotpedia: "Joe_Burnes",
    party: "Libertarian",
  }, {
    first_name: "Tom",
    last_name: "Watson",
    ballotpedia: "Tom_Watson_(Texas_congressional_candidate)",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 20,
  candidates: [{
    first_name: "Jeffrey",
    last_name: "Blunt",
    ballotpedia: "Jeffrey_Blunt",
    party: "Libertarian",
  }, {
    first_name: "Joaquin",
    last_name: "Castro",
    ballotpedia: "Joaquin_Castro",
    party: "Democratic",
    wikipedia: "/wiki/Joaquin_Castro",
  }, {
    first_name: "Mauro",
    last_name: "Garza",
    ballotpedia: "Mauro_Garza",
    party: "Republican",
  }]
}, {
  state: "TX",
  number: 21,
  candidates: [{
    first_name: "Wendy",
    last_name: "Davis",
    ballotpedia: "Wendy_Davis_(Texas)",
    party: "Democratic",
    wikipedia: "/wiki/Wendy_Davis_(politician)",
  }, {
    first_name: "Arthur",
    last_name: "DiBianca",
    ballotpedia: "Arthur_DiBianca",
    party: "Libertarian",
  }, {
    first_name: "Chip",
    last_name: "Roy",
    ballotpedia: "Chip_Roy",
    party: "Republican",
    wikipedia: "/wiki/Chip_Roy",
  }, {
    first_name: "Tom",
    last_name: "Wakely",
    ballotpedia: "Thomas_Wakely",
    party: "Green",
  }]
}, {
  state: "TX",
  number: 22,
  candidates: [{
    first_name: "Sri Preston",
    last_name: "Kulkarni",
    ballotpedia: "Sri_Preston_Kulkarni",
    party: "Democratic",
    wikipedia: "/wiki/Sri_Preston_Kulkarni",
  }, {
    first_name: "Joseph ",
    last_name: "LeBlanc",
    ballotpedia: "Joseph_LeBlanc_(Texas)",
    party: "Libertarian",
  }, {
    first_name: "Troy",
    last_name: "Nehls",
    ballotpedia: "Troy_Nehls",
    party: "Republican",
    wikipedia: "/wiki/Troy_Nehls",
  }]
}, {
  state: "TX",
  number: 23,
  candidates: [{
    first_name: "Tony",
    last_name: "Gonzales",
    ballotpedia: "Tony_Gonzales",
    party: "Republican",
  }, {
    first_name: "Gina",
    last_name: "Ortiz Jones",
    ballotpedia: "Gina_Ortiz_Jones",
    party: "Democratic",
    wikipedia: "/wiki/Gina_Ortiz_Jones",
  }, {
    first_name: "Beto",
    last_name: "Villela",
    ballotpedia: "Beto_Villela",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 24,
  candidates: [{
    first_name: "Mark",
    last_name: "Bauer",
    ballotpedia: "Mark_Bauer",
    party: "Independent",
  }, {
    first_name: "Darren",
    last_name: "Hamilton",
    ballotpedia: "Darren_Hamilton",
    party: "Libertarian",
  }, {
    first_name: "Steve",
    last_name: "Kuzmich",
    ballotpedia: "Steve_Kuzmich",
    party: "Independent",
  }, {
    first_name: "Candace",
    last_name: "Valenzuela",
    ballotpedia: "Candace_Valenzuela",
    party: "Democratic",
  }, {
    first_name: "Beth",
    last_name: "Van Duyne",
    ballotpedia: "Beth_Van_Duyne",
    party: "Republican",
    wikipedia: "/wiki/Beth_Van_Duyne",
  }]
}, {
  state: "TX",
  number: 25,
  candidates: [{
    first_name: "Bill",
    last_name: "Kelsey",
    ballotpedia: "Bill_Kelsey",
    party: "Libertarian",
  }, {
    first_name: "Julie",
    last_name: "Oliver",
    ballotpedia: "Julie_Oliver",
    party: "Democratic",
  }, {
    first_name: "Roger",
    last_name: "Williams",
    ballotpedia: "Roger_Williams_(Texas)",
    party: "Republican",
    wikipedia: "/wiki/Roger_Williams_(American_politician)",
  }]
}, {
  state: "TX",
  number: 26,
  candidates: [{
    first_name: "Michael",
    last_name: "Burgess",
    ballotpedia: "Michael_Burgess",
    party: "Republican",
    wikipedia: "/wiki/Michael_C._Burgess",
  }, {
    first_name: "Carol",
    last_name: "Iannuzzi",
    ballotpedia: "Carol_Iannuzzi",
    party: "Democratic",
  }]
}, {
  state: "TX",
  number: 27,
  candidates: [{
    first_name: "Michael",
    last_name: "Cloud",
    ballotpedia: "Michael_Cloud_(Texas)",
    party: "Republican",
    wikipedia: "/wiki/Michael_Cloud",
  }, {
    first_name: "Ricardo",
    last_name: "de la Fuente",
    ballotpedia: "Ricardo_De_La_Fuente",
    party: "Democratic",
  }, {
    first_name: "Phil",
    last_name: "Gray",
    ballotpedia: "Phil_Gray",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 28,
  candidates: [{
    first_name: "Bekah",
    last_name: "Congdon",
    ballotpedia: "Bekah_Congdon",
    party: "Libertarian",
  }, {
    first_name: "Henry",
    last_name: "Cuellar",
    ballotpedia: "Henry_Cuellar",
    party: "Democratic",
    wikipedia: "/wiki/Henry_Cuellar",
  }, {
    first_name: "Sandra",
    last_name: "Whitten",
    ballotpedia: "Sandra_Whitten",
    party: "Republican",
  }]
}, {
  state: "TX",
  number: 29,
  candidates: [{
    first_name: "Jaimy",
    last_name: "Blanco",
    ballotpedia: "Jaimy_Blanco",
    party: "Republican",
  }, {
    first_name: "Sylvia",
    last_name: "Garcia",
    ballotpedia: "Sylvia_Garcia",
    party: "Democratic",
    wikipedia: "/wiki/Sylvia_Garcia",
  }, {
    first_name: "Phil",
    last_name: "Kurtz",
    ballotpedia: "Phil_Kurtz",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 30,
  candidates: [{
    first_name: "Eddie Bernice",
    last_name: "Johnson",
    ballotpedia: "Eddie_Bernice_Johnson",
    party: "Democratic",
    wikipedia: "/wiki/Eddie_Bernice_Johnson",
  }, {
    first_name: "Tre",
    last_name: "Pennie",
    ballotpedia: "Tre_Pennie",
    party: "Republican",
  }, {
    first_name: "Eric",
    last_name: "Williams",
    ballotpedia: "Eric_Williams_(Texas)",
    party: "Independent",
  }]
}, {
  state: "TX",
  number: 31,
  candidates: [{
    first_name: "John",
    last_name: "Carter",
    ballotpedia: "John_Carter_(Texas)",
    party: "Republican",
    wikipedia: "/wiki/John_Carter_(Texas_politician)",
  }, {
    first_name: "Donna",
    last_name: "Imam",
    ballotpedia: "Donna_Imam",
    party: "Democratic",
  }, {
    first_name: "Clark",
    last_name: "Patterson",
    ballotpedia: "Clark_Patterson",
    party: "Libertarian",
  }]
}, {
  state: "TX",
  number: 32,
  candidates: [{
    first_name: "Colin",
    last_name: "Allred",
    ballotpedia: "Colin_Allred",
    party: "Democratic",
    wikipedia: "/wiki/Colin_Allred",
  }, {
    first_name: "Genevieve",
    last_name: "Collins",
    ballotpedia: "Genevieve_Collins",
    party: "Republican",
  }, {
    first_name: "Christy",
    last_name: "Mowrey",
    ballotpedia: "Christy_Mowrey",
    party: "Libertarian",
  }, {
    first_name: "Jason",
    last_name: "Sigmon",
    ballotpedia: "Jason_Sigmon",
    party: "Independent",
  }]
}, {
  state: "TX",
  number: 33,
  candidates: [{
    first_name: "Carlos",
    last_name: "Quintanilla",
    ballotpedia: "Carlos_Quintanilla_(Texas)",
    party: "Independent",
  }, {
    first_name: "Jason",
    last_name: "Reeves",
    ballotpedia: "Jason_Reeves_(Texas)",
    party: "Libertarian",
  }, {
    first_name: "Fabian",
    last_name: "Vasquez",
    ballotpedia: "Fabian_Vasquez",
    party: "Republican",
  }, {
    first_name: "Marc",
    last_name: "Veasey",
    ballotpedia: "Marc_Veasey",
    party: "Democratic",
    wikipedia: "/wiki/Marc_Veasey",
  }, {
    first_name: "Renedria",
    last_name: "Welton",
    ballotpedia: "Renedria_Welton",
    party: "Independent",
  }]
}, {
  state: "TX",
  number: 34,
  candidates: [{
    first_name: "Anthony",
    last_name: "Cristo",
    ballotpedia: "Anthony_Cristo",
    party: "Libertarian",
  }, {
    first_name: "Rey",
    last_name: "Gonzalez",
    ballotpedia: "Rey_Gonzalez",
    party: "Republican",
  }, {
    first_name: "Chris",
    last_name: "Royal",
    ballotpedia: "Chris_Royal",
    party: "Independent",
  }, {
    first_name: "Filemon",
    last_name: "Vela",
    suffix: "Jr.",
    ballotpedia: "Filemon_Vela_(Texas_U.S._representative)",
    party: "Democratic",
    wikipedia: "/wiki/Filemon_Vela_Jr.",
  }]
}, {
  state: "TX",
  number: 35,
  candidates: [{
    first_name: "Lloyd",
    last_name: "Doggett",
    ballotpedia: "Lloyd_Doggett",
    party: "Democratic",
    wikipedia: "/wiki/Lloyd_Doggett",
  }, {
    first_name: "Mark",
    last_name: "Loewe",
    ballotpedia: "Mark_Loewe",
    party: "Libertarian",
  }, {
    first_name: "Jason",
    last_name: "Mata",
    ballotpedia: "Jason_Mata",
    party: "Independent",
  }, {
    first_name: "Jenny",
    last_name: "Garcia Sharon",
    ballotpedia: "Jenny_Garcia_Sharon",
    party: "Republican",
  }]
}, {
  state: "TX",
  number: 36,
  candidates: [{
    first_name: "Chad",
    last_name: "Abbey",
    ballotpedia: "Chad_Abbey",
    party: "Libertarian",
  }, {
    first_name: "Brian",
    last_name: "Babin",
    ballotpedia: "Brian_Babin",
    party: "Republican",
    wikipedia: "/wiki/Brian_Babin",
  }, {
    first_name: "Rashad",
    last_name: "Lewis",
    ballotpedia: "Rashad_Lewis",
    party: "Democratic",
  }, {
    first_name: "Hal ",
    last_name: "Ridley",
    suffix: "Jr.",
    ballotpedia: "Hal_Ridley_Jr.",
    party: "Green",
  }]
}, {
  state: "UT",
  number: 1,
  candidates: [{
    first_name: "Blake",
    last_name: "Moore",
    ballotpedia: "Blake_Moore",
    party: "Republican",
  }, {
    first_name: "Darren",
    last_name: "Parry",
    ballotpedia: "Darren_Parry",
    party: "Democratic",
  }]
}, {
  state: "UT",
  number: 2,
  candidates: [{
    first_name: "J. Robert",
    last_name: "Latham",
    ballotpedia: "J._Robert_Latham",
    party: "Libertarian",
  }, {
    first_name: "Chris",
    last_name: "Stewart",
    ballotpedia: "Chris_Stewart_(Utah)",
    party: "Republican",
    wikipedia: "/wiki/Chris_Stewart_(politician)",
  }, {
    first_name: "Kael",
    last_name: "Weston",
    ballotpedia: "Kael_Weston",
    party: "Democratic",
    wikipedia: "/wiki/Kael_Weston",
  }]
}, {
  state: "UT",
  number: 3,
  candidates: [{
    first_name: "Dan",
    last_name: "Cummings",
    ballotpedia: "Daniel_Clyde_Cummings",
    party: "Constitution",
  }, {
    first_name: "John",
    last_name: "Curtis",
    ballotpedia: "John_Curtis_(Utah)",
    party: "Republican",
    wikipedia: "/wiki/John_Curtis_(Utah_politician)",
  }, {
    first_name: "Thomas",
    last_name: "McNeill",
    ballotpedia: "Thomas_McNeill",
    party: "United Utah",
  }, {
    first_name: "Devin",
    last_name: "Thorpe",
    ballotpedia: "Devin_Thorpe",
    party: "Democratic",
  }]
}, {
  state: "UT",
  number: 4,
  candidates: [{
    first_name: "Jonia",
    last_name: "Broderick",
    ballotpedia: "Jonia_Broderick",
    party: "United Utah",
  }, {
    first_name: "Ben",
    last_name: "McAdams",
    ballotpedia: "Ben_McAdams",
    party: "Democratic",
    wikipedia: "/wiki/Ben_McAdams",
  }, {
    first_name: "John",
    last_name: "Molnar",
    ballotpedia: "John_Molnar",
    party: "Libertarian",
  }, {
    first_name: "Burgess",
    last_name: "Owens",
    ballotpedia: "Burgess_Owens",
    party: "Republican",
    wikipedia: "/wiki/Burgess_Owens",
  }]
}, {
  state: "VT",
  number: 0,
  candidates: [{
    first_name: "Peter",
    last_name: "Becker",
    party: "Independent",
  }, {
    first_name: "Miriam",
    last_name: "Berry",
    ballotpedia: "Miriam_Berry",
    party: "Republican",
  }, {
    first_name: "Christopher",
    last_name: "Helali",
    party: "Communist",
  }, {
    first_name: "Marcia",
    last_name: "Horne",
    ballotpedia: "Marcia_Horne",
    party: "Independent",
  }, {
    first_name: "Shawn",
    last_name: "Orr",
    party: "Independent",
  }, {
    first_name: "Jerry",
    last_name: "Trudell",
    ballotpedia: "Jerry_Trudell",
    party: "Independent",
  }, {
    first_name: "Peter",
    last_name: "Welch",
    ballotpedia: "Peter_Welch",
    party: "Democratic",
    wikipedia: "/wiki/Peter_Welch",
  }]
}, {
  state: "VA",
  number: 1,
  candidates: [{
    first_name: "Qasim",
    last_name: "Rashid",
    ballotpedia: "Qasim_Rashid",
    party: "Democratic",
    wikipedia: "/wiki/Qasim_Rashid",
  }, {
    first_name: "Rob",
    last_name: "Wittman",
    ballotpedia: "Rob_Wittman",
    party: "Republican",
    wikipedia: "/wiki/Rob_Wittman",
  }]
}, {
  state: "VA",
  number: 2,
  candidates: [{
    first_name: "David",
    last_name: "Foster",
    ballotpedia: "David_B._Foster_(Virginia_congressional_candidate)",
    party: "Independent",
  }, {
    first_name: "Elaine",
    last_name: "Luria",
    ballotpedia: "Elaine_Luria",
    party: "Democratic",
    wikipedia: "/wiki/Elaine_Luria",
  }, {
    first_name: "Scott",
    last_name: "Taylor",
    ballotpedia: "Scott_Taylor_(Virginia)",
    party: "Republican",
    wikipedia: "/wiki/Scott_Taylor_(politician)",
  }]
}, {
  state: "VA",
  number: 3,
  candidates: [{
    first_name: "John",
    last_name: "Collick",
    ballotpedia: "John_Collick",
    party: "Republican",
  }, {
    first_name: "Bobby",
    last_name: "Scott",
    ballotpedia: "Bobby_Scott_(Virginia)",
    party: "Democratic",
    wikipedia: "/wiki/Bobby_Scott_(politician)",
  }]
}, {
  state: "VA",
  number: 4,
  candidates: [{
    first_name: "Leon",
    last_name: "Benjamin",
    ballotpedia: "Leon_Benjamin",
    party: "Republican",
  }, {
    first_name: "Donald",
    last_name: "McEachin",
    ballotpedia: "Donald_McEachin",
    party: "Democratic",
    wikipedia: "/wiki/Donald_McEachin",
  }]
}, {
  state: "VA",
  number: 5,
  candidates: [{
    first_name: "Bob",
    last_name: "Good",
    ballotpedia: "Bob_Good",
    party: "Republican",
    wikipedia: "/wiki/Bob_Good",
  }, {
    first_name: "Cameron",
    last_name: "Webb",
    ballotpedia: "Cameron_Webb",
    party: "Democratic",
  }]
}, {
  state: "VA",
  number: 6,
  candidates: [{
    first_name: "Nicholas",
    last_name: "Betts",
    ballotpedia: "Nicholas_Betts",
    party: "Democratic",
  }, {
    first_name: "Ben",
    last_name: "Cline",
    ballotpedia: "Ben_Cline",
    party: "Republican",
    wikipedia: "/wiki/Ben_Cline",
  }]
}, {
  state: "VA",
  number: 7,
  candidates: [{
    first_name: "Nick",
    last_name: "Freitas",
    ballotpedia: "Nick_Freitas",
    party: "Republican",
    wikipedia: "/wiki/Nick_Freitas",
  }, {
    first_name: "Abigail",
    last_name: "Spanberger",
    ballotpedia: "Abigail_Spanberger",
    party: "Democratic",
    wikipedia: "/wiki/Abigail_Spanberger",
  }]
}, {
  state: "VA",
  number: 8,
  candidates: [{
    first_name: "Don",
    last_name: "Beyer",
    ballotpedia: "Don_Beyer",
    party: "Democratic",
    wikipedia: "/wiki/Don_Beyer",
  }, {
    first_name: "Jeff",
    last_name: "Jordan",
    ballotpedia: "Jeff_Jordan_(Virginia)",
    party: "Republican",
  }]
}, {
  state: "VA",
  number: 9,
  candidates: [{
    first_name: "Morgan",
    last_name: "Griffith",
    ballotpedia: "Morgan_Griffith",
    party: "Republican",
    wikipedia: "/wiki/Morgan_Griffith",
  }]
}, {
  state: "VA",
  number: 10,
  candidates: [{
    first_name: "Aliscia",
    last_name: "Andrews",
    ballotpedia: "Aliscia_Andrews",
    party: "Republican",
  }, {
    first_name: "Jennifer",
    last_name: "Wexton",
    ballotpedia: "Jennifer_Wexton",
    party: "Democratic",
    wikipedia: "/wiki/Jennifer_Wexton",
  }]
}, {
  state: "VA",
  number: 11,
  candidates: [{
    first_name: "Manga",
    last_name: "Anantatmula",
    ballotpedia: "Manga_Anantatmula",
    party: "Republican",
  }, {
    first_name: "Gerry",
    last_name: "Connolly",
    ballotpedia: "Gerry_Connolly",
    party: "Democratic",
    wikipedia: "/wiki/Gerry_Connolly",
  }]
}, {
  state: "WA",
  number: 1,
  candidates: [{
    first_name: "Jeffrey",
    last_name: "Beeler",
    ballotpedia: "Jeffrey_Beeler",
    party: "Republican",
  }, {
    first_name: "Suzan",
    last_name: "DelBene",
    ballotpedia: "Suzan_DelBene",
    party: "Democratic",
    wikipedia: "/wiki/Suzan_DelBene",
  }]
}, {
  state: "WA",
  number: 2,
  candidates: [{
    first_name: "Tim",
    last_name: "Hazelo",
    ballotpedia: "Timothy_Hazelo",
    party: "Republican",
  }, {
    first_name: "Rick",
    last_name: "Larsen",
    ballotpedia: "Rick_Larsen",
    party: "Democratic",
    wikipedia: "/wiki/Rick_Larsen",
  }]
}, {
  state: "WA",
  number: 3,
  candidates: [{
    first_name: "Jaime Herrera",
    last_name: "Beutler",
    ballotpedia: "Jaime_Herrera_Beutler",
    party: "Republican",
    wikipedia: "/wiki/Jaime_Herrera_Beutler",
  }, {
    first_name: "Carolyn",
    last_name: "Long",
    ballotpedia: "Carolyn_Long",
    party: "Democratic",
  }]
}, {
  state: "WA",
  number: 4,
  candidates: [{
    first_name: "Doug",
    last_name: "McKinley",
    ballotpedia: "Doug_McKinley",
    party: "Democratic",
  }, {
    first_name: "Dan",
    last_name: "Newhouse",
    ballotpedia: "Dan_Newhouse",
    party: "Republican",
    wikipedia: "/wiki/Dan_Newhouse",
  }]
}, {
  state: "WA",
  number: 5,
  candidates: [{
    first_name: "Cathy McMorris",
    last_name: "Rodgers",
    ballotpedia: "Cathy_McMorris_Rodgers",
    party: "Republican",
    wikipedia: "/wiki/Cathy_McMorris_Rodgers",
  }, {
    first_name: "Dave",
    last_name: "Wilson",
    ballotpedia: "Dave_Wilson_(Washington)",
    party: "Democratic",
  }]
}, {
  state: "WA",
  number: 6,
  candidates: [{
    first_name: "Derek",
    last_name: "Kilmer",
    ballotpedia: "Derek_Kilmer",
    party: "Democratic",
    wikipedia: "/wiki/Derek_Kilmer",
  }, {
    first_name: "Elizabeth",
    last_name: "Kreiselmaier",
    ballotpedia: "Elizabeth_Kreiselmaier",
    party: "Republican",
  }]
}, {
  state: "WA",
  number: 7,
  candidates: [{
    first_name: "Pramila",
    last_name: "Jayapal",
    ballotpedia: "Pramila_Jayapal",
    party: "Democratic",
    wikipedia: "/wiki/Pramila_Jayapal",
  }, {
    first_name: "Craig",
    last_name: "Keller",
    ballotpedia: "Craig_Keller",
    party: "Republican",
  }]
}, {
  state: "WA",
  number: 8,
  candidates: [{
    first_name: "Jesse",
    last_name: "Jensen",
    ballotpedia: "Jesse_Jensen",
    party: "Republican",
  }, {
    first_name: "Kim",
    last_name: "Schrier",
    ballotpedia: "Kim_Schrier",
    party: "Democratic",
    wikipedia: "/wiki/Kim_Schrier",
  }]
}, {
  state: "WA",
  number: 9,
  candidates: [{
    first_name: "Doug",
    last_name: "Basler",
    ballotpedia: "Doug_Basler",
    party: "Republican",
  }, {
    first_name: "Adam",
    last_name: "Smith",
    ballotpedia: "Adam_Smith_(Washington)",
    party: "Democratic",
    wikipedia: "/wiki/Adam_Smith_(politician)",
  }]
}, {
  state: "WA",
  number: 10,
  candidates: [{
    first_name: "Beth",
    last_name: "Doglio",
    ballotpedia: "Beth_Doglio",
    party: "Democratic",
    wikipedia: "/wiki/Beth_Doglio",
  }, {
    first_name: "Marilyn",
    last_name: "Strickland",
    ballotpedia: "Marilyn_Strickland",
    party: "Democratic",
    wikipedia: "/wiki/Marilyn_Strickland",
  }]
}, {
  state: "WV",
  number: 1,
  candidates: [{
    first_name: "Natalie",
    last_name: "Cline",
    ballotpedia: "Natalie_Cline_(West_Virginia)",
    party: "Democratic",
  }, {
    first_name: "David",
    last_name: "McKinley",
    ballotpedia: "David_McKinley",
    party: "Republican",
    wikipedia: "/wiki/David_McKinley",
  }]
}, {
  state: "WV",
  number: 2,
  candidates: [{
    first_name: "Cathy",
    last_name: "Kunkel",
    ballotpedia: "Cathy_Kunkel",
    party: "Democratic",
  }, {
    first_name: "Alex",
    last_name: "Mooney",
    ballotpedia: "Alex_Mooney",
    party: "Republican",
    wikipedia: "/wiki/Alex_Mooney",
  }]
}, {
  state: "WV",
  number: 3,
  candidates: [{
    first_name: "Carol",
    last_name: "Miller",
    ballotpedia: "Carol_Miller_(West_Virginia)",
    party: "Republican",
    wikipedia: "/wiki/Carol_Miller_(politician)",
  }, {
    first_name: "Hilary",
    last_name: "Turner",
    ballotpedia: "Hilary_Turner",
    party: "Democratic",
  }]
}, {
  state: "WI",
  number: 1,
  candidates: [{
    first_name: "Roger",
    last_name: "Polack",
    ballotpedia: "Roger_Polack",
    party: "Democratic",
  }, {
    first_name: "Bryan",
    last_name: "Steil",
    ballotpedia: "Bryan_Steil",
    party: "Republican",
    wikipedia: "/wiki/Bryan_Steil",
  }]
}, {
  state: "WI",
  number: 2,
  candidates: [{
    first_name: "Mark",
    last_name: "Pocan",
    ballotpedia: "Mark_Pocan",
    party: "Democratic",
    wikipedia: "/wiki/Mark_Pocan",
  }, {
    first_name: "Peter",
    last_name: "Theron",
    ballotpedia: "Peter_Theron",
    party: "Republican",
  }]
}, {
  state: "WI",
  number: 3,
  candidates: [{
    first_name: "Ron",
    last_name: "Kind",
    ballotpedia: "Ron_Kind",
    party: "Democratic",
    wikipedia: "/wiki/Ron_Kind",
  }, {
    first_name: "Derrick",
    last_name: "Van Orden",
    ballotpedia: "Derrick_Van_Orden",
    party: "Republican",
  }]
}, {
  state: "WI",
  number: 4,
  candidates: [{
    first_name: "Gwen",
    last_name: "Moore",
    ballotpedia: "Gwen_Moore",
    party: "Democratic",
    wikipedia: "/wiki/Gwen_Moore",
  }, {
    first_name: "Robert",
    last_name: "Raymond",
    ballotpedia: "Robert_Raymond",
    party: "Independent",
  }, {
    first_name: "Tim",
    last_name: "Rogers",
    ballotpedia: "Tim_Rogers_(Wisconsin)",
    party: "Republican",
  }]
}, {
  state: "WI",
  number: 5,
  candidates: [{
    first_name: "Scott",
    last_name: "Fitzgerald",
    ballotpedia: "Scott_Fitzgerald",
    party: "Republican",
    wikipedia: "/wiki/Scott_L._Fitzgerald",
  }, {
    first_name: "Tom",
    last_name: "Palzewicz",
    ballotpedia: "Tom_Palzewicz",
    party: "Democratic",
  }]
}, {
  state: "WI",
  number: 6,
  candidates: [{
    first_name: "Glenn",
    last_name: "Grothman",
    ballotpedia: "Glenn_Grothman",
    party: "Republican",
    wikipedia: "/wiki/Glenn_Grothman",
  }, {
    first_name: "Jessica",
    last_name: "King",
    ballotpedia: "Jessica_King_(Wisconsin)",
    party: "Democratic",
    wikipedia: "/wiki/Jessica_King",
  }]
}, {
  state: "WI",
  number: 7,
  candidates: [{
    first_name: "Tom",
    last_name: "Tiffany",
    ballotpedia: "Tom_Tiffany",
    party: "Republican",
    wikipedia: "/wiki/Tom_Tiffany",
  }, {
    first_name: "Tricia",
    last_name: "Zunker",
    ballotpedia: "Tricia_Zunker",
    party: "Democratic",
  }]
}, {
  state: "WI",
  number: 8,
  candidates: [{
    first_name: "Mike",
    last_name: "Gallagher",
    ballotpedia: "Michael_Gallagher_(Wisconsin)",
    party: "Republican",
    wikipedia: "/wiki/Mike_Gallagher_(American_politician)",
  }, {
    first_name: "Amanda",
    last_name: "Stuck",
    ballotpedia: "Amanda_Stuck",
    party: "Democratic",
    wikipedia: "/wiki/Amanda_Stuck",
  }]
}, {
  state: "WY",
  number: 0,
  candidates: [{
    first_name: "Richard",
    last_name: "Brubaker",
    ballotpedia: "Richard_Brubaker",
    party: "Libertarian",
  }, {
    first_name: "Liz",
    last_name: "Cheney",
    ballotpedia: "Liz_Cheney",
    party: "Republican",
    wikipedia: "/wiki/Liz_Cheney",
  }, {
    first_name: "Lynnette Grey",
    last_name: "Bull",
    ballotpedia: "Lynnette_Grey_Bull",
    party: "Democratic",
  }, {
    first_name: "Jeff",
    last_name: "Haggit",
    ballotpedia: "Jeff_Haggit",
    party: "Constitution",
  }]
}, {
  state: "AS",
  number: 0,
  candidates: [{
    first_name: "Oreta Tufuga Mapu",
    last_name: "Crichton",
    party: "Democratic",
  }, {
    first_name: "Amata Coleman",
    last_name: "Radewagen",
    ballotpedia: "Amata_Coleman_Radewagen",
    wikipedia: "/wiki/Amata_Coleman_Radewagen",
  }, {
    first_name: "Meleagi",
    last_name: "Suitonu-Chapman",
    ballotpedia: "Meleagi_Suitonu-Chapman",
    party: "Democratic",
  }]
}, {
  state: "DC",
  number: 0,
  candidates: [{
    first_name: "John",
    last_name: "Cheeks",
    ballotpedia: "John_Cheeks",
    party: "Independent",
  }, {
    first_name: "David",
    last_name: "Krucoff",
    ballotpedia: "David_Krucoff",
    party: "Independent",
  }, {
    first_name: "Amir",
    last_name: "Lowery",
    ballotpedia: "Amir_Lowery",
    party: "Independent",
  }, {
    first_name: "Omari",
    last_name: "Musa",
    ballotpedia: "Omari_Musa",
    party: "Independent",
  }, {
    first_name: "Eleanor Holmes",
    last_name: "Norton",
    ballotpedia: "Eleanor_Holmes_Norton",
    wikipedia: "/wiki/Eleanor_Holmes_Norton",
  }, {
    first_name: "Lino",
    last_name: "Stracuzzi",
    ballotpedia: "Natale_Stracuzzi",
    party: "DC Statehood Green",
  }, {
    first_name: "Barbara Washington",
    last_name: "Franklin",
    ballotpedia: "Barbara_Washington_Franklin",
    party: "Independent",
  }]
}, {
  state: "GU",
  number: 0,
  candidates: [{
    first_name: "Wil",
    last_name: "Castro",
    ballotpedia: "William_Castro",
    party: "Republican",
  }, {
    first_name: "Michael F. Q.",
    last_name: "San Nicolas",
    ballotpedia: "Michael_F.Q._San_Nicolas",
    wikipedia: "/wiki/Michael_San_Nicolas",
  }, {
    first_name: "Robert",
    last_name: "Underwood",
    ballotpedia: "Robert_Underwood_(Guam)",
    wikipedia: "/wiki/Robert_A._Underwood",
  }]
}, {
  state: "MP",
  number: 0,
  candidates: [{
    first_name: "Gregorio Kilili Camacho",
    last_name: "Sablan",
    ballotpedia: "Gregorio_Sablan",
    wikipedia: "/wiki/Gregorio_Sablan",
  }]
}, {
  state: "PR",
  number: 0,
  candidates: [{
    first_name: "Aníbal",
    last_name: "Acevedo Vilá",
    ballotpedia: "Aníbal_Acevedo_Vilá",
    wikipedia: "/wiki/An%C3%ADbal_Acevedo_Vil%C3%A1",
  }, {
    first_name: "Juan Carlos",
    last_name: "Albors",
    ballotpedia: "Juan_Carlos_Albors",
    party: "Independent",
  }, {
    first_name: "Jenniffer",
    last_name: "González",
    ballotpedia: "Jenniffer_González",
    wikipedia: "/wiki/Jenniffer_Gonz%C3%A1lez",
  }, {
    first_name: "Ada Norah",
    last_name: "Henriquez",
    ballotpedia: "Ada_Norah_Henriquez",
    party: "Dignity Project",
  }, {
    first_name: "Zayira",
    last_name: "Jordán Conde",
    ballotpedia: "Zayira_Jordan",
    party: "Citizen's Victory Movement",
  }, {
    first_name: "Luis Roberto",
    last_name: "Piñero",
    ballotpedia: "Luis_Roberto_Piñero",
    party: "Puerto Rico Independence",
  }]
}, {
  state: "VI",
  number: 0,
  candidates: [{
    first_name: "Shekema",
    last_name: "George",
    ballotpedia: "Shekema_George",
    party: "Independent",
  }, {
    first_name: "Stacey",
    last_name: "Plaskett",
    ballotpedia: "Stacey_Plaskett",
    wikipedia: "/wiki/Stacey_Plaskett",
  }]
}];
