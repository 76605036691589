import React from "react";
import { Link } from "react-router-dom";
import {stateCodeToName} from './StateInflectors';

function CandidatesHeader(props) {
  const {chamber, state, office, district, information} = props;
  const readableDistrict =
    (district === 0 ? 'At-Large District' : (district ? 'District ' + district : ''));

  function resolveSenateLink(location) {
    const {pathname} = location;

    if (pathname === '/us/president' || pathname === '/us/house') {
      return '/us/senate';
    } else {
      const stateCode = pathname.split('/')[3];
      return '/us/senate/' + stateCode;
    }
  }

  function resolveHouseLink(location) {
    const {pathname} = location;

    if (pathname === '/us/president' || pathname === '/us/senate') {
      return '/us/house';
    } else {
      const stateCode = pathname.split('/')[3];
      return '/us/house/' + stateCode;
    }
  }

  function OfficeLink(props) {
    const {title, path} = props;
    const active = (path === office);
    const link = path === 'president' ? '/us/president' :
          (path === 'senate' ? resolveSenateLink : resolveHouseLink);

    return(
      <li className={active ? 'active' : null}>
        {active ? (title + ' ') : <Link to={link}>{title} </Link>}
      </li>
    );
  }

  const stateMarkup = state ? <span className="state">{stateCodeToName(state)}</span> : '';
  const districtMarkup = readableDistrict ? <span className="district">{readableDistrict}</span> : '';

  return (
    <div className="candidates-header">
      <ul className="menu">
        <OfficeLink title="US President" path="president" />
        <OfficeLink title="US Senate" path="senate" />
        <OfficeLink title="US House of Representatives" path="house" />
      </ul>
      {state ? <p>{stateMarkup}{districtMarkup}</p>: ''}
      {information ? <p className="information">{information}</p> : ''}
    </div>
  );
}

export default CandidatesHeader;
