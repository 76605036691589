import React from "react";
import Button from './Button';
import { Link } from "react-router-dom";
import USMap from './USMap';
import CandidateCarousel from './CandidateCarousel';

function LandingPage(props) {
  return (
    <div className="landing-page">
      <USMap />
          <div className="inset">

            <div className="lead-in">
              You probably know who you are voting for.
            </div>

            <CandidateCarousel />

            <div className="main-content">
              <p className="headline">
                Who do you actually want to win?
              </p>
              <p className="lead-out">

              </p>

              <div className="actions">
                <Button to="/poll">Take the Poll</Button>
                <br /><br />
                <Link className="skip-poll" to="/us/president">Go To Candidates</Link>
              </div>

              <div className="explanation">
                <p>
                  TopVote.us contrasts voting choices with voters&rsquo; real expressed
                  preferences to provide a unique perspective on our nation&rsquo;s electorate.
                </p>

                <p>
                  Who would you really vote for if you thought they could win? Put another
                  way, who would be your first choice if we had ranked-choice voting?
                </p>

                <p>
                  TopVote.us has parterned up with VeriPoll.net to help confirm that real voters
                  are the ones participating in these surveys. By adding in demographic weighting,
                  we are able to generate high-quality polling results from opt-in
                  poll data. At present, our survey covers all federal election for November 2020.
                </p>
              </div>
            </div>

          </div>
    </div>
  );
}

export default LandingPage;
