const fakeHouseData = [
  {state: "AK", number:  0, winner: "D"},
  {state: "AL", number:  1, winner: "G"},
  {state: "AL", number:  2, winner: "L"},
  {state: "AL", number:  3, winner: "R"},
  {state: "AL", number:  4, winner: null},
  {state: "AL", number:  5, winner: "D"},
  {state: "AL", number:  6, winner: "G"},
  {state: "AL", number:  7, winner: "L"},
  {state: "AR", number:  1, winner: "R"},
  {state: "AR", number:  2, winner: null},
  {state: "AR", number:  3, winner: "D"},
  {state: "AR", number:  4, winner: "G"},
  {state: "AZ", number:  1, winner: "L"},
  {state: "AZ", number:  2, winner: "R"},
  {state: "AZ", number:  3, winner: null},
  {state: "AZ", number:  4, winner: "D"},
  {state: "AZ", number:  5, winner: "G"},
  {state: "AZ", number:  6, winner: "L"},
  {state: "AZ", number:  7, winner: "R"},
  {state: "AZ", number:  8, winner: null},
  {state: "AZ", number:  9, winner: "D"},
  {state: "CA", number:  1, winner: "G"},
  {state: "CA", number:  2, winner: "L"},
  {state: "CA", number:  3, winner: "R"},
  {state: "CA", number:  4, winner: null},
  {state: "CA", number:  5, winner: "D"},
  {state: "CA", number:  6, winner: "G"},
  {state: "CA", number:  7, winner: "L"},
  {state: "CA", number:  8, winner: "R"},
  {state: "CA", number:  9, winner: null},
  {state: "CA", number: 10, winner: "D"},
  {state: "CA", number: 11, winner: "G"},
  {state: "CA", number: 12, winner: "L"},
  {state: "CA", number: 13, winner: "R"},
  {state: "CA", number: 14, winner: null},
  {state: "CA", number: 15, winner: "D"},
  {state: "CA", number: 16, winner: "G"},
  {state: "CA", number: 17, winner: "L"},
  {state: "CA", number: 18, winner: "R"},
  {state: "CA", number: 19, winner: null},
  {state: "CA", number: 20, winner: "D"},
  {state: "CA", number: 21, winner: "G"},
  {state: "CA", number: 22, winner: "L"},
  {state: "CA", number: 23, winner: "R"},
  {state: "CA", number: 24, winner: null},
  {state: "CA", number: 25, winner: "D"},
  {state: "CA", number: 26, winner: "G"},
  {state: "CA", number: 27, winner: "L"},
  {state: "CA", number: 28, winner: "R"},
  {state: "CA", number: 29, winner: null},
  {state: "CA", number: 30, winner: "D"},
  {state: "CA", number: 31, winner: "G"},
  {state: "CA", number: 32, winner: "L"},
  {state: "CA", number: 33, winner: "R"},
  {state: "CA", number: 34, winner: null},
  {state: "CA", number: 35, winner: "D"},
  {state: "CA", number: 36, winner: "G"},
  {state: "CA", number: 37, winner: "L"},
  {state: "CA", number: 38, winner: "R"},
  {state: "CA", number: 39, winner: null},
  {state: "CA", number: 40, winner: "D"},
  {state: "CA", number: 41, winner: "G"},
  {state: "CA", number: 42, winner: "L"},
  {state: "CA", number: 43, winner: "R"},
  {state: "CA", number: 44, winner: null},
  {state: "CA", number: 45, winner: "D"},
  {state: "CA", number: 46, winner: "G"},
  {state: "CA", number: 47, winner: "L"},
  {state: "CA", number: 48, winner: "R"},
  {state: "CA", number: 49, winner: null},
  {state: "CA", number: 50, winner: "D"},
  {state: "CA", number: 51, winner: "G"},
  {state: "CA", number: 52, winner: "L"},
  {state: "CA", number: 53, winner: "R"},
  {state: "CO", number:  1, winner: null},
  {state: "CO", number:  2, winner: "D"},
  {state: "CO", number:  3, winner: "G"},
  {state: "CO", number:  4, winner: "L"},
  {state: "CO", number:  5, winner: "R"},
  {state: "CO", number:  6, winner: null},
  {state: "CO", number:  7, winner: "D"},
  {state: "CT", number:  1, winner: "G"},
  {state: "CT", number:  2, winner: "L"},
  {state: "CT", number:  3, winner: "R"},
  {state: "CT", number:  4, winner: null},
  {state: "CT", number:  5, winner: "D"},
  {state: "DE", number:  0, winner: "G"},
  {state: "FL", number:  1, winner: "L"},
  {state: "FL", number:  2, winner: "R"},
  {state: "FL", number:  3, winner: null},
  {state: "FL", number:  4, winner: "D"},
  {state: "FL", number:  5, winner: "G"},
  {state: "FL", number:  6, winner: "L"},
  {state: "FL", number:  7, winner: "R"},
  {state: "FL", number:  8, winner: null},
  {state: "FL", number:  9, winner: "D"},
  {state: "FL", number: 10, winner: "G"},
  {state: "FL", number: 11, winner: "L"},
  {state: "FL", number: 12, winner: "R"},
  {state: "FL", number: 13, winner: null},
  {state: "FL", number: 14, winner: "D"},
  {state: "FL", number: 15, winner: "G"},
  {state: "FL", number: 16, winner: "L"},
  {state: "FL", number: 17, winner: "R"},
  {state: "FL", number: 18, winner: null},
  {state: "FL", number: 19, winner: "D"},
  {state: "FL", number: 20, winner: "G"},
  {state: "FL", number: 21, winner: "L"},
  {state: "FL", number: 22, winner: "R"},
  {state: "FL", number: 23, winner: null},
  {state: "FL", number: 24, winner: "D"},
  {state: "FL", number: 25, winner: "G"},
  {state: "FL", number: 26, winner: "L"},
  {state: "FL", number: 27, winner: "R"},
  {state: "GA", number:  1, winner: null},
  {state: "GA", number:  2, winner: "D"},
  {state: "GA", number:  3, winner: "G"},
  {state: "GA", number:  4, winner: "L"},
  {state: "GA", number:  5, winner: "R"},
  {state: "GA", number:  6, winner: null},
  {state: "GA", number:  7, winner: "D"},
  {state: "GA", number:  8, winner: "G"},
  {state: "GA", number:  9, winner: "L"},
  {state: "GA", number: 10, winner: "R"},
  {state: "GA", number: 11, winner: null},
  {state: "GA", number: 12, winner: "D"},
  {state: "GA", number: 13, winner: "G"},
  {state: "GA", number: 14, winner: "L"},
  {state: "HI", number:  1, winner: "R"},
  {state: "HI", number:  2, winner: null},
  {state: "IA", number:  1, winner: "D"},
  {state: "IA", number:  2, winner: "G"},
  {state: "IA", number:  3, winner: "L"},
  {state: "IA", number:  4, winner: "R"},
  {state: "ID", number:  1, winner: null},
  {state: "ID", number:  2, winner: "D"},
  {state: "IL", number:  1, winner: "G"},
  {state: "IL", number:  2, winner: "L"},
  {state: "IL", number:  3, winner: "R"},
  {state: "IL", number:  4, winner: null},
  {state: "IL", number:  5, winner: "D"},
  {state: "IL", number:  6, winner: "G"},
  {state: "IL", number:  7, winner: "L"},
  {state: "IL", number:  8, winner: "R"},
  {state: "IL", number:  9, winner: null},
  {state: "IL", number: 10, winner: "D"},
  {state: "IL", number: 11, winner: "G"},
  {state: "IL", number: 12, winner: "L"},
  {state: "IL", number: 13, winner: "R"},
  {state: "IL", number: 14, winner: null},
  {state: "IL", number: 15, winner: "D"},
  {state: "IL", number: 16, winner: "G"},
  {state: "IL", number: 17, winner: "L"},
  {state: "IL", number: 18, winner: "R"},
  {state: "IN", number:  1, winner: null},
  {state: "IN", number:  2, winner: "D"},
  {state: "IN", number:  3, winner: "G"},
  {state: "IN", number:  4, winner: "L"},
  {state: "IN", number:  5, winner: "R"},
  {state: "IN", number:  6, winner: null},
  {state: "IN", number:  7, winner: "D"},
  {state: "IN", number:  8, winner: "G"},
  {state: "IN", number:  9, winner: "L"},
  {state: "KS", number:  1, winner: "R"},
  {state: "KS", number:  2, winner: null},
  {state: "KS", number:  3, winner: "D"},
  {state: "KS", number:  4, winner: "G"},
  {state: "KY", number:  1, winner: "L"},
  {state: "KY", number:  2, winner: "R"},
  {state: "KY", number:  3, winner: null},
  {state: "KY", number:  4, winner: "D"},
  {state: "KY", number:  5, winner: "G"},
  {state: "KY", number:  6, winner: "L"},
  {state: "LA", number:  1, winner: "R"},
  {state: "LA", number:  2, winner: null},
  {state: "LA", number:  3, winner: "D"},
  {state: "LA", number:  4, winner: "G"},
  {state: "LA", number:  5, winner: "L"},
  {state: "LA", number:  6, winner: "R"},
  {state: "MA", number:  1, winner: null},
  {state: "MA", number:  2, winner: "D"},
  {state: "MA", number:  3, winner: "G"},
  {state: "MA", number:  4, winner: "L"},
  {state: "MA", number:  5, winner: "R"},
  {state: "MA", number:  6, winner: null},
  {state: "MA", number:  7, winner: "D"},
  {state: "MA", number:  8, winner: "G"},
  {state: "MA", number:  9, winner: "L"},
  {state: "MD", number:  1, winner: "R"},
  {state: "MD", number:  2, winner: null},
  {state: "MD", number:  3, winner: "D"},
  {state: "MD", number:  4, winner: "G"},
  {state: "MD", number:  5, winner: "L"},
  {state: "MD", number:  6, winner: "R"},
  {state: "MD", number:  7, winner: null},
  {state: "MD", number:  8, winner: "D"},
  {state: "ME", number:  1, winner: "G"},
  {state: "ME", number:  2, winner: "L"},
  {state: "MI", number:  1, winner: "R"},
  {state: "MI", number:  2, winner: null},
  {state: "MI", number:  3, winner: "D"},
  {state: "MI", number:  4, winner: "G"},
  {state: "MI", number:  5, winner: "L"},
  {state: "MI", number:  6, winner: "R"},
  {state: "MI", number:  7, winner: null},
  {state: "MI", number:  8, winner: "D"},
  {state: "MI", number:  9, winner: "G"},
  {state: "MI", number: 10, winner: "L"},
  {state: "MI", number: 11, winner: "R"},
  {state: "MI", number: 12, winner: null},
  {state: "MI", number: 13, winner: "D"},
  {state: "MI", number: 14, winner: "G"},
  {state: "MN", number:  1, winner: "L"},
  {state: "MN", number:  2, winner: "R"},
  {state: "MN", number:  3, winner: null},
  {state: "MN", number:  4, winner: "D"},
  {state: "MN", number:  5, winner: "G"},
  {state: "MN", number:  6, winner: "L"},
  {state: "MN", number:  7, winner: "R"},
  {state: "MN", number:  8, winner: null},
  {state: "MO", number:  1, winner: "D"},
  {state: "MO", number:  2, winner: "G"},
  {state: "MO", number:  3, winner: "L"},
  {state: "MO", number:  4, winner: "R"},
  {state: "MO", number:  5, winner: null},
  {state: "MO", number:  6, winner: "D"},
  {state: "MO", number:  7, winner: "G"},
  {state: "MO", number:  8, winner: "L"},
  {state: "MS", number:  1, winner: "R"},
  {state: "MS", number:  2, winner: null},
  {state: "MS", number:  3, winner: "D"},
  {state: "MS", number:  4, winner: "G"},
  {state: "MT", number:  0, winner: "L"},
  {state: "NC", number:  1, winner: "R"},
  {state: "NC", number:  2, winner: null},
  {state: "NC", number:  3, winner: "D"},
  {state: "NC", number:  4, winner: "G"},
  {state: "NC", number:  5, winner: "L"},
  {state: "NC", number:  6, winner: "R"},
  {state: "NC", number:  7, winner: null},
  {state: "NC", number:  8, winner: "D"},
  {state: "NC", number:  9, winner: "G"},
  {state: "NC", number: 10, winner: "L"},
  {state: "NC", number: 11, winner: "R"},
  {state: "NC", number: 12, winner: null},
  {state: "NC", number: 13, winner: "D"},
  {state: "ND", number:  0, winner: "G"},
  {state: "NE", number:  1, winner: "L"},
  {state: "NE", number:  2, winner: "R"},
  {state: "NE", number:  3, winner: null},
  {state: "NH", number:  1, winner: "D"},
  {state: "NH", number:  2, winner: "G"},
  {state: "NJ", number:  1, winner: "L"},
  {state: "NJ", number:  2, winner: "R"},
  {state: "NJ", number:  3, winner: null},
  {state: "NJ", number:  4, winner: "D"},
  {state: "NJ", number:  5, winner: "G"},
  {state: "NJ", number:  6, winner: "L"},
  {state: "NJ", number:  7, winner: "R"},
  {state: "NJ", number:  8, winner: null},
  {state: "NJ", number:  9, winner: "D"},
  {state: "NJ", number: 10, winner: "G"},
  {state: "NJ", number: 11, winner: "L"},
  {state: "NJ", number: 12, winner: "R"},
  {state: "NM", number:  1, winner: null},
  {state: "NM", number:  2, winner: "D"},
  {state: "NM", number:  3, winner: "G"},
  {state: "NV", number:  1, winner: "L"},
  {state: "NV", number:  2, winner: "R"},
  {state: "NV", number:  3, winner: null},
  {state: "NV", number:  4, winner: "D"},
  {state: "NY", number:  1, winner: "G"},
  {state: "NY", number:  2, winner: "L"},
  {state: "NY", number:  3, winner: "R"},
  {state: "NY", number:  4, winner: null},
  {state: "NY", number:  5, winner: "D"},
  {state: "NY", number:  6, winner: "G"},
  {state: "NY", number:  7, winner: "L"},
  {state: "NY", number:  8, winner: "R"},
  {state: "NY", number:  9, winner: null},
  {state: "NY", number: 10, winner: "D"},
  {state: "NY", number: 11, winner: "G"},
  {state: "NY", number: 12, winner: "L"},
  {state: "NY", number: 13, winner: "R"},
  {state: "NY", number: 14, winner: null},
  {state: "NY", number: 15, winner: "D"},
  {state: "NY", number: 16, winner: "G"},
  {state: "NY", number: 17, winner: "L"},
  {state: "NY", number: 18, winner: "R"},
  {state: "NY", number: 19, winner: null},
  {state: "NY", number: 20, winner: "D"},
  {state: "NY", number: 21, winner: "G"},
  {state: "NY", number: 22, winner: "L"},
  {state: "NY", number: 23, winner: "R"},
  {state: "NY", number: 24, winner: null},
  {state: "NY", number: 25, winner: "D"},
  {state: "NY", number: 26, winner: "G"},
  {state: "NY", number: 27, winner: "L"},
  {state: "OH", number:  1, winner: "R"},
  {state: "OH", number:  2, winner: null},
  {state: "OH", number:  3, winner: "D"},
  {state: "OH", number:  4, winner: "G"},
  {state: "OH", number:  5, winner: "L"},
  {state: "OH", number:  6, winner: "R"},
  {state: "OH", number:  7, winner: null},
  {state: "OH", number:  8, winner: "D"},
  {state: "OH", number:  9, winner: "G"},
  {state: "OH", number: 10, winner: "L"},
  {state: "OH", number: 11, winner: "R"},
  {state: "OH", number: 12, winner: null},
  {state: "OH", number: 13, winner: "D"},
  {state: "OH", number: 14, winner: "G"},
  {state: "OH", number: 15, winner: "L"},
  {state: "OH", number: 16, winner: "R"},
  {state: "OK", number:  1, winner: null},
  {state: "OK", number:  2, winner: "D"},
  {state: "OK", number:  3, winner: "G"},
  {state: "OK", number:  4, winner: "L"},
  {state: "OK", number:  5, winner: "R"},
  {state: "OR", number:  1, winner: null},
  {state: "OR", number:  2, winner: "D"},
  {state: "OR", number:  3, winner: "G"},
  {state: "OR", number:  4, winner: "L"},
  {state: "OR", number:  5, winner: "R"},
  {state: "PA", number:  1, winner: null},
  {state: "PA", number:  2, winner: "D"},
  {state: "PA", number:  3, winner: "G"},
  {state: "PA", number:  4, winner: "L"},
  {state: "PA", number:  5, winner: "R"},
  {state: "PA", number:  6, winner: null},
  {state: "PA", number:  7, winner: "D"},
  {state: "PA", number:  8, winner: "G"},
  {state: "PA", number:  9, winner: "L"},
  {state: "PA", number: 10, winner: "R"},
  {state: "PA", number: 11, winner: null},
  {state: "PA", number: 12, winner: "D"},
  {state: "PA", number: 13, winner: "G"},
  {state: "PA", number: 14, winner: "L"},
  {state: "PA", number: 15, winner: "R"},
  {state: "PA", number: 16, winner: null},
  {state: "PA", number: 17, winner: "D"},
  {state: "PA", number: 18, winner: "G"},
  {state: "RI", number:  1, winner: "L"},
  {state: "RI", number:  2, winner: "R"},
  {state: "SC", number:  1, winner: null},
  {state: "SC", number:  2, winner: "D"},
  {state: "SC", number:  3, winner: "G"},
  {state: "SC", number:  4, winner: "L"},
  {state: "SC", number:  5, winner: "R"},
  {state: "SC", number:  6, winner: null},
  {state: "SC", number:  7, winner: "D"},
  {state: "SD", number:  0, winner: "G"},
  {state: "TN", number:  1, winner: "L"},
  {state: "TN", number:  2, winner: "R"},
  {state: "TN", number:  3, winner: null},
  {state: "TN", number:  4, winner: "D"},
  {state: "TN", number:  5, winner: "G"},
  {state: "TN", number:  6, winner: "L"},
  {state: "TN", number:  7, winner: "R"},
  {state: "TN", number:  8, winner: null},
  {state: "TN", number:  9, winner: "D"},
  {state: "TX", number:  1, winner: "G"},
  {state: "TX", number:  2, winner: "L"},
  {state: "TX", number:  3, winner: "R"},
  {state: "TX", number:  4, winner: null},
  {state: "TX", number:  5, winner: "D"},
  {state: "TX", number:  6, winner: "G"},
  {state: "TX", number:  7, winner: "L"},
  {state: "TX", number:  8, winner: "R"},
  {state: "TX", number:  9, winner: null},
  {state: "TX", number: 10, winner: "D"},
  {state: "TX", number: 11, winner: "G"},
  {state: "TX", number: 12, winner: "L"},
  {state: "TX", number: 13, winner: "R"},
  {state: "TX", number: 14, winner: null},
  {state: "TX", number: 15, winner: "D"},
  {state: "TX", number: 16, winner: "G"},
  {state: "TX", number: 17, winner: "L"},
  {state: "TX", number: 18, winner: "R"},
  {state: "TX", number: 19, winner: null},
  {state: "TX", number: 20, winner: "D"},
  {state: "TX", number: 21, winner: "G"},
  {state: "TX", number: 22, winner: "L"},
  {state: "TX", number: 23, winner: "R"},
  {state: "TX", number: 24, winner: null},
  {state: "TX", number: 25, winner: "D"},
  {state: "TX", number: 26, winner: "G"},
  {state: "TX", number: 27, winner: "L"},
  {state: "TX", number: 28, winner: "R"},
  {state: "TX", number: 29, winner: null},
  {state: "TX", number: 30, winner: "D"},
  {state: "TX", number: 31, winner: "G"},
  {state: "TX", number: 32, winner: "L"},
  {state: "TX", number: 33, winner: "R"},
  {state: "TX", number: 34, winner: null},
  {state: "TX", number: 35, winner: "D"},
  {state: "TX", number: 36, winner: "G"},
  {state: "UT", number:  1, winner: "L"},
  {state: "UT", number:  2, winner: "R"},
  {state: "UT", number:  3, winner: null},
  {state: "UT", number:  4, winner: "D"},
  {state: "VA", number:  1, winner: "G"},
  {state: "VA", number:  2, winner: "L"},
  {state: "VA", number:  3, winner: "R"},
  {state: "VA", number:  4, winner: null},
  {state: "VA", number:  5, winner: "D"},
  {state: "VA", number:  6, winner: "G"},
  {state: "VA", number:  7, winner: "L"},
  {state: "VA", number:  8, winner: "R"},
  {state: "VA", number:  9, winner: null},
  {state: "VA", number: 10, winner: "D"},
  {state: "VA", number: 11, winner: "G"},
  {state: "VT", number:  0, winner: "L"},
  {state: "WA", number:  1, winner: "R"},
  {state: "WA", number:  2, winner: null},
  {state: "WA", number:  3, winner: "D"},
  {state: "WA", number:  4, winner: "G"},
  {state: "WA", number:  5, winner: "L"},
  {state: "WA", number:  6, winner: "R"},
  {state: "WA", number:  7, winner: null},
  {state: "WA", number:  8, winner: "D"},
  {state: "WA", number:  9, winner: "G"},
  {state: "WA", number: 10, winner: "L"},
  {state: "WI", number:  1, winner: "R"},
  {state: "WI", number:  2, winner: null},
  {state: "WI", number:  3, winner: "D"},
  {state: "WI", number:  4, winner: "G"},
  {state: "WI", number:  5, winner: "L"},
  {state: "WI", number:  6, winner: "R"},
  {state: "WI", number:  7, winner: null},
  {state: "WI", number:  8, winner: "D"},
  {state: "WV", number:  1, winner: "G"},
  {state: "WV", number:  2, winner: "L"},
  {state: "WV", number:  3, winner: "R"},
  {state: "WY", number:  0, winner: null}
]

export default fakeHouseData
