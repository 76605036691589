import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TitleBar from './TitleBar';
import LandingPage from './LandingPage';
import Poll from './Poll';
import MapViewer from './MapViewer';
// import CanvasMap from './CanvasMap';

function App(props) {
  return (
    <>
      <TitleBar />
      <div className="horizontal-wrap">
        <div className="content">
          <BrowserRouter>
            <Switch>
              <Route exact path="/" render={() => <LandingPage />} />
              <Route exact path="/poll" render={() => <Poll />} />

              <Route exact path="/us/president" render={() => <MapViewer office="president" />} />
              <Route exact path="/us/senate" children={<MapViewer office="senate" />} />
              <Route exact path="/us/house" children={<MapViewer office="house" />} />
              <Route path="/us/senate/:state" children={<MapViewer office="senate" />} />
              <Route path="/us/house/:state/:district" children={<MapViewer office="house" />} />
              <Route path="/us/house/:state" children={<MapViewer office="house" />} />

              {/* <Route exact path="/canvas" render={() => <MapViewer asCanvas={true} />} /> */}
              {/* <Route path="/canvas/:state/:district" children={<MapViewer asCanvas={true} />} /> */}
              {/* <Route path="/canvas/:state" children={<MapViewer asCanvas={true} />} /> */}
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}

export default App;
